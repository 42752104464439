import AddIcon from "@mui/icons-material/Add";
import { useState } from "react";
import {
  DialogContentText,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Stack,
  Divider,
} from "@mui/material";
import now from "../../lib/now.js";
import { db } from "../../models/db.js";
import LoyaltyProgram from "../../models/LoyaltyProgram.js";

export default function NewLoyaltyProgramModal({ isOpen, onClose }) {
  const [name, setName] = useState("");
  const [sources, setSources] = useState("");
  const clearForm = () => {
    setName("");
  };

  const isValid = name.length > 3;

  const saveData = async () => {
    const item = new LoyaltyProgram({
      title: name,
      sources: sources
        .split(",")
        .map((item) => item.trim())
        .filter((item) => item.length),
    });
    item.createdAt = now();
    item.updatedAt = item.createdAt;
    await db.loyaltyPrograms.put(item);
    return item;
  };

  const onPrimaryClick = async () => {
    if (!isValid) return;
    const trip = await saveData();
    onClose();
    setName("");
  };

  const onCloseClick = () => {
    onClose();
    clearForm();
  };

  const onKeyUp = (event) => {
    if (event.key !== "Enter") return;
    onPrimaryClick();
  };

  const onNameChange = (event) => setName(event.target.value);
  const onSourcesChange = (event) => setSources(event.target.value);

  return (
    <Dialog open={isOpen} onClose={onCloseClick}>
      <DialogTitle>New Loyalty Program</DialogTitle>
      <DialogContent>
        <DialogContentText>Enter the details of your new loyalty program.</DialogContentText>
        <Divider sx={{ marginTop: 2, marginBottom: 2 }} />
        <Stack spacing={2} direction="row">
          <TextField value={name} label="Name" fullWidth margin="none" required onChange={onNameChange} />
          <TextField value={sources} label="Sources" fullWidth margin="none" onChange={onSourcesChange} />
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="secondary" variant="outlined">
          Cancel
        </Button>
        <Button
          disabled={!isValid}
          onClick={onPrimaryClick}
          startIcon={<AddIcon fontSize="small" />}
          color="primary"
          variant="contained"
        >
          Create
        </Button>
      </DialogActions>
    </Dialog>
  );
}
