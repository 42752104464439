import { Big } from "big.js";

export default function BookingEditorReducerPayments(state) {
  const principlePayment = state.payments.find((item) => item.isPrinciple);

  state.price = state.payments
    .reduce((acc, payment) => {
      if (payment.priceIsValid && payment.price > 0 && payment.priceCurrency === principlePayment?.priceCurrency) {
        return acc.plus(payment.price);
      }

      return acc;
    }, new Big(0))
    .round(2)
    .toNumber();

  state.pricePaid = state.payments
    .reduce((acc, payment) => {
      if (payment.pricePaidIsValid && payment.pricePaid > 0) {
        return acc.plus(payment.pricePaid);
      }

      return acc;
    }, new Big(0))
    .round(2)
    .toNumber();

  // priceAsString: result.valueAsString,
  // price: result.value,
  // priceIsValid: result.error === undefined,

  const paymentStates = state.payments.reduce(
    (acc, payment) => {
      if (payment.isPaid) {
        acc.paid += 1;
      } else {
        acc.unpaid += 1;
      }

      if (payment.isPriceEstimated) {
        acc.estimated += 1;
      } else {
        acc.notEstimated += 1;
      }
      return acc;
    },
    { paid: 0, unpaid: 0, estimated: 0, notEstimated: 0 },
  );

  if (paymentStates.paid > 0 && paymentStates.unpaid > 0) {
    // mixed
    // state.isPaid = undefined; // TODO: Handle partial payment status elsewhere in system
    state.isPaid = false;
  } else if (paymentStates.paid > 0 && paymentStates.unpaid === 0) {
    // all paid
    state.isPaid = true;
  } else if (paymentStates.paid === 0 && paymentStates.unpaid > 0) {
    // only unpaid
    state.isPaid = false;
  } else {
    // Nothing
    state.isPaid = false;
  }

  if (paymentStates.estimated > 0 && paymentStates.notEstimated > 0) {
    // mixed
    // state.isPriceEstimated = undefined; // TODO: Handle mixed estimated status elsewhere in system
    state.isPriceEstimated = true;
  } else if (paymentStates.estimated > 0 && paymentStates.notEstimated === 0) {
    // all estimated
    state.isPriceEstimated = true;
  } else if (paymentStates.estimated === 0 && paymentStates.notEstimated > 0) {
    // only unpaid
    state.isPriceEstimated = false;
  } else {
    // Nothing
    state.isPriceEstimated = false;
  }

  state.priceCurrency = principlePayment?.priceCurrency || "GBP";

  return state;
}
