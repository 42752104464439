import { useLiveQuery } from "dexie-react-hooks";

import { db } from "../../models/db.js";
import StayBrandsContext from "./StayBrandsContext.js";

export default function StayBrandsProvider({ children }) {
  const stayBrands = useLiveQuery(() => db.stayBrands.toCollection().sortBy("id"));
  const isLoaded = stayBrands !== undefined;
  return (
    <StayBrandsContext.Provider value={{ stayBrands: stayBrands || [], isLoaded }}>
      {children}
    </StayBrandsContext.Provider>
  );
}
