import { STAY, TRAVEL } from "../../models/BookingConstants.js";

/**
 * Determine if the form is valid across all options
 * @param state
 * @return {{isValid: Boolean, isValidErrorMessages: String[] }} - The result
 * @constructor
 */
export default function BookingEditorReducerValidator(state) {
  const isValidErrorMessages = [];

  if (state.type === TRAVEL && !state.segments?.length) {
    isValidErrorMessages.push("Travel booking has no segments added.");
  }
  if (state.type === STAY && !state.stays?.length) {
    isValidErrorMessages.push("Accommodation booking has no stays added.");
  }

  if (state.segments?.length) {
    const nonEmptySegments = state.segments.filter((segment) => segment.isEmpty === false);
    if (!nonEmptySegments.length) {
      isValidErrorMessages.push("There are empty segments.");
    }
    if (nonEmptySegments.find((segment) => segment.isValid === false)) {
      isValidErrorMessages.push("There are invalid segments.");
    }
  }

  if (state.stays?.length) {
    const nonEmptyStays = state.stays.filter((stay) => stay.isEmpty === false);
    if (!nonEmptyStays.length) {
      isValidErrorMessages.push("There are empty stays.");
    }
    if (nonEmptyStays.find((stay) => stay.isValid === false)) {
      isValidErrorMessages.push("There are invalid stays.");
    }
  }

  if (!state.payments?.length) {
    isValidErrorMessages.push("No payment has been added.");
  }

  const invalidPayment = state.payments.find(
    (payment) =>
      payment.priceIsValid === false || payment.pricePaidIsValid === false || payment.pointsPaidIsValid === false,
  );

  if (invalidPayment) {
    isValidErrorMessages.push("There are invalid payments.");
  }

  return {
    isValid: isValidErrorMessages.length === 0,
    isValidErrorMessages,
  };
}
