import Box from "@mui/material/Box";

import GlobalMapLoader from "../Globe/GlobalMapLoader.js";
import GlobeDataProvider from "../Globe/GlobeDataProvider.js";

export default function TripDetailsMap({ flights }) {
  return (
    <GlobeDataProvider flights={flights} animate sequence>
      <Box sx={{ flexGrow: "1" }}>
        <GlobalMapLoader />
      </Box>
    </GlobeDataProvider>
  );
}
