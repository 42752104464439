import { useLiveQuery } from "dexie-react-hooks";

import { db } from "../../models/db.js";
import AircraftContext from "./AircraftContext.js";

export default function AircraftProvider({ children, id, aircraft: manualAircraft }) {
  const aircraft = useLiveQuery(() => (id ? db.aircraft.get(id) : null), [id]);
  const result = manualAircraft || aircraft;
  const isLoaded = result !== undefined;
  return <AircraftContext.Provider value={{ aircraft: result || null, isLoaded }}>{children}</AircraftContext.Provider>;
}
