import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import MenuItem from "@mui/material/MenuItem";

import DownloadIcon from "@mui/icons-material/Download";
import { extractAsEJSON } from "../../models/extractToJSON.js";

const saveToFile = (fileContent, fullFileName) => {
  if (!fileContent) return;
  const fileParts = [fileContent];
  const blobLink = document.createElement("a");
  const blob = new Blob(fileParts, { type: "text/json" });
  blobLink.style.display = "none";
  const blobUrl = window.URL.createObjectURL(blob);

  blobLink.setAttribute("href", blobUrl);
  blobLink.setAttribute("download", fullFileName);
  blobLink.click();
};

export default function ExportDataMenuItem({ onClick, anonymous }) {
  const onClickHandler = async () => {
    onClick();
    const result = await extractAsEJSON(anonymous);
    const fullFileName = anonymous ? "anonymous-export.json" : "export.json";

    saveToFile(result, fullFileName);
  };
  return (
    <MenuItem onClick={onClickHandler} disableRipple disableTouchRipple>
      <ListItemIcon>
        <DownloadIcon fontSize="small" />
      </ListItemIcon>
      <ListItemText>{anonymous ? "Export Demo Data" : "Export Data"}</ListItemText>
    </MenuItem>
  );
}
