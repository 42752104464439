import Breadcrumbs from "@mui/material/Breadcrumbs";
import Link from "@mui/material/Link";
import { useLiveQuery } from "dexie-react-hooks";
import { useIntl } from "react-intl";

import { Link as ReactRouterLink } from "react-router";
import { db } from "../../models/db.js";
import FeatureCard from "../FeatureCard.js";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";

export function AgentDetailsCardQuery({ agentId }) {
  const agent = useLiveQuery(() => db.agents.get(agentId));

  return <AgentDetailsCard agent={agent} />;
}

export default function AgentDetailsCard({ agent }) {
  const { formatNumber } = useIntl();

  return (
    <CardContent>
      <Grid container columnSpacing={1} rowSpacing={{ xs: 1, sm: 0 }}>
        <Grid size={{ xs: 12, md: 6 }}>
          <Card elevation={0}>
            <CardContent sx={{ paddingLeft: 0, paddingRight: 0 }}>
              <Breadcrumbs variant="h5" gutterBottom>
                <Link to={`/agents`} component={ReactRouterLink} underline="hover" color="inherit" variant="h5">
                  Agents
                </Link>
                <Typography component="div" variant="h5" color="text.primary">
                  {agent?.title}
                </Typography>
              </Breadcrumbs>
            </CardContent>
          </Card>
        </Grid>
        <Grid size={{ xs: 12, md: 2 }}>
          <FeatureCard title="Total Nights" value={formatNumber(agent?.totalNights || 0)} variant="outlined" />
        </Grid>

        <Grid size={{ xs: 12, md: 2 }}>
          <FeatureCard title="Total Bookings" value={formatNumber(agent?.totalBookings || 0)} variant="outlined" />
        </Grid>

        <Grid size={{ xs: 12, md: 2 }}>
          <FeatureCard
            title="Total Paid"
            value={formatNumber(agent?.lifetimeSpend || 0, { style: "currency", currency: "GBP" })}
          />
        </Grid>
      </Grid>
    </CardContent>
  );
}
