function calculateTotals(trips) {
  const result = trips.reduce(
    (acc, trip) => {
      acc.totalPricePaid += trip.totalPricePaid || 0.0;
      acc.totalPricePaidUnpaid += trip.totalPricePaidUnpaid || 0.0;
      acc.totalNights += trip.totalNights || 0;
      return acc;
    },
    {
      totalPricePaid: 0.0,
      totalPricePaidUnpaid: 0.0,
      totalNights: 0,
      totalPricePaidForServices: 0.0,
      totalPricePaidForStays: 0.0,
      totalPricePaidForTravel: 0.0,
    },
  );

  return result;
}

const sorter = (trips, sortConfig) => {
  return trips.sort((a, b) => {
    if (sortConfig.key === "title") {
      return sortConfig.direction === "asc"
        ? a.title.localeCompare(b.title, undefined, { sensitivity: "base" })
        : b.title.localeCompare(a.title, undefined, { sensitivity: "base" });
    } else {
      if (a[sortConfig.key] < b[sortConfig.key]) {
        return sortConfig.direction === "asc" ? -1 : 1;
      }
      if (a[sortConfig.key] > b[sortConfig.key]) {
        return sortConfig.direction === "asc" ? 1 : -1;
      }
      return 0;
    }
  });
};

export default function TripsListReducer(state, action) {
  if (!action) return state;
  const { value, type } = action;

  if (type === "SET_STATE") {
    return { ...state, ...value };
  }

  if (type === "SET_SORT") {
    return { ...state, sortConfig: value, sortedTrips: sorter(state.trips, value) };
  }

  if (type === "SET_TRIPS") {
    return { ...state, trips: value, ...calculateTotals(value), sortedTrips: sorter(value, state.sortConfig) };
  }

  return state;
}
