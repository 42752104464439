import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Grid from "@mui/material/Grid";

import { useIntl } from "react-intl";
import FeatureCard from "../FeatureCard.js";

export default function TripsListSummaryHeader({ state }) {
  const { formatNumber } = useIntl();

  return (
    <CardContent>
      <Grid container columnSpacing={1} rowSpacing={{ xs: 1, sm: 0 }}>
        <Grid size={{ xs: 12, md: 6 }}>
          <Card elevation={0}></Card>
        </Grid>
        <Grid size={{ xs: 12, md: 2 }}>
          <FeatureCard title="Total Nights" value={formatNumber(state.totalNights || 0)} variant="outlined" />
        </Grid>

        <Grid size={{ xs: 12, md: 2 }}>
          <FeatureCard
            title="Payments Due"
            value={formatNumber(state.totalPricePaidUnpaid, { style: "currency", currency: "GBP" })}
            variant="outlined"
          />
        </Grid>

        <Grid size={{ xs: 12, md: 2 }}>
          <FeatureCard
            title="Total Paid"
            value={formatNumber(state.totalPricePaid, { style: "currency", currency: "GBP" })}
          />
        </Grid>
      </Grid>
    </CardContent>
  );
}
