import IconButton from "@mui/material/IconButton";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import usePrivacy from "../../contexts/Privacy/usePrivacy.js";
import Tooltip from "@mui/material/Tooltip";

export default function PrivacyButton() {
  const { privacyModeEnabled, setPrivacyModeEnabled } = usePrivacy();
  const icon = !privacyModeEnabled ? <VisibilityIcon /> : <VisibilityOffIcon />;
  const toggle = () => {
    setPrivacyModeEnabled(!privacyModeEnabled);
  };
  return (
    <Tooltip title={privacyModeEnabled ? "Show sensitive details" : "Hide sensitive details"} placement="bottom-end">
      <IconButton
        sx={{ display: { xs: "none", md: "flex" } }}
        color={privacyModeEnabled ? "primary" : "inherit"}
        size="small"
        onClick={toggle}
      >
        {icon}
      </IconButton>
    </Tooltip>
  );
}
