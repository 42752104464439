import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import TableHead from "@mui/material/TableHead";
import TableSortLabel from "@mui/material/TableSortLabel";

import { useIntl } from "react-intl";
import Card from "@mui/material/Card";
import Link from "@mui/material/Link";
import { Link as ReactRouterLink } from "react-router";
import TripNoteText from "../TripDetails/TrimNoteText.js";

const longDateOptions = {
  year: "numeric",
  month: "long",
  day: "numeric",
  weekday: "short",
};

export default function TripsList({ trips, sortConfig, onSort }) {
  const { formatDate, formatNumber } = useIntl();
  const createSortHandler = (key) => () => {
    onSort(key);
  };

  return (
    <>
      <Card>
        <TableContainer>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell>
                  <TableSortLabel
                    active={sortConfig.key === "startDate"}
                    direction={sortConfig.direction}
                    onClick={createSortHandler("startDate")}
                  >
                    Start
                  </TableSortLabel>
                </TableCell>
                <TableCell>End</TableCell>
                <TableCell>
                  <TableSortLabel
                    active={sortConfig.key === "durationInDays"}
                    direction={sortConfig.direction}
                    onClick={createSortHandler("durationInDays")}
                  >
                    Days
                  </TableSortLabel>
                </TableCell>
                <TableCell>
                  <TableSortLabel
                    active={sortConfig.key === "title"}
                    direction={sortConfig.direction}
                    onClick={createSortHandler("title")}
                  >
                    Title
                  </TableSortLabel>
                </TableCell>
                <TableCell>
                  <TableSortLabel
                    active={sortConfig.key === "totalDistanceInKm"}
                    direction={sortConfig.direction}
                    onClick={createSortHandler("totalDistanceInKm")}
                  >
                    Distance
                  </TableSortLabel>
                </TableCell>
                <TableCell>
                  <TableSortLabel
                    active={sortConfig.key === "totalFlights"}
                    direction={sortConfig.direction}
                    onClick={createSortHandler("totalFlights")}
                  >
                    Flights
                  </TableSortLabel>
                </TableCell>
                <TableCell>
                  <TableSortLabel
                    active={sortConfig.key === "totalHotels"}
                    direction={sortConfig.direction}
                    onClick={createSortHandler("totalHotels")}
                  >
                    Hotels
                  </TableSortLabel>
                </TableCell>
                <TableCell>
                  <TableSortLabel
                    active={sortConfig.key === "totalPricePaid"}
                    direction={sortConfig.direction}
                    onClick={createSortHandler("totalPricePaid")}
                  >
                    Total Paid
                  </TableSortLabel>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {trips.map((trip) => (
                <TableRow key={trip.id} hover>
                  <TableCell>{trip.startDate ? formatDate(trip.startDate, longDateOptions) : ""}</TableCell>
                  <TableCell>{trip.endDate ? formatDate(trip.endDate, longDateOptions) : ""}</TableCell>
                  <TableCell>{formatNumber(trip.durationInDays || 0)}</TableCell>

                  <TableCell>
                    <Link to={`/trips/${trip.id}`} component={ReactRouterLink}>
                      {trip.title}
                    </Link>
                    <TripNoteText component="span" note={trip.note} />
                  </TableCell>
                  <TableCell>
                    {trip.totalDistanceInKm > 0
                      ? formatNumber(trip.totalDistanceInKm, {
                          style: "unit",
                          unit: "kilometer",
                          maximumFractionDigits: 0,
                        })
                      : "-"}
                  </TableCell>
                  <TableCell>{trip.totalFlights > 0 ? formatNumber(trip.totalFlights) : "-"}</TableCell>
                  <TableCell>{trip.totalHotels > 0 ? formatNumber(trip.totalHotels) : "-"}</TableCell>

                  <TableCell>{formatNumber(trip.totalPricePaid, { style: "currency", currency: "GBP" })}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Card>
    </>
  );
}
