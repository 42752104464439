import { useLiveQuery } from "dexie-react-hooks";
import { useReducer, useEffect } from "react";
import { db } from "../../models/db.js";
import FixedFooterAppBar from "../FixedFooterAppBar.js";
import MainScrollLayout from "../MainScrollLayout.js";
import SubScrollLayout from "../SubScrollLayout.js";
import NewTripButton from "./NewTripButton.js";
import TripsListInitialState from "./state/TripsListInitialState.js";
import TripsListReducer from "./state/TripsListReducer.js";
import TripsList from "./TripsList.js";
import TripsListSummaryHeader from "./TripsListSummaryHeader.js";

export default function TripsListPage() {
  const [state, dispatch] = useReducer(TripsListReducer, null, TripsListInitialState);
  const setState = (value) => dispatch({ type: "SET_STATE", value });
  const setSortConfig = (value) => dispatch({ type: "SET_SORT", value });

  const trips = useLiveQuery(() => db.trips.orderBy("sortDate").reverse().toArray());

  useEffect(() => {
    dispatch({ type: "SET_TRIPS", value: trips || [] });
  }, [trips]);

  const { sortConfig, sortedTrips } = state;
  const handleSort = (key) => {
    let direction = sortConfig.direction;
    if (sortConfig.key === key) {
      direction = direction === "asc" ? "desc" : "asc";
    }
    setSortConfig({ key, direction });
  };

  return (
    <>
      <MainScrollLayout footerBar fixed flexColumn>
        <TripsListSummaryHeader state={state} />
        <SubScrollLayout>
          <TripsList trips={sortedTrips} sortConfig={sortConfig} onSort={handleSort} />
        </SubScrollLayout>
      </MainScrollLayout>
      <FixedFooterAppBar>
        <NewTripButton />
      </FixedFooterAppBar>
    </>
  );
}
