import Tooltip from "@mui/material/Tooltip";

import IconButton from "@mui/material/IconButton";
import { Link as ReactRouterLink, useLocation } from "react-router";
import TourIcon from "@mui/icons-material/Tour";

const to = "/stations";
export default function StationsButton() {
  const { pathname } = useLocation();
  const isActive = pathname === to;
  return (
    <Tooltip title={"Stations"} placement="bottom-end">
      <IconButton component={ReactRouterLink} to={to} color={isActive ? "primary" : "inherit"} size="small">
        <TourIcon />
      </IconButton>
    </Tooltip>
  );
}
