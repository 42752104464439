import SystemUpdateAltIcon from "@mui/icons-material/SystemUpdateAlt";

import { IconButton, Button, Tooltip } from "@mui/material";
import useAppInstallation from "../../contexts/AppInstallation/useAppInstallation.js";

export default function AppUpdateButton() {
  const { installState, skipWait } = useAppInstallation();

  const onUpdateClick = () => {
    skipWait();
  };

  if (!installState.updateAvailable) return null;
  return (
    <>
      <Tooltip title="An update is ready to install" placement="bottom-end" arrow>
        <Button
          variant="contained"
          sx={{
            display: { xs: "none", sm: "flex" },
          }}
          onClick={onUpdateClick}
          disabled={installState.updateInstalling}
          startIcon={<SystemUpdateAltIcon fontSize="small" />}
          size="small"
          color="primary"
        >
          Update Available
        </Button>
      </Tooltip>
      <IconButton
        sx={{
          display: { xs: "flex", sm: "none" },
        }}
        onClick={onUpdateClick}
        size="small"
      >
        <SystemUpdateAltIcon fontSize="small" />
      </IconButton>
    </>
  );
}
