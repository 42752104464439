import MainScrollLayout from "../MainScrollLayout.js";
import StayBrandsList from "./StayBrandsList.js";

export default function StayBrandsListPage() {
  return (
    <MainScrollLayout>
      <StayBrandsList />
    </MainScrollLayout>
  );
}
