import AddIcon from "@mui/icons-material/Add";
import Button from "@mui/material/Button";
import { useState } from "react";
import NewTripModal from "./NewTripModal.js";

export default function NewTripButton() {
  const [isOpen, setIsOpen] = useState(false);

  const onClick = () => {
    setIsOpen(!isOpen);
  };

  const onClose = () => {
    setIsOpen(false);
  };

  return (
    <>
      <Button startIcon={<AddIcon fontSize="small" />} color="secondary" variant="outlined" onClick={onClick}>
        New Trip
      </Button>
      <NewTripModal isOpen={isOpen} onClose={onClose} />
    </>
  );
}
