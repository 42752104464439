import Divider from "@mui/material/Divider";
import { useLiveQuery } from "dexie-react-hooks";

import { db } from "../../models/db.js";
import SegmentsList from "../FlightsList/SegmentsList.js";
import MainScrollLayout from "../MainScrollLayout.js";
import SubScrollLayout from "../SubScrollLayout.js";
import StationBookingsList from "./StationBookingsList.js";
import { useParams } from "react-router";
import StationDetailsCard from "./StationDetailsCard.js";
import StationFixedFooterAppBar from "./StationFixedFooterAppBar.js";

export default function StationBookingsListPage() {
  const { stationId, tab } = useParams();
  const linkState = { source: "station" };

  const station = useLiveQuery(() => db.stations.get(stationId), [stationId]);

  const tabSafe = tab || "segments";

  return (
    <>
      <MainScrollLayout footerBar fixed flexColumn>
        <StationDetailsCard station={station} />
        <Divider />

        <SubScrollLayout>
          {tabSafe === "segments" && <SegmentsList stationId={stationId} linkState={linkState} />}
          {tabSafe === "bookings" && <StationBookingsList stationId={stationId} />}
        </SubScrollLayout>
      </MainScrollLayout>
      <StationFixedFooterAppBar tabSafe={tabSafe} stationId={stationId} linkState={linkState} />
    </>
  );
}
