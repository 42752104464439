import MainScrollLayout from "../MainScrollLayout.js";
import BookingTravelList from "./BookingTravelList.js";

export default function BookingTravelListPage() {
  return (
    <MainScrollLayout>
      <BookingTravelList />
    </MainScrollLayout>
  );
}
