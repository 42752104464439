import AddIcon from "@mui/icons-material/Add";
import { useState } from "react";
import {
  DialogContentText,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Stack,
  Divider,
} from "@mui/material";
import generateIdFromTitle from "../../lib/generateIdFromTitle.js";
import now from "../../lib/now.js";
import { db } from "../../models/db.js";
import Station from "../../models/Station.js";

export default function NewStationModal({ isOpen, onClose }) {
  const [name, setName] = useState("");

  const clearForm = () => {
    setName("");
  };

  const isValid = name.length > 3;

  const saveData = async () => {
    const _now = now();
    const _title = name.trim();
    const station = new Station({
      title: _title,
      id: generateIdFromTitle(_title),
      createdAt: _now,
      updatedAt: _now,
    });

    await db.stations.add(station);
    return station;
  };

  const onPrimaryClick = async () => {
    if (!isValid) return;
    const station = await saveData();
    onClose();
    clearForm();
  };

  const onCloseClick = () => {
    onClose();
    clearForm();
  };

  const onKeyUp = (event) => {
    if (event.key !== "Enter") return;
    onPrimaryClick();
  };

  return (
    <Dialog open={isOpen} onClose={onCloseClick}>
      <DialogTitle>New Station</DialogTitle>
      <DialogContent>
        <DialogContentText>Enter the name of your new station.</DialogContentText>
        <Divider sx={{ marginTop: 2, marginBottom: 2 }} />
        <Stack direction="row" spacing={2}>
          <TextField
            label="Name"
            fullWidth
            required
            onKeyUp={onKeyUp}
            value={name}
            autoComplete="off"
            onChange={(event) => setName(event.target.value)}
          />
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="secondary" variant="outlined">
          Cancel
        </Button>
        <Button
          disabled={!isValid}
          onClick={onPrimaryClick}
          startIcon={<AddIcon fontSize="small" />}
          color="primary"
          variant="contained"
        >
          Create
        </Button>
      </DialogActions>
    </Dialog>
  );
}
