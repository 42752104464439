import TextField from "@mui/material/TextField";
import Tooltip from "@mui/material/Tooltip";

import useStations from "../../contexts/Stations/useStations.js";
import CreatableAutocomplete from "./CreatableAutocomplete.js";
import Stack from "@mui/material/Stack";

function StationInfoTooltip({ value, children }) {
  if (value) {
    let content = [];

    if (value.timeZone) {
      content.push(`Time zone: ${value.timeZone}`);
    } else {
      content.push(`Time zone: Unknown`);
    }

    if (value.country?.code) {
      content.push(`Country: ${value.country.code}`);
    } else {
      content.push(`Country: Unknown`);
    }

    if (content.length > 0) {
      return (
        <Tooltip title={content.join(", ")} arrow placement="bottom-start">
          <span>{children}</span>
        </Tooltip>
      );
    }
  }

  return children;
}

export default function StationsAutocomplete({ value, onChange, label, required, valueName, helperText, disabled }) {
  const { stations } = useStations();

  let titleValue = "";

  if (value) {
    if (value.id) {
      // pre-saved value
      titleValue = value.title;
    } else {
      // unsaved
      titleValue = valueName;
    }
  }

  return (
    <Stack direction="row" spacing={1}>
      <CreatableAutocomplete
        value={value}
        onChange={onChange}
        options={stations}
        label={label}
        sx={{ width: 75, minWidth: 75 }}
        useValue
        required={required}
        disableClearable
        clearOnBlur={false}
        disabled={disabled}
      />
      <StationInfoTooltip value={value}>
        <TextField
          label={`${label} Name`}
          value={titleValue}
          disabled={disabled || value?.id?.length > 0}
          onChange={(event) => {
            onChange(value, event.target.value);
          }}
          variant="standard"
          margin="none"
          sx={{ maxWidth: 160 }}
          required={required}
          helperText={helperText}
        />
      </StationInfoTooltip>
    </Stack>
  );
}
