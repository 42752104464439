import Breadcrumbs from "@mui/material/Breadcrumbs";
import Link from "@mui/material/Link";
import { useIntl } from "react-intl";

import { Link as ReactRouterLink } from "react-router";
import FeatureCard from "../FeatureCard.js";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import BookingRangeText from "./BookingRangeText.js";

export default function StationDetailsCard({ station }) {
  const { formatNumber } = useIntl();
  return (
    <CardContent>
      <Grid container columnSpacing={1} rowSpacing={{ xs: 1, sm: 0 }}>
        <Grid size={{ xs: 12, md: 4 }}>
          <Card elevation={0}>
            <CardContent sx={{ paddingLeft: 0, paddingRight: 0 }}>
              <Breadcrumbs variant="h5" gutterBottom>
                <Link to={`/stations`} component={ReactRouterLink} underline="hover" color="inherit" variant="h5">
                  Stations
                </Link>
                <Typography component="div" variant="h5" color="text.primary">
                  {station?.title}
                </Typography>
              </Breadcrumbs>
              <BookingRangeText station={station} />
            </CardContent>
          </Card>
        </Grid>

        <Grid size={{ xs: 12, md: 2 }}>
          <FeatureCard title="Bookings" variant="outlined" value={formatNumber(station?.totalBookings || 0, {})} />
        </Grid>

        <Grid size={{ xs: 12, md: 2 }}>
          <FeatureCard title="Departures" variant="outlined" value={formatNumber(station?.totalDepartures || 0, {})} />
        </Grid>

        <Grid size={{ xs: 12, md: 2 }}>
          <FeatureCard title="Arrivals" variant="outlined" value={formatNumber(station?.totalArrivals || 0, {})} />
        </Grid>

        <Grid size={{ xs: 12, md: 2 }}>
          <FeatureCard title="Segments" variant="outlined" value={formatNumber(station?.totalSegments || 0, {})} />
        </Grid>
      </Grid>
    </CardContent>
  );
}
