import { useMemo, useState } from "react";
import { useLiveQuery } from "dexie-react-hooks";
import { db } from "../../models/db.js";
import Card from "@mui/material/Card";
import AircraftTable from "./AircraftTable.js";

export default function AircraftList() {
  const [sortConfig, setSortConfig] = useState({ key: "model", direction: "asc" });

  const aircraft = useLiveQuery(() => db.aircraft.toCollection().sortBy("id"));

  const sortedAircraft = useMemo(() => {
    if (!aircraft) return null;

    return aircraft.sort((a, b) => {
      if (sortConfig.key === "model") {
        return sortConfig.direction === "asc"
          ? a.model.localeCompare(b.model, undefined, { sensitivity: "base" })
          : b.model.localeCompare(a.model, undefined, { sensitivity: "base" });
      } else {
        if (a[sortConfig.key] < b[sortConfig.key]) {
          return sortConfig.direction === "asc" ? -1 : 1;
        }
        if (a[sortConfig.key] > b[sortConfig.key]) {
          return sortConfig.direction === "asc" ? 1 : -1;
        }
        return 0;
      }
    });
  }, [aircraft, sortConfig]);

  if (!sortedAircraft) return null;

  const handleSort = (key) => {
    let direction = sortConfig.direction;
    if (sortConfig.key === key) {
      direction = direction === "asc" ? "desc" : "asc";
    }
    setSortConfig({ key, direction });
  };

  return (
    <Card square>
      <AircraftTable aircraft={sortedAircraft} sortConfig={sortConfig} onSort={handleSort} />
    </Card>
  );
}
