import Box from "@mui/material/Box";

export default function TextWrapper({ children, width = "150px", sx }) {
  return (
    <Box
      component="span"
      sx={{
        maxWidth: width,
        overflow: "hidden",
        display: "inline-block",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
        ...(sx || {}),
      }}
    >
      {children}
    </Box>
  );
}
