import { addDays, isAfter, isBefore } from "date-fns";

function segmentStartDateReducer(includeSkipped = false) {
  return (acc, segment) => {
    if (!segment.departureDate) return acc; // No date, so ignore
    if (!includeSkipped && segment.isSkipped) return acc; // Skipped ignore
    if (!acc || isBefore(segment.departureDate, acc)) return segment.departureDate;
    return acc;
  };
}

export function determineBestStartDate(segments) {
  return (
    segments.reduce(segmentStartDateReducer(), undefined) || segments.reduce(segmentStartDateReducer(true), undefined)
  );
}

function segmentEndDateReducer(includeSkipped = false) {
  return (acc, segment) => {
    if (!segment.departureDate) return acc; // No date, so ignore
    if (!includeSkipped && segment.isSkipped) return acc; // Skipped ignore
    const compareDate = segment.isOvernight ? addDays(segment.departureDate, 1) : segment.departureDate;
    if (!acc || isAfter(compareDate, acc)) return compareDate;
    return acc;
  };
}

export function determineBestEndDate(segments) {
  return segments.reduce(segmentEndDateReducer(), undefined) || segments.reduce(segmentEndDateReducer(true), undefined);
}
