import ConfirmationNumberIcon from "@mui/icons-material/ConfirmationNumber";
import ConnectingAirportsIcon from "@mui/icons-material/ConnectingAirports";
import FlightIcon from "@mui/icons-material/Flight";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import Skeleton from "@mui/material/Skeleton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";

import { Link as ReactRouterLink } from "react-router";
import useCarrier from "../../contexts/Carrier/useCarrier.js";
import { FLIGHT } from "../../models/CarrierConstants.js";
import { ToggleButtonCompact, ToggleButtonIcon } from "../controls/ToggleButton.js";
import FixedFooterAppBar from "../FixedFooterAppBar.js";

export default function CarrierFixedFooterCard({ linkState, carrierId, tabSafe }) {
  const { carrier, isLoaded } = useCarrier();

  const isAirline = isLoaded && carrier?.type === FLIGHT;
  const isNotAirline = isLoaded && !isAirline;
  return (
    <FixedFooterAppBar>
      <Button
        size="small"
        startIcon={<FlightIcon fontSize="small" />}
        color="secondary"
        variant="outlined"
        component={ReactRouterLink}
        state={linkState}
        to={`/bookings/new/travel?carrierId=${carrierId}`}
      >
        Add Travel
      </Button>
      <Divider orientation="vertical" variant="middle" flexItem />
      <ToggleButtonGroup exclusive value={tabSafe}>
        <ToggleButtonCompact
          color="primary"
          size="small"
          value="bookings"
          component={ReactRouterLink}
          to={`/carriers/${carrierId}`}
        >
          <ToggleButtonIcon>
            <ConfirmationNumberIcon />
          </ToggleButtonIcon>
          Bookings
        </ToggleButtonCompact>
        {isAirline && (
          <ToggleButtonCompact
            color="primary"
            size="small"
            value="flights"
            component={ReactRouterLink}
            to={`/carriers/${carrierId}/flights`}
          >
            <ToggleButtonIcon>
              <ConnectingAirportsIcon />
            </ToggleButtonIcon>
            Flights
          </ToggleButtonCompact>
        )}
        {isNotAirline && (
          <ToggleButtonCompact
            color="primary"
            size="small"
            value="segments"
            component={ReactRouterLink}
            to={`/carriers/${carrierId}/segments`}
          >
            <ToggleButtonIcon>
              <ConnectingAirportsIcon />
            </ToggleButtonIcon>
            Segments
          </ToggleButtonCompact>
        )}
        {!isLoaded && (
          <ToggleButtonCompact
            color="primary"
            size="small"
            value="segments"
            disabled
            component={ReactRouterLink}
            to={`/carriers/${carrierId}/segments`}
          >
            <ToggleButtonIcon>
              <ConnectingAirportsIcon />
            </ToggleButtonIcon>
            <Skeleton animation={false}>Segments</Skeleton>
          </ToggleButtonCompact>
        )}
      </ToggleButtonGroup>
    </FixedFooterAppBar>
  );
}
