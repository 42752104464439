import MainScrollLayout from "../MainScrollLayout.js";
import BookingStayList from "./BookingStayList.js";

export default function BookingStayListPage() {
  return (
    <MainScrollLayout>
      <BookingStayList />
    </MainScrollLayout>
  );
}
