import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import TableHead from "@mui/material/TableHead";
import Tooltip from "@mui/material/Tooltip";

import { useIntl } from "react-intl";
import CopyToClipboardText from "../CopyToClipboardText.js";
import BookingDescriptionText from "../GenericBookingsTable/BookingDescriptionText.js";
import CostText from "../GenericBookingsTable/CostText.js";
import PricePaid from "../GenericBookingsTable/PricePaid.js";
import SupplierTitleLink from "../GenericBookingsTable/SupplierTitleLink.js";
import BookingPaymentStatusChip from "./BookingPaymentStatusChip.js";
import BookingTypeIcon from "./BookingTypeIcon.js";
import TableSortLabel from "@mui/material/TableSortLabel";
import BookingTitleLink from "../GenericBookingsTable/BookingTitleLink.js";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import FilterAltOffIcon from "@mui/icons-material/FilterAltOff";
export default function BookingsTable({
  bookings,
  onRequestSort,
  sortBy,
  sortOrder,
  hideCancelledBookings,
  onToggleHideCancelledBookings,
}) {
  const { formatDate } = useIntl();

  const sortByBookingDate = (event) => onRequestSort(event, "bookingDate");
  const sortByStartDate = (event) => onRequestSort(event, "startDate");
  const sortByCost = (event) => onRequestSort(event, "cost");
  const toggleHideCancelledBookingsClick = () => onToggleHideCancelledBookings(!hideCancelledBookings);

  return (
    <TableContainer>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell>
              <TableSortLabel active={sortBy === "bookingDate"} direction={sortOrder} onClick={sortByBookingDate}>
                Booking Date
              </TableSortLabel>
            </TableCell>
            <TableCell></TableCell>
            <TableCell>
              <TableSortLabel active={sortBy === "startDate"} direction={sortOrder} onClick={sortByStartDate}>
                Description
              </TableSortLabel>
            </TableCell>
            <TableCell>Supplier / Vendor</TableCell>
            <TableCell>Reference</TableCell>
            <TableCell align="right">
              <TableSortLabel active={sortBy === "cost"} direction={sortOrder} onClick={sortByCost}>
                Cost
              </TableSortLabel>
            </TableCell>
            <TableCell align="right">Price Paid</TableCell>
            <TableCell>
              <Tooltip
                title={hideCancelledBookings ? "Cancelled bookings are hidden" : "Showing all bookings"}
                placement="bottom-end"
              >
                <TableSortLabel
                  active={hideCancelledBookings}
                  direction="asc"
                  onClick={toggleHideCancelledBookingsClick}
                  IconComponent={hideCancelledBookings ? FilterAltIcon : FilterAltOffIcon}
                >
                  Status
                </TableSortLabel>
              </Tooltip>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {bookings.map((booking) => {
            return (
              <TableRow key={booking.id} hover>
                <TableCell
                  sx={{
                    whiteSpace: "nowrap",
                  }}
                >
                  {formatDate(booking.bookingDate, {
                    year: "numeric",
                    month: "short",
                    day: "numeric",
                    weekday: "short",
                  })}
                </TableCell>
                <TableCell>
                  <BookingTypeIcon booking={booking} />
                </TableCell>
                <TableCell>
                  <BookingTitleLink booking={booking} />
                  <BookingDescriptionText booking={booking} showPerNight />
                </TableCell>
                <TableCell>
                  <SupplierTitleLink booking={booking} />
                </TableCell>
                <TableCell>
                  <CopyToClipboardText enablePrivacy value={booking.bookingReference} />
                </TableCell>
                <TableCell align="right">
                  <CostText booking={booking} />
                </TableCell>

                <TableCell align="right">
                  <PricePaid booking={booking} />
                </TableCell>
                <TableCell sx={{ width: 120 }}>
                  <BookingPaymentStatusChip booking={booking} />
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
