import Typography from "@mui/material/Typography";
import Tooltip from "@mui/material/Tooltip";
import Zoom from "@mui/material/Zoom";

import { useIntl } from "react-intl";
import useCurrency from "../../contexts/Currency/useCurrency.js";
import { BOOKED, CANCELLED, REFUNDED } from "../../models/BookingConstants.js";

export default function PricePaid({ booking, showUnpaidLabel }) {
  const { formatNumber } = useIntl();
  const { currency } = useCurrency();

  const { price, priceCurrency, isPaid, status } = booking;
  if (price === undefined) {
    return (
      <Typography variant="body2" color="warning.main" component="span" fontStyle="italic">
        Unknown
      </Typography>
    );
  }

  const currencyTitle = currency.find((item) => item.id === priceCurrency)?.title || priceCurrency;
  const text = formatNumber(booking.price, { style: "currency", currency: priceCurrency });

  const perNightText =
    booking._nights > 1 && booking.pricePerNight
      ? ` / ${formatNumber(booking.pricePerNight, { style: "currency", currency: priceCurrency, maximumFractionDigits: 0 })} per night`
      : "";

  if (isPaid === false && status === BOOKED) {
    return (
      <Tooltip
        TransitionComponent={Zoom}
        title={`${showUnpaidLabel ? "Unpaid / " : ""}${currencyTitle}${perNightText}`}
        arrow
        placement="right"
        color="warning"
      >
        <Typography variant="body2" color="warning.main" component="span" fontStyle="italic">
          {text}
        </Typography>
      </Tooltip>
    );
  } else if ((isPaid && status === REFUNDED) || (!isPaid && status === CANCELLED)) {
    return (
      <Tooltip TransitionComponent={Zoom} title={`${currencyTitle}${perNightText}`} arrow placement="right">
        <Typography
          variant="body2"
          component="span"
          sx={{
            textDecoration: "line-through",
          }}
        >
          {text}
        </Typography>
      </Tooltip>
    );
  }
  return (
    <Tooltip TransitionComponent={Zoom} title={`${currencyTitle}${perNightText}`} arrow placement="right">
      <span>{text}</span>
    </Tooltip>
  );
}
