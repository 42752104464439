import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Tooltip from "@mui/material/Tooltip";
import Radio from "@mui/material/Radio";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { TimeField } from "@mui/x-date-pickers/TimeField";
import dayjs from "dayjs";
import { useState } from "react";
import { useIntl } from "react-intl";
import basic from "../../api/flights/basic.js";
import useSnackBar from "../../contexts/SnackBar/useSnackBar.js";
import formatETicket from "../../lib/formatETicket.js";
import generateIdFromTitle from "../../lib/generateIdFromTitle.js";
import stripWhiteSpace from "../../lib/stripWhiteSpace.js";
import CarriersAutoComplete from "./CarriersAutocomplete.js";
import EditorRowActionsButton from "./EditorRowActionsButton.js";
import LoyaltyProgramActivityButton from "./LoyaltyProgramActivity/LoyaltyProgramActivityButton.js";
import processFlightRecord from "./processFlightRecord.js";
import StationsAutocomplete from "./StationsAutocomplete.js";
import cleanDateValue from "./cleanDateValue.js";
import getSegmentFullTitle from "../../models/getSegmentFullTitle.js";
import ToggleButton from "@mui/material/ToggleButton";
import PlusOneIcon from "@mui/icons-material/PlusOne";
import TravelExploreIcon from "@mui/icons-material/TravelExplore";
import { differenceInCalendarDays, differenceInMinutes, formatDuration, intervalToDuration } from "date-fns";

export default function SegmentEditorRow({
  segment,
  setSegment,
  showLeadingCarrier,
  referenceDate,
  endDateMaxValue,
  bookingDateMinValue,
}) {
  const { formatNumber, formatDate } = useIntl();
  const { enqueueSnackbar } = useSnackBar();

  const [isLoadingFlightData, setIsLoadingFlightData] = useState(false);

  const {
    eTicket,
    carrier,
    id,
    carrierReference,
    origin,
    originTitle,
    destination,
    destinationTitle,
    departureDate,
    createdAt,
    isLeadingCarrier,
    classCode,
    flightNumber,
    isOvernight,
    distanceInKm,
    aircraft,
    duration,
    departureTimeUtc,
    arrivalTimeUtc,
  } = segment;

  let durationInMinutesText = "";

  if (duration) {
    durationInMinutesText = formatDuration(duration, {
      format: ["hours", "minutes"],
    });
  }

  const onCarrierSelected = (newValue) => {
    setSegment(id, {
      carrier: newValue,
    });
  };

  const onFromSelected = (newValue, newCustomTitle) => {
    setSegment(id, {
      origin: newValue
        ? { ...newValue, country: newValue.country ? { code: newValue.country.code } : undefined }
        : { title: newCustomTitle },
      originTitle: (newValue?.id ? "" : newCustomTitle) || "",
    });
  };

  const onToSelected = (newValue, newCustomTitle) => {
    setSegment(id, {
      destination: newValue
        ? { ...newValue, country: newValue.country ? { code: newValue.country.code } : undefined }
        : { title: newCustomTitle },
      destinationTitle: (newValue?.id ? "" : newCustomTitle) || "",
    });
  };

  const onToggleLeadingCarrier = () => {
    setSegment(id, !isLeadingCarrier, "SET_LEADING_CARRIER");
  };

  const onDeleteClick = () => {
    setSegment(id, undefined, "DELETE_SEGMENT");
  };

  const onLoyaltyProgramActivityChange = (value, event) => {
    setSegment(id, value, event);
  };

  const onSearchFlightDataClick = async () => {
    if (isLoadingFlightData) return;
    setIsLoadingFlightData(true);

    const data = await basic({ flightNumber, departure: departureDate.toISOString().substring(0, 10) });

    const isValid = data.success === true;

    if (!isValid) {
      if (data.message) {
        enqueueSnackbar({ message: `Failed to load flight data: ${data.message}`, variant: "error" });
      } else {
        enqueueSnackbar({ message: `Failed to load flight data.`, variant: "error" });
      }
      setIsLoadingFlightData(false);

      return;
    }

    let flight;

    if (origin?.id) {
      flight = data?.data?.find((result) => result.departure?.airport?.iata === origin?.id);
    }

    if (!flight && data?.data.length > 1) {
      console.log(data?.data);
      enqueueSnackbar({ message: `There is more than one possible flight that matches.`, variant: "error" });
      return;
    }

    if (!flight) {
      flight = data?.data?.[0];
    }

    if (!flight) {
      enqueueSnackbar({ message: `Could not locate this flight.`, variant: "error" });
      setIsLoadingFlightData(false);

      return;
    }

    const dataToSet = processFlightRecord({ flight });

    if (Object.keys(dataToSet).length) {
      setSegment(id, { ...dataToSet, sourcedFromAPI: true });
      enqueueSnackbar({ message: `Flight data for flight ${flight.number} was loaded.`, variant: "success" });
    }

    setIsLoadingFlightData(false);
  };

  const canSearchFlightData = Boolean(departureDate) && flightNumber?.length >= 3;

  const departureTimeZone = origin?.timeZone;
  const arrivalTimeZone = destination?.timeZone;

  return (
    <Card key={id} elevation={1}>
      <CardContent>
        <Stack direction="row" spacing={1}>
          <DatePicker
            disableToolbar
            variant="inline"
            inputVariant="outlined"
            autoOk
            label="Departure"
            value={departureDate ? dayjs.utc(departureDate) : null}
            onChange={(newValue) => {
              setSegment(id, { departureDate: newValue?.startOf("day")?.toDate() });
            }}
            sx={{ maxWidth: 100, width: 100 }}
            minDate={dayjs.utc(bookingDateMinValue)}
            maxDate={dayjs.utc(endDateMaxValue)}
            referenceDate={dayjs.utc(referenceDate || createdAt)}
            timezone="UTC"
            showDaysOutsideCurrentMonth
            format="DD/MM/YY"
            disabled={isLoadingFlightData}
            slotProps={{
              openPickerButton: { size: "small" },
              openPickerIcon: { fontSize: "small" },
              textField: {
                variant: "standard",
                label: "Departure",
                margin: "none",
              },
            }}
          />
          <TextField
            label="Flight No."
            value={flightNumber}
            onChange={(event) => {
              setSegment(id, { flightNumber: stripWhiteSpace(event.target.value.toUpperCase()) });
            }}
            variant="standard"
            margin="none"
            disabled={isLoadingFlightData}
            sx={{ maxWidth: 70 }}
          />
          <Box sx={{ alignSelf: "center" }}>
            <Tooltip title="Query flight information" arrow placement="bottom-start">
              <span>
                <IconButton
                  onClick={onSearchFlightDataClick}
                  color={segment.sourcedFromAPI ? "success" : "default"}
                  size="small"
                  disabled={segment.sourcedFromAPI || isLoadingFlightData || !canSearchFlightData}
                >
                  <TravelExploreIcon fontSize="small" />
                </IconButton>
              </span>
            </Tooltip>
          </Box>
          <CarriersAutoComplete
            value={carrier}
            onChange={onCarrierSelected}
            disabled={segment.sourcedFromAPI || isLoadingFlightData}
          />
          <StationsAutocomplete
            required
            value={origin}
            valueName={originTitle}
            onChange={onFromSelected}
            label="From"
            disabled={(segment.sourcedFromAPI && origin) || isLoadingFlightData}
          />
          <Tooltip title={`Time zone: ${departureTimeZone || "Unknown"}`} arrow placement="bottom-start">
            <span>
              <TimeField
                label="Departing"
                format="HH:mm"
                clearable
                readOnly
                value={departureTimeUtc ? dayjs.utc(departureTimeUtc) : null}
                timezone={departureTimeZone}
                onChange={() => {}}
                variant="standard"
                margin="none"
                sx={{ width: 75, minWidth: 75 }}
                disabled={true === true || isLoadingFlightData}
              />
            </span>
          </Tooltip>
          <StationsAutocomplete
            required
            value={destination}
            valueName={destinationTitle}
            onChange={onToSelected}
            label="To"
            disabled={(segment.sourcedFromAPI && destination) || isLoadingFlightData}
          />
          <Tooltip title={`Time zone: ${arrivalTimeZone || "Unknown"}`} arrow placement="bottom-start">
            <span>
              <TimeField
                label="Arriving"
                format="HH:mm"
                clearable
                readOnly
                value={arrivalTimeUtc ? dayjs.utc(arrivalTimeUtc) : null}
                timezone={arrivalTimeZone}
                onChange={() => {}}
                variant="standard"
                margin="none"
                sx={{ width: 75, minWidth: 75 }}
                disabled={true === true || isLoadingFlightData}
              />
            </span>
          </Tooltip>
          <Box sx={{ alignSelf: "center" }}>
            <Tooltip title="Arrives the next day" arrow placement="bottom-start">
              <span>
                <ToggleButton
                  value="isOvernight"
                  selected={isOvernight || false}
                  onChange={() => {
                    setSegment(id, { isOvernight: !isOvernight });
                  }}
                  size="small"
                  color="success"
                  disableTouchRipple
                  disabled={isLoadingFlightData || Boolean(segment.duration)}
                >
                  <PlusOneIcon />
                </ToggleButton>
              </span>
            </Tooltip>
          </Box>

          <Divider orientation="vertical" variant="middle" flexItem />
          <Box sx={{ alignSelf: "center" }}>
            <Tooltip title="Leading Carrier" arrow placement="bottom-start">
              <span>
                <Radio
                  checked={isLeadingCarrier || false}
                  size="small"
                  onChange={onToggleLeadingCarrier}
                  disabled={!showLeadingCarrier}
                />
              </span>
            </Tooltip>
          </Box>
          <Box sx={{ alignSelf: "center" }}>
            <LoyaltyProgramActivityButton
              segment={segment}
              title={getSegmentFullTitle({ segment, showFlightNumber: true })}
              onChange={onLoyaltyProgramActivityChange}
              loyaltyProgramActivities={segment.loyaltyProgramActivities}
              referenceDate={departureDate}
              minDate={bookingDateMinValue}
            />
          </Box>

          <Box sx={{ alignSelf: "center" }}>
            <EditorRowActionsButton value={segment} set={setSegment} isSegment onDelete={onDeleteClick} />
          </Box>
        </Stack>
        <Stack direction="row" spacing={1} marginTop={2}>
          <TextField
            label="Distance"
            value={
              distanceInKm
                ? `${formatNumber(distanceInKm, {
                    style: "unit",
                    unit: "kilometer",
                    maximumFractionDigits: 0,
                  })}`
                : ""
            }
            placeholder="Unknown"
            onChange={() => {}}
            variant="standard"
            disabled
            margin="none"
          />
          <TextField
            label="Duration"
            value={durationInMinutesText}
            placeholder="Unknown"
            onChange={() => {}}
            variant="standard"
            margin="none"
            disabled
          />
          <TextField
            label="Aircraft"
            value={aircraft?.model || ""}
            placeholder="Unknown"
            onChange={() => {}}
            variant="standard"
            margin="none"
            disabled
          />
          <TextField
            label="Class"
            value={classCode}
            onChange={(event) => {
              setSegment(id, { classCode: stripWhiteSpace(event.target.value.toUpperCase()) });
            }}
            variant="standard"
            margin="none"
            sx={{ maxWidth: 54 }}
          />
          <TextField
            label="eTicket"
            value={eTicket}
            onChange={(event) => {
              setSegment(id, { eTicket: formatETicket(event.target.value) });
            }}
            inputProps={{
              inputMode: "numeric",
            }}
            variant="standard"
            margin="none"
          />
          <TextField
            label="Carrier Reference"
            value={carrierReference}
            onChange={(event) => {
              setSegment(id, { carrierReference: stripWhiteSpace(event.target.value.toUpperCase()) });
            }}
            inputProps={{
              inputMode: "numeric",
            }}
            variant="standard"
            margin="none"
          />
        </Stack>
      </CardContent>
      <Divider />
    </Card>
  );
}
