import CardContent from "@mui/material/CardContent";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import { useState } from "react";
import TextField from "@mui/material/TextField";
import { Link as ReactRouterLink } from "react-router";
import useDialog from "../../contexts/Dialog/useDialog.js";
import useSnackBar from "../../contexts/SnackBar/useSnackBar.js";
import { db } from "../../models/db.js";

export default function LoyaltyProgramCard({ loyaltyProgram }) {
  const { enqueueSnackbar } = useSnackBar();
  const confirmAction = useDialog();

  const [editMode, setEditMode] = useState(false);
  const [title, setTitle] = useState(loyaltyProgram.title);
  const [note, setNote] = useState(loyaltyProgram.note || "");
  const [programId, setProgramId] = useState(loyaltyProgram.programId || "");
  const [sources, setSources] = useState(loyaltyProgram.sources.join(",") || "");

  const onEditClick = () => {
    setEditMode(!editMode);
  };

  const onDeleteClick = async () => {
    const proceed = await confirmAction({
      title: `Delete Loyalty Program ${loyaltyProgram.title}`,
      text: "Are you sure you want to delete this program and its activity? ",
      submitButtonLabel: "Delete",
      color: "error",
    });

    if (!proceed) return;

    const result = await db.deleteLoyaltyProgram(loyaltyProgram.id);
    if (result?.success) {
      enqueueSnackbar({ message: `${title} Deleted`, variant: "success" });
    } else {
      enqueueSnackbar({ message: result.message, variant: "error" });
    }
  };

  const onCancelClick = () => {
    setEditMode(false);
    setTitle(loyaltyProgram.title);
  };

  const onSaveClick = async () => {
    await db.updateLoyaltyProgram(
      {
        ...loyaltyProgram,
        title,
        note: note || undefined,
        programId: programId || undefined,
        sources: sources
          .split(",")
          .map((item) => item.trim())
          .filter((item) => item.length),
      },
      loyaltyProgram,
    );
    enqueueSnackbar({ message: `${title} Updated`, variant: "success" });
    setEditMode(!editMode);
  };

  const onTitleChange = (event) => {
    setTitle(event.target.value);
  };

  const onNoteChange = (event) => setNote(event.target.value);
  const onProgramIdChange = (event) => setProgramId(event.target.value);
  const onSourcesChange = (event) => setSources(event.target.value);

  const onIdChange = () => {};

  return (
    <Card>
      <CardContent>
        <Grid container spacing={2}>
          <Grid>
            <TextField value={loyaltyProgram.id} fullWidth margin="none" disabled required onChange={onIdChange} />
          </Grid>
          <Grid>
            <TextField
              label="Name"
              value={title}
              fullWidth
              margin="none"
              disabled={!editMode}
              required
              onChange={onTitleChange}
            />
          </Grid>
          <Grid>
            <TextField
              label="Program ID"
              value={programId}
              fullWidth
              margin="none"
              disabled={!editMode}
              onChange={onProgramIdChange}
            />
          </Grid>
          <Grid>
            <TextField label="Note" value={note} fullWidth margin="none" disabled={!editMode} onChange={onNoteChange} />
          </Grid>
          <Grid>
            <TextField
              label="Sources"
              value={sources}
              fullWidth
              margin="none"
              disabled={!editMode}
              onChange={onSourcesChange}
            />
          </Grid>
          <Grid>
            <Button color="secondary" component={ReactRouterLink} to={`/loyalty/${loyaltyProgram.id}`}>
              Activity
            </Button>
            {editMode && (
              <Button color="secondary" onClick={onCancelClick}>
                Cancel
              </Button>
            )}
            {!editMode && (
              <Button color="error" onClick={onDeleteClick}>
                Delete
              </Button>
            )}
            {!editMode && (
              <Button color="secondary" onClick={onEditClick}>
                Edit
              </Button>
            )}
            {editMode && (
              <Button color="primary" onClick={onSaveClick}>
                Save
              </Button>
            )}
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}
