import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import Typography from "@mui/material/Typography";

import { Link as ReactRouterLink } from "react-router";
import useStayBrands from "../../contexts/StayBrands/useStayBrands.js";
import { AutoCompleteListItem } from "./AutocompleteList.js";
import CreatableAutocomplete from "./CreatableAutocomplete.js";

export default function StayBrandsAutocomplete({ value, onChange, label }) {
  const { stayBrands, isLoaded } = useStayBrands();

  const renderOption = (props, option) => {
    return (
      <AutoCompleteListItem {...props} key={props.key}>
        <Typography variant="body2">{option.title}</Typography>
      </AutoCompleteListItem>
    );
  };

  const path = value?.id ? `/hotels/${value.id}` : null;
  const endAdornment = (
    <InputAdornment position="end">
      <IconButton
        component={ReactRouterLink}
        disabled={!path}
        to={path}
        edge="end"
        size="small"
        sx={{
          // padding: 0.5,
          marginRight: 0,
        }}
      >
        <OpenInNewIcon />
      </IconButton>
    </InputAdornment>
  );
  return (
    <CreatableAutocomplete
      value={value}
      onChange={onChange}
      options={stayBrands}
      label={label || "Brand"}
      forcePopupIcon
      sx={{ width: 300 }}
      required
      loading={!isLoaded}
      renderOption={renderOption}
      endAdornment={endAdornment}
    />
  );
}
