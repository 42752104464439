import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import TableHead from "@mui/material/TableHead";
import TableSortLabel from "@mui/material/TableSortLabel";

import { useIntl } from "react-intl";
import Card from "@mui/material/Card";

export default function CurrencyList({ currency, homeCurrency }) {
  const { formatNumber } = useIntl();

  return (
    <>
      <Card>
        <TableContainer>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell>
                  <TableSortLabel active direction="desc" disabled>
                    Title
                  </TableSortLabel>
                </TableCell>
                <TableCell>To Home Currency</TableCell>
                <TableCell>From Home Currency</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {currency.map(({ id, title, rateToHomeCurrency, rateFromHomeCurrency }) => (
                <TableRow key={id} hover>
                  <TableCell>{title}</TableCell>
                  <TableCell>
                    {`${formatNumber(1, { style: "currency", currency: id })} = ${formatNumber(rateToHomeCurrency, { style: "currency", currency: homeCurrency })}`}
                  </TableCell>
                  <TableCell>
                    {`${formatNumber(1, { style: "currency", currency: homeCurrency })} = ${formatNumber(rateFromHomeCurrency, { style: "currency", currency: id })}`}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Card>
    </>
  );
}
