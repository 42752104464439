import AutoAwesomeIcon from "@mui/icons-material/AutoAwesome";
import FlightIcon from "@mui/icons-material/Flight";
import HotelIcon from "@mui/icons-material/Hotel";

import { BOOKED, SERVICE, STAY, TRAVEL } from "../../models/BookingConstants.js";
import { BUS, FLIGHT, CAR, TRAIN, BOAT } from "../../models/CarrierConstants.js";
import DirectionsBusIcon from "@mui/icons-material/DirectionsBus";
import TrainIcon from "@mui/icons-material/Train";
import DriveEtaIcon from "@mui/icons-material/DriveEta";
import DirectionsBoatIcon from "@mui/icons-material/DirectionsBoat";
import ReceiptLongIcon from "@mui/icons-material/ReceiptLong";

export default function BookingTypeIcon({ booking, type }) {
  const color = booking.status === BOOKED ? "inherit" : "secondary";

  if (booking.type.includes(SERVICE)) {
    return <AutoAwesomeIcon color={color} />;
  }

  if (booking.type.length > 1 && !type) {
    return <ReceiptLongIcon color={color} />;
  }
  if ((!type || type === TRAVEL) && booking.type.includes(TRAVEL)) {
    let transitType = booking.leadingCarrier?.type;

    if (transitType === BUS) {
      return <DirectionsBusIcon color={color} />;
    }

    if (transitType === BOAT) {
      return <DirectionsBoatIcon color={color} />;
    }

    if (transitType === TRAIN) {
      return <TrainIcon color={color} />;
    }

    if (transitType === CAR) {
      return <DriveEtaIcon color={color} />;
    }

    if (transitType === FLIGHT) {
      return <FlightIcon color={color} />;
    }

    return <FlightIcon color={color} />;
  }

  if ((!type || type === STAY) && booking.type.includes(STAY)) {
    return <HotelIcon color={color} />;
  }

  return null;
}
