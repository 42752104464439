import generateIdFromTitle from "../lib/generateIdFromTitle.js";
import AircraftValidator from "./AircraftValidator.js";

/**
 * Represents an Aircraft, with associated metadata.
 */
export default class Aircraft {
  constructor({
    id,
    title,
    model,
    modeS,
    reg,
    totalDistanceInKm,
    totalSegments,
    totalSegmentsWithDistanceInKm,
    firstSegmentDepartureDate,
    lastSegmentDepartureDate,
  } = {}) {
    this.id = generateIdFromTitle(id || model);
    this.title = title || undefined;
    this.model = model || undefined;
    this.modeS = modeS || undefined;
    this.reg = reg || undefined;

    this.totalDistanceInKm = totalDistanceInKm || 0.0;
    this.totalSegments = totalSegments || 0;
    this.totalSegmentsWithDistanceInKm = totalSegmentsWithDistanceInKm || 0;
    this.firstSegmentDepartureDate = firstSegmentDepartureDate || undefined;
    this.lastSegmentDepartureDate = lastSegmentDepartureDate || undefined;
  }

  /**
   * The ID
   * @type {String}
   */
  id;

  /**
   * The model.
   * @type {String}
   * @example "Boeing 787-9"
   */
  model;

  /**
   * The model display name, per ICAO.
   * @type {String}
   * @example "Boeing 787-9"
   */
  title;

  /**
   * The Mode-S transponder ID.
   * @type {String}
   * @example "E80459"
   */
  modeS;

  /**
   * The aircraft registration (tail number).
   * @type {String}
   * @example "CC-BGM"
   */
  reg;

  /**
   * The total number of paid segments.
   * @type {Number}
   */
  totalSegments;

  /**
   * The total number of segments with distance information.
   * @type {Number}
   */
  totalSegmentsWithDistanceInKm;

  /**
   * The total kilometers travelled (by any means) on this trip. Calculated.
   * @type {Number}
   */
  totalDistanceInKm;

  /**
   * The first primary passenger non-cancelled segment with this Carrier.
   * @type {Date}
   */
  firstSegmentDepartureDate;

  /**
   * The last primary passenger non-cancelled segment with this Carrier.
   * @type {Date}
   */
  lastSegmentDepartureDate;

  /**
   * Indicates if this instance and its data is valid.
   * @return {boolean}
   */
  get isValid() {
    const { error, value } = AircraftValidator.validate(this);
    if (error === undefined) {
      return true;
    }
    console.log(error, value);
    return false;
  }
}
