import Box from "@mui/material/Box";

export default function MainScrollLayout({ children, footerBar, fixed, flexColumn }) {
  let sx = {
    // backgroundColor: "red",
    "--titlebar-area-height": "env(titlebar-area-height, 0px)",
    "--titlebar-space": (theme) =>
      `calc(var(--titlebar-area-height) + ${footerBar ? theme.mixins.totalToolbarHeight * 2 : theme.mixins.totalToolbarHeight}px)`,
    height: (theme) => `calc(100vh - var(--titlebar-space))`,
    overflowX: "hidden",
    ...(fixed
      ? { overflowY: "hidden", overscrollBehaviorY: "none" }
      : { overflowY: "scroll", overscrollBehaviorY: "contain" }),

    ...(flexColumn ? { display: "flex", flexDirection: "column" } : {}),
  };

  return (
    <Box component="main" sx={sx}>
      {children}
    </Box>
  );
}
