import { useMediaQuery } from "@mui/material";

import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import { Link as ReactRouterLink, useLocation } from "react-router";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import FlightIcon from "@mui/icons-material/Flight";
import HotelIcon from "@mui/icons-material/Hotel";
import ModeOfTravelIcon from "@mui/icons-material/ModeOfTravel";
import ConnectingAirportsIcon from "@mui/icons-material/ConnectingAirports";
import ToolbarControlButtonStack from "./ToolbarControlButtonStack.js";

export default function AppHeaderBar() {
  const { pathname } = useLocation();
  const hidden = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const knownValues = ["/", "/hotels", "/trips", "/flights", "/bookings"];
  const isKnown = knownValues.includes(pathname);
  return (
    <AppBar
      position="fixed"
      color="default"
      sx={{
        backgroundImage: "none",
        borderBottomColor: "divider",
        borderBottomStyle: "solid",
        borderBottomWidth: "1px",
        // For the PWA Window
        appRegion: "drag",
        WebkitAppRegion: "drag",
        paddingTop: "env(titlebar-area-height, 0px)",
      }}
      elevation={0}
    >
      <Toolbar variant="dense" disableGutters sx={{ minHeight: (theme) => theme.mixins.toolbar.minHeight }}>
        <Tabs value={isKnown ? pathname : false}>
          <Tab
            component={ReactRouterLink}
            to="/"
            sx={{ minWidth: "unset" }}
            icon={<ModeOfTravelIcon fontSize="small" />}
            iconPosition="start"
            label={hidden ? null : "Trips"}
            value="/"
            disableRipple
          />
          <Tab
            component={ReactRouterLink}
            to="/bookings"
            sx={{ minWidth: "unset" }}
            icon={<FlightIcon fontSize="small" />}
            iconPosition="start"
            label={hidden ? null : "Travel"}
            value="/bookings"
            disableRipple
          />
          <Tab
            component={ReactRouterLink}
            to="/hotels"
            sx={{ minWidth: "unset" }}
            icon={<HotelIcon fontSize="small" />}
            iconPosition="start"
            label={hidden ? null : "Hotels"}
            value="/hotels"
            disableRipple
          />

          <Tab
            component={ReactRouterLink}
            to="/flights"
            sx={{ minWidth: "unset" }}
            icon={<ConnectingAirportsIcon fontSize="small" />}
            iconPosition={hidden ? "top" : "start"}
            label={hidden ? null : "Flights"}
            value="/flights"
            disableRipple
          />
        </Tabs>
        <Box sx={{ flexGrow: 1 }} />
        <ToolbarControlButtonStack />
      </Toolbar>
    </AppBar>
  );
}
