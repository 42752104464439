import { useState, useRef, useCallback } from "react";
import DialogContext from "./DialogContext.js";
import ConfirmationModal from "./ConfirmationModal.js";

const getEmptyState = (options = {}) => ({ text: null, title: null, ...options });

export default function DialogProvider({ children }) {
  const [confirmationState, setConfirmationState] = useState(getEmptyState());
  const awaitingPromiseRef = useRef();
  const [isOpen, setIsOpen] = useState(false);

  const closeDialog = () => setIsOpen(false);

  const confirmAction = useCallback(
    async (options) => {
      if (isOpen) return Promise.resolve(false);
      setConfirmationState({
        text: options.text,
        title: options.title || null,
        submitButtonLabel: options.submitButtonLabel || "Continue",
        color: options.color || undefined,
      });
      setIsOpen(true);
      return new Promise((resolve) => {
        awaitingPromiseRef.current = resolve;
      });
    },
    [isOpen],
  );

  const handleClose = (silent) => {
    if (!silent && awaitingPromiseRef.current) {
      awaitingPromiseRef.current(false);
    }

    closeDialog();
  };

  const handleSubmit = () => {
    if (awaitingPromiseRef.current) {
      awaitingPromiseRef.current(true);
    }
    closeDialog();
  };

  return (
    <>
      <DialogContext.Provider value={confirmAction}>{children}</DialogContext.Provider>
      <ConfirmationModal
        onSubmit={handleSubmit}
        onClose={handleClose}
        isOpen={isOpen}
        text={confirmationState.text}
        title={confirmationState.title}
        submitButtonLabel={confirmationState.submitButtonLabel}
        color={confirmationState.color}
      />
    </>
  );
}
