import Link from "@mui/material/Link";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import Button from "@mui/material/Button";
import { useState } from "react";
import TextField from "@mui/material/TextField";
import { useIntl } from "react-intl";
import { Link as ReactRouterLink } from "react-router";
import useSnackBar from "../../contexts/SnackBar/useSnackBar.js";
import stripWhiteSpace from "../../lib/stripWhiteSpace.js";
import { db } from "../../models/db.js";
import Stack from "@mui/material/Stack";

export default function AircraftTableRow({ aircraft }) {
  const { enqueueSnackbar } = useSnackBar();
  const { formatNumber } = useIntl();

  const [editMode, setEditMode] = useState(false);
  const [model, setModel] = useState(aircraft.model);
  const [id, setId] = useState(aircraft.id);

  const onEditClick = () => {
    setEditMode(!editMode);
  };

  const onDeleteClick = async () => {
    const result = await db.deleteAircraft(aircraft.id);
    if (result?.success) {
      enqueueSnackbar({ message: `${model} Deleted`, variant: "success" });
    } else {
      enqueueSnackbar({ message: result.message, variant: "error" });
    }
  };

  const onCancelClick = () => {
    setEditMode(false);
    setModel(aircraft.model);
    setId(aircraft.id);
  };

  const onSaveClick = async () => {
    await db.updateAircraft(
      {
        ...aircraft,
        id,
        model,
      },
      aircraft,
    );
    enqueueSnackbar({ message: `${model} Updated`, variant: "success" });
    setEditMode(!editMode);
  };

  const onTitleChange = (event) => {
    setModel(event.target.value);
  };

  const onIdChange = (event) => {
    setId(stripWhiteSpace(event.target.value.toUpperCase()));
  };

  const onRecalculateLifetimeSpendClick = async () => {
    await db.recalculateAircraft(aircraft.id);
    enqueueSnackbar({ message: `${model} Updated`, variant: "success" });
  };

  return (
    <TableRow hover>
      {!editMode && (
        <TableCell>
          <Link to={`/aircraft/${aircraft.id}`} component={ReactRouterLink}>
            {[aircraft.manufacturer, aircraft.title || aircraft.model].filter(Boolean).join(" ")}
          </Link>
        </TableCell>
      )}
      {!editMode && <TableCell>{aircraft.icao || ""}</TableCell>}
      {editMode && (
        <TableCell>
          <TextField value={model} fullWidth margin="none" required onChange={onTitleChange} />
        </TableCell>
      )}

      <TableCell align="right">
        {aircraft.totalSegments !== undefined ? (
          <span>{formatNumber(aircraft.totalSegments, {})}</span>
        ) : (
          <span>Unknown</span>
        )}
      </TableCell>

      <TableCell align="right">
        {aircraft.totalDistanceInKm !== undefined ? (
          <span>
            {formatNumber(aircraft.totalDistanceInKm, {
              style: "unit",
              unit: "kilometer",
              maximumFractionDigits: 0,
            })}
          </span>
        ) : (
          <span>Unknown</span>
        )}
      </TableCell>

      {/*<TableCell align="right">*/}
      {/*<Stack direction="row" justifyContent="flex-end" alignItems="center" spacing={1}>*/}
      {/*  {aircraft.lifetimeSpend !== undefined ? (*/}
      {/*    <span>{formatNumber(aircraft.lifetimeSpend, { style: "currency", currency: "GBP" })}</span>*/}
      {/*  ) : (*/}
      {/*    <span>Unknown</span>*/}
      {/*  )}*/}

      {/*  <IconButton size="small" disabled={editMode} color="inherit" onClick={onRecalculateLifetimeSpendClick}>*/}
      {/*    <RefreshIcon fontSize="small" />*/}
      {/*  </IconButton>*/}
      {/*</Stack>*/}
      {/*</TableCell>*/}

      <TableCell align="right">
        <Stack direction="row" spacing={0.5}>
          {editMode && (
            <Button color="secondary" onClick={onCancelClick}>
              Cancel
            </Button>
          )}
          {!editMode && (
            <Button color="secondary" disabled onClick={onDeleteClick}>
              Delete
            </Button>
          )}
          {!editMode && (
            <Button color="secondary" onClick={onEditClick}>
              Edit
            </Button>
          )}
          {editMode && (
            <Button color="primary" onClick={onSaveClick}>
              Save
            </Button>
          )}
        </Stack>
      </TableCell>
    </TableRow>
  );
}
