import { lazy, Suspense, useMemo } from "react";

const AllFlightsGlobe = lazy(() => import(/* webpackChunkName: "globe" */ "./AllFlightsGlobe.js"));

const EmptyFallback = () => null;

export default function GlobalMapLoader() {
  return useMemo(
    () => (
      <Suspense fallback={<EmptyFallback />}>
        <AllFlightsGlobe />
      </Suspense>
    ),
    [],
  );
}
