import useStayLocations from "../../contexts/StayLocations/useStayLocations.js";
import { AutoCompleteListItem } from "./AutocompleteList.js";
import CreatableAutocomplete from "./CreatableAutocomplete.js";
import Typography from "@mui/material/Typography";

export default function StayLocationsAutocomplete({ value, onChange, label, stayBrandId, isNewBrandSelected }) {
  const { stayLocations, isLoaded } = useStayLocations();

  let options = stayLocations;

  if (isLoaded && stayBrandId) {
    options = options.filter((option) => option.brand.id === stayBrandId);
  } else if (isLoaded && isNewBrandSelected) {
    options = [];
  }

  let error = false;

  if (value?.id && !options.find((option) => option.id === value.id)) {
    error = true;
  }

  const renderOption = (props, option) => {
    return (
      <AutoCompleteListItem {...props} key={`${props.key}_${option.brand?.id || ""}`}>
        <Typography variant="body2">{option.title}</Typography>
        {!stayBrandId && option.brand?.title && (
          <Typography variant="body2" color="text.disabled" paddingLeft={1} display="inline-block">
            {option.brand.title}
          </Typography>
        )}
      </AutoCompleteListItem>
    );
  };

  return (
    <CreatableAutocomplete
      value={value}
      error={error}
      onChange={onChange}
      options={options}
      label={label || "Location"}
      forcePopupIcon
      sx={{ width: 300 }}
      required
      renderOption={renderOption}
      loading={!isLoaded}
    />
  );
}
