export default function emptyStayObject({ bookingId, checkInDate, checkOutDate, nights }) {
  const now = new Date();

  return {
    id: self.crypto.randomUUID(),
    bookingId,
    createdAt: now,
    updatedAt: now,
    checkInDate: checkInDate || null,
    checkOutDate: checkOutDate || null,
    nights: nights || null,
    brand: null,
    location: null,
    isSkipped: undefined,
    roomNumber: "",
    roomName: "",
    note: "",
    bookingReference: "",
    loyaltyProgramIds: [],
    loyaltyProgramActivities: [],
  };
}
