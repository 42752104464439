import Divider from "@mui/material/Divider";

import CarrierProvider from "../../contexts/Carrier/CarrierProvider.js";
import FlightsList from "../FlightsList/FlightsList.js";
import SegmentsList from "../FlightsList/SegmentsList.js";
import MainScrollLayout from "../MainScrollLayout.js";
import SubScrollLayout from "../SubScrollLayout.js";
import CarrierDetailsCard from "./CarrierDetailsCard.js";
import CarrierFixedFooterCard from "./CarrierFixedFooterAppBar.js";
import CarriersBookingsList from "./CarriersBookingsList.js";
import { useParams } from "react-router";

export default function CarriersBookingsListPage() {
  const { carrierId, tab } = useParams();
  const linkState = { source: "carrier" };

  const tabSafe = tab || "bookings";

  return (
    <>
      <CarrierProvider id={carrierId}>
        <MainScrollLayout footerBar fixed flexColumn>
          <CarrierDetailsCard />
          <Divider />
          <SubScrollLayout key={tabSafe}>
            {tabSafe === "bookings" && <CarriersBookingsList carrierId={carrierId} linkState={linkState} />}
            {tabSafe === "flights" && <FlightsList carrierId={carrierId} linkState={linkState} />}
            {tabSafe === "segments" && <SegmentsList carrierId={carrierId} linkState={linkState} />}
          </SubScrollLayout>
        </MainScrollLayout>
        <CarrierFixedFooterCard tabSafe={tabSafe} linkState={linkState} carrierId={carrierId} />
      </CarrierProvider>
    </>
  );
}
