import Joi from "joi";
import generateIdFromTitle from "../lib/generateIdFromTitle.js";
import { BUS, TRAIN, BOAT, CAR, FLIGHT, OTHER } from "./CarrierConstants.js";

export const CarrierValidator = Joi.object({
  title: Joi.string().required().example("Air France"),
  id: Joi.string().required(),
  type: Joi.string().optional().allow(BUS, TRAIN, BOAT, CAR, FLIGHT, OTHER),
  createdAt: Joi.date().required(),
  updatedAt: Joi.date().required(),
  lifetimeSpend: Joi.number().min(0).precision(2).required(),
  totalDistanceInKm: Joi.number().min(0).required().precision(2),
  totalSegments: Joi.number().min(0).required(),
  totalBookings: Joi.number().min(0).required(),
  totalSegmentsWithDistanceInKm: Joi.number().min(0).required(),
  icao: Joi.string().optional().example("AFR"),
  iata: Joi.string().optional().example("AF"),
  firstSegmentDepartureDate: Joi.date().optional(),
  lastSegmentDepartureDate: Joi.date().optional(),
}).label("Carrier");

/**
 * Represents a Carrier, which is a transport company, like an airline.
 */
export default class Carrier {
  constructor({
    id,
    title,
    _id,
    createdAt,
    updatedAt,
    type,
    lifetimeSpend,
    icao,
    iata,
    totalDistanceInKm,
    totalBookings,
    totalSegments,
    totalSegmentsWithDistanceInKm,
    firstSegmentDepartureDate,
    lastSegmentDepartureDate,
  } = {}) {
    const candidateId = id || _id;
    this.id = generateIdFromTitle(candidateId || title);
    this.title = title || undefined;
    this.createdAt = createdAt || undefined;
    this.updatedAt = updatedAt || undefined;
    this.type = type || FLIGHT;
    this.lifetimeSpend = lifetimeSpend || 0.0;
    this.icao = icao || undefined;
    this.iata = iata || undefined;
    this.totalDistanceInKm = totalDistanceInKm || 0.0;
    this.totalBookings = totalBookings || 0;
    this.totalSegments = totalSegments || 0;
    this.totalSegmentsWithDistanceInKm = totalSegmentsWithDistanceInKm || 0;
    this.firstSegmentDepartureDate = firstSegmentDepartureDate || undefined;
    this.lastSegmentDepartureDate = lastSegmentDepartureDate || undefined;
  }

  /**
   * The title.
   * @type {String}
   */
  title;

  /**
   * The IATA registration, if any.
   * @type {String}
   * @example "AF"
   */
  iata;

  /**
   * The ICAO registration, if any.
   * @type {String}
   * @example "AFF"
   */
  icao;

  /**
   * The type ENUM value.
   * @type {String|undefined}
   */
  type;

  /**
   * The creation date.
   * @type {Date}
   */
  createdAt;

  /**
   * The update date.
   * @type {Date}
   */
  updatedAt;

  /**
   * The ID
   * @type {String}
   */
  id;

  /**
   * Lifetime spend
   * @type {Number}
   */
  lifetimeSpend;

  /**
   * The total number of paid bookings.
   * @type {Number}
   */
  totalBookings;

  /**
   * The total number of paid segments.
   * @type {Number}
   */
  totalSegments;

  /**
   * The total number of segments with distance information.
   * @type {Number}
   */
  totalSegmentsWithDistanceInKm;

  /**
   * The total kilometers travelled (by any means) on this trip. Calculated.
   * @type {Number}
   */
  totalDistanceInKm;

  /**
   * The first primary passenger non-cancelled segment with this Carrier.
   * @type {Date}
   */
  firstSegmentDepartureDate;

  /**
   * The last primary passenger non-cancelled segment with this Carrier.
   * @type {Date}
   */
  lastSegmentDepartureDate;

  /**
   * Indicates if this instance and its data is valid.
   * @return {boolean}
   */
  get isValid() {
    const { error, value } = CarrierValidator.validate(this);
    if (error === undefined) {
      return true;
    }
    console.log(error, value);
    return false;
  }
}
