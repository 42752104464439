import { useLiveQuery } from "dexie-react-hooks";

import { db } from "../../models/db.js";
import CarrierContext from "./CarrierContext.js";

export default function CarrierProvider({ children, id, carrier: manualCarrier }) {
  const carrier = useLiveQuery(() => (id ? db.carriers.get(id) : null), [id]);
  const result = manualCarrier || carrier;
  const isLoaded = result !== undefined;
  return <CarrierContext.Provider value={{ carrier: result || null, isLoaded }}>{children}</CarrierContext.Provider>;
}
