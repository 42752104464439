import Joi from "joi";
import generateIdFromTitle from "../lib/generateIdFromTitle.js";

export const StationValidator = Joi.object({
  title: Joi.string().required().example("Guarulhos - Governador André Franco Montoro"),
  id: Joi.string().required().uppercase().min(3).max(3).example("GRU"), // IATA ID if airport,
  createdAt: Joi.date().required(),
  updatedAt: Joi.date().required(),
  icao: Joi.string().optional().uppercase().min(4).max(4).example("SBGR"),
  iata: Joi.string().optional().uppercase().min(3).max(3).example("GRU"), // IATA ID if airport,
  lat: Joi.number().optional(),
  lon: Joi.number().optional(),
  timeZone: Joi.string().optional().example("America/Sao_Paulo"),
  shortName: Joi.string().optional().example("Guarulhos - Governador André Franco Montoro"),
  fullName: Joi.string().optional().example("São Paulo Guarulhos - Governador André Franco Montoro"),
  municipalityName: Joi.string().optional().example("São Paulo"),
  country: Joi.object({
    code: Joi.string().required().example("BR"),
    name: Joi.string().optional().example("Brazil"),
  }).optional(),
  continent: Joi.object({
    code: Joi.string().required().example("SA"),
    name: Joi.string().optional().example("South America"),
  }).optional(),
  hasData: Joi.boolean().optional().example(false),
  totalSegments: Joi.number().min(0).required(),
  totalBookings: Joi.number().min(0).required(),
  totalDepartures: Joi.number().min(0).required(),
  totalArrivals: Joi.number().min(0).required(),
  firstSegmentDepartureDate: Joi.date().optional(),
  lastSegmentDepartureDate: Joi.date().optional(),
  isAirport: Joi.boolean().optional(),
  isTrainStation: Joi.boolean().optional(),
  isBusStation: Joi.boolean().optional(),
}).label("Station");

/**
 * Represents a Station, which can be an airport, or train station, etc. It's a departure point.
 */
export default class Station {
  constructor({
    id,
    title,
    createdAt,
    updatedAt,
    icao,
    iata,
    lat,
    lon,
    timeZone,
    country,
    continent,
    shortName,
    fullName,
    municipalityName,
    hasData,
    totalSegments,
    totalBookings,
    totalDepartures,
    totalArrivals,
    firstSegmentDepartureDate,
    lastSegmentDepartureDate,
    isAirport,
    isTrainStation,
    isBusStation,
  } = {}) {
    this.id = id || generateIdFromTitle(title)?.substring(0, 3);
    this.title = title || undefined;
    this.createdAt = createdAt || undefined;
    this.updatedAt = updatedAt || undefined;
    this.icao = icao || undefined;
    this.iata = iata || undefined;
    this.timeZone = timeZone || undefined;
    this.lat = lat ?? undefined;
    this.lon = lon ?? undefined;
    this.country = country || undefined;
    this.continent = continent || undefined;
    this.shortName = shortName || undefined;
    this.fullName = fullName || undefined;
    this.municipalityName = municipalityName || undefined;
    this.hasData = hasData;
    this.totalSegments = totalSegments || 0;
    this.totalBookings = totalBookings || 0;
    this.totalDepartures = totalDepartures || 0;
    this.totalArrivals = totalArrivals || 0;
    this.firstSegmentDepartureDate = firstSegmentDepartureDate || undefined;
    this.lastSegmentDepartureDate = lastSegmentDepartureDate || undefined;
    this.isAirport = isAirport || undefined;
    this.isTrainStation = isTrainStation || undefined;
    this.isBusStation = isBusStation || undefined;
  }

  /**
   * The title of this station.
   * @type {String}
   */
  title;

  /**
   * The Station ID - this should be the IATA (three letter) airport code.
   * @type {String}
   * @example "GRU"
   */
  id;

  /**
   * The IATA code - (three letter) airport code.
   * @type {String}
   * @example "GRU"
   */
  iata;

  /**
   * The Station ID - this is the four-letter ICAO code
   * @example "SBGR"
   * @type {String|undefined}
   */
  icao;

  /**
   * The short name
   * @example Schiphol
   * @type {String|undefined}
   */
  shortName;
  /**
   * The long name
   * @example Amsterdam Schiphol
   * @type {String|undefined}
   */
  fullName;

  /**
   * The city name
   * @example Amsterdam
   * @type {String|undefined}
   */
  municipalityName;

  /**
   * The latitude.
   * @type {Number|undefined}
   */
  lat;

  /**
   * The longitude.
   * @type {Number|undefined}
   */
  lon;

  /**
   * The timezone identifier
   * @example Europe/Amsterdam
   * @type {String|undefined}
   */
  timeZone;

  /**
   * Indicates if this station has been populated with high quality location information.
   */
  hasData;

  /**
   * The country
   * @type {{code: String, name: String}|undefined}
   */
  country;

  /**
   * The continent
   * @type {{code: String, name: String}|undefined}
   */
  continent;

  /**
   * The creation date.
   * @type {Date}
   */
  createdAt;

  /**
   * The update date.
   * @type {Date}
   */
  updatedAt;

  /**
   * The total number of paid bookings.
   * @type {Number}
   */
  totalBookings;

  /**
   * The total number of paid segments.
   * @type {Number}
   */
  totalSegments;

  /**
   * The total number of paid segment arrivals TO this station.
   * @type {Number}
   */
  totalArrivals;

  /**
   * The total number of paid segment departures FROM this station.
   * @type {Number}
   */
  totalDepartures;

  /**
   * The first primary passenger non-cancelled segment routed through this Station.
   * @type {Date}
   */
  firstSegmentDepartureDate;

  /**
   * The last primary passenger non-cancelled segment routed through this Station.
   * @type {Date}
   */
  lastSegmentDepartureDate;

  /**
   * Indicates if this is an Airport
   * @type {Boolean}
   */
  isAirport;

  /**
   * Indicates if this is a Train Station
   * @type {Boolean}
   */
  isTrainStation;

  /**
   * Indicates if this is a Bus Station or with a bus connection
   * @type {Boolean}
   */
  isBusStation;

  /**
   * Indicates if this Station and its data is valid.
   * @return {Boolean}
   */
  get isValid() {
    const { error, value } = StationValidator.validate(this);
    if (error === undefined) {
      return true;
    }
    console.log(error, value);
    return false;
  }
}
