import { isAfter, isBefore } from "date-fns";
import { Dexie } from "dexie";
import identifyAircraftModel from "../utils/identifyAircraftModel.js";
import shouldSegmentBeIgnored from "../utils/shouldSegmentBeIgnored.js";
import { CANCELLED, REFUNDED } from "./BookingConstants.js";
import { FLIGHT } from "./CarrierConstants.js";
import getSegmentStationIds from "./getSegmentStationIds.js";
import Trip from "./Trip.js";
import Booking from "./Booking.js";
import Aircraft from "./Aircraft.js";
import Segment from "./Segment.js";
import Station from "./Station.js";
import Carrier from "./Carrier.js";
import Agent from "./Agent.js";
import Stay from "./Stay.js";
import StayBrand from "./StayBrand.js";
import StayLocation from "./StayLocation.js";
import PaymentMethod from "./PaymentMethod.js";
import LoyaltyProgram from "./LoyaltyProgram.js";
import { Big } from "big.js";

function shouldBookingBeIgnored(booking) {
  if (booking.status === REFUNDED) {
    // Refunded bookings are basically ignored as if they never happened.
    return true;
  }
  if (booking.status === CANCELLED && !booking.isPaid) {
    // Cancelled bookings that were never paid are basically ignored as if they never happened.
    return true;
  }

  // 'BOOKED', or 'cancelled but not refunded'
  return false;
}

export class TravelDB extends Dexie {
  constructor() {
    super("TravelDB");
    this.version(1).stores({
      trips: "id, sortDate",
      bookings:
        "id, trip.id, agent.id, bookingDate, *type, leadingCarrier.id, leadingStayBrand.id, leadingStayLocation.id, *segmentCarrierIds, *segmentStationIds",
      segments:
        "id, trip.id, booking.id, aircraft.id, carrier.id, origin.id, destination.id, departureDate, *loyaltyProgramIds",
      flights:
        "id, trip.id, booking.id, aircraft.id, carrier.id, origin.id, destination.id, departureDate, *loyaltyProgramIds",
      stays: "id, trip.id, booking.id, brand.id, location.id, checkInDate, *loyaltyProgramIds",
      stations: "id, &title",
      carriers: "id",
      stayBrands: "id",
      stayLocations: "id, brand.id",
      agents: "id",
      paymentMethods: "id",
      loyaltyPrograms: "id",
      aircraft: "id",
    });

    this.table("segments").mapToClass(Segment);
    this.table("flights").mapToClass(Segment);
    this.table("stays").mapToClass(Stay);
    this.table("bookings").mapToClass(Booking);
    this.table("trips").mapToClass(Trip);
    this.table("stations").mapToClass(Station);
    this.table("carriers").mapToClass(Carrier);
    this.table("agents").mapToClass(Agent);
    this.table("stayBrands").mapToClass(StayBrand);
    this.table("aircraft").mapToClass(Aircraft);
    this.table("stayLocations").mapToClass(StayLocation);
    this.table("paymentMethods").mapToClass(PaymentMethod);
    this.table("loyaltyPrograms").mapToClass(LoyaltyProgram);
  }

  async deleteTrip(tripId) {
    return this.transaction("rw", [this.trips, this.bookings, this.segments, this.flights, this.stays], async () => {
      return Promise.all([
        this.stays.where({ "trip.id": tripId }).delete(),
        this.segments.where({ "trip.id": tripId }).delete(),
        this.flights.where({ "trip.id": tripId }).delete(),
        this.bookings.where({ "trip.id": tripId }).delete(),

        this.trips.delete(tripId),
      ]);

      // TODO: Recalculate all carriers, agents, etc as bookings would have changed
    });
  }

  async deleteBooking(bookingId) {
    const booking = await db.bookings.get(bookingId);
    await this.transaction("rw", [this.bookings, this.stays, this.segments, this.flights], async () => {
      return Promise.all([
        this.segments.where({ "booking.id": bookingId }).delete(),
        this.flights.where({ "booking.id": bookingId }).delete(),
        this.stays.where({ "booking.id": bookingId }).delete(),
        this.bookings.delete(bookingId),
      ]);
    });

    if (booking.trip) {
      await this.recalculateTrip(booking.trip.id);
    }

    if (booking.agent) {
      await this.recalculateAgent(booking.agent.id);
    }

    // TODO: Recalculate all carriers, agents, etc as bookings would have changed
  }

  async getBooking(bookingId) {
    const booking = await db.bookings.get(bookingId);
    if (!booking) return null;

    return { booking };
  }

  /**
   * @param {String} tripId
   * @return {Promise<{ trip: Trip|null, bookings: Booking[] }>}
   */
  async getTrip(tripId) {
    const result = await Promise.all([db.trips.get(tripId), db.bookings.where({ "trip.id": tripId }).toArray()]);
    return { trip: result[0], bookings: result[1] };
  }

  async deleteCarrier(carrierId) {
    const carrier = await db.carriers.get(carrierId);
    if (!carrier) return { success: false, message: "Carrier does not exist" };
    const bookings = await this.bookings.where("segmentCarrierIds").equals(carrierId).toArray();
    if (bookings.length) {
      return { success: false, message: `${carrier.title} cannot be deleted when its associated to existing bookings` };
    }
    this.carriers.delete(carrierId);
    return { success: true };
  }

  async deleteAircraft(aircraftId) {
    const aircraft = await db.aircraft.get(aircraftId);
    if (!aircraft) return { success: false, message: "Aircraft does not exist" };
    const flights = await this.flights.where("aircraft.id").equals(aircraftId).toArray();
    if (flights.length) {
      return {
        success: false,
        message: `${aircraft.model} cannot be deleted when it is associated to existing flights`,
      };
    }
    this.aircraft.delete(aircraftId);
    return { success: true };
  }

  async deleteAgent(agentId) {
    const agent = await db.agents.get(agentId);
    if (!agent) return { success: false, message: "Agent does not exist" };
    const bookings = await this.bookings.where("agent.id").equals(agentId).toArray();
    if (bookings.length) {
      return { success: false, message: `${agent.title} cannot be deleted when it is associated to existing bookings` };
    }
    this.agents.delete(agentId);
    return { success: true };
  }

  async deleteLoyaltyProgram(loyaltyProgramId) {
    const loyaltyProgram = await db.loyaltyPrograms.get(loyaltyProgramId);
    if (!loyaltyProgram) return { success: false, message: "Program does not exist" };

    const segmentsOnProgram = await this.segments.where("loyaltyProgramIds").equals(loyaltyProgramId).toArray();
    const staysOnProgram = await this.stays.where("loyaltyProgramIds").equals(loyaltyProgramId).toArray();

    if (staysOnProgram.length || segmentsOnProgram.length) {
      return {
        success: false,
        message: `${loyaltyProgram.title} cannot be deleted when it is associated to existing activity`,
      };
    }

    this.loyaltyPrograms.delete(loyaltyProgramId);
    return { success: true };
  }

  /**
   * Delete a `Stay Brand`.
   * @param {String} stayBrandId - The Stay Brand ID
   * @return {Promise<{success: boolean, message: string|undefined}>}
   */
  async deleteStayBrand(stayBrandId) {
    const stayBrand = await db.stayBrands.get(stayBrandId);
    if (!stayBrand) return { success: false, message: "Brand does not exist" };
    const stays = await this.stays.where("brand.id").equals(stayBrandId).toArray();
    if (stays.length) {
      return {
        success: false,
        message: `${stayBrand.title} cannot be deleted when its associated to existing bookings`,
      };
    }

    await db.transaction("rw", [db.stayLocations, db.stayBrands], async () => {
      return Promise.all([
        this.stayLocations.where("brand.id").equals(stayBrandId).delete(),
        this.stayBrands.delete(stayBrandId),
      ]);
    });

    return { success: true };
  }

  /**
   * Delete a `Stay Brand Location`.
   * @param {String} stayBrandLocationId - The Stay Brand Location ID
   * @return {Promise<{success: boolean, message: string|undefined}>}
   */
  async deleteStayBrandLocation(stayBrandLocationId) {
    const stayBrandLocation = await db.stayLocations.get(stayBrandLocationId);
    if (!stayBrandLocation) return { success: false, message: "Location does not exist" };
    const stays = await this.stays.where("location.id").equals(stayBrandLocationId).toArray();
    if (stays.length) {
      return {
        success: false,
        message: `${stayBrandLocation.title} cannot be deleted when its associated to existing bookings`,
      };
    }

    await db.transaction("rw", [db.stayLocations, db.stayBrands], async () => {
      return Promise.all([this.stayLocations.delete(stayBrandLocationId)]);
    });

    return { success: true };
  }

  async recalculateEverything() {
    await db.recalculateAircraftFromFlights();

    const getId = (item) => item.id;
    await db.transaction(
      "rw",
      [db.bookings, db.carriers, db.segments, db.stayBrands, db.agents, db.stations, db.aircraft, db.flights],
      async () => {
        const carriers = (await db.carriers.toArray()).map(getId);
        const stayBrands = (await db.stayBrands.toArray()).map(getId);
        const agents = (await db.agents.toArray()).map(getId);
        const stations = await db.stations.toArray();
        const aircraft = (await db.aircraft.toArray()).map(getId);

        for (let i = 0; i < carriers.length; i++) {
          const carrierId = carriers[i];
          await db.recalculateCarrier(carrierId);
        }

        for (let i = 0; i < stayBrands.length; i++) {
          const stayBrandId = stayBrands[i];
          await db.recalculateStayBrand(stayBrandId);
        }

        for (let i = 0; i < agents.length; i++) {
          const agentId = agents[i];
          await db.recalculateAgent(agentId);
        }

        for (let i = 0; i < stations.length; i++) {
          const station = stations[i];
          await db.recalculateStation(station.id, station);
        }

        for (let i = 0; i < aircraft.length; i++) {
          const aircraftId = aircraft[i];
          await db.recalculateAircraft(aircraftId);
        }
      },
    );
  }

  async recalculateAircraftFromFlights() {
    await db.transaction("rw", [db.aircraft, db.flights, db.segments], async () => {
      const flights = await db.flights.toArray();
      const existingSystemAircraft = await db.aircraft.toArray();

      for (let i = 0; i < flights.length; i++) {
        const flight = flights[i];

        if (flight.aircraft) {
          // record has aircraft perhaps not saved.
          const existingRecord = existingSystemAircraft.find((aircraft) => aircraft.model === flight.aircraft.model);
          if (existingRecord) {
            // assign the ID to the record, and move on
            const dataToAdd = { ...flight.aircraft, id: existingRecord.id, model: existingRecord.model };
            await db.flights.update(flight.id, {
              aircraft: dataToAdd,
            });
            await db.segments.update(flight.id, { aircraft: dataToAdd });
          } else {
            // flight has a type not yet created.
            const newAircraftRecord = new Aircraft(flight.aircraft);
            const dataToAdd = { id: newAircraftRecord.id, model: newAircraftRecord.model };

            await db.aircraft.put(dataToAdd);

            existingSystemAircraft.push(dataToAdd);
            await db.flights.update(flight.id, { aircraft: { ...flight.aircraft, ...dataToAdd } });
            await db.segments.update(flight.id, { aircraft: { ...flight.aircraft, ...dataToAdd } });
          }
        }
      }
    });
  }

  async recalculateStation(stationId, existingStation) {
    return db.transaction("rw", [db.bookings, db.stations], async () => {
      const bookings = await db.bookings.where({ segmentStationIds: stationId }).toArray();

      const bookingStatus = bookings.reduce(
        (acc, booking) => {
          if (shouldBookingBeIgnored(booking)) {
            return acc;
          }

          acc.totalBookings = acc.totalBookings + 1;

          booking.segments.forEach((segment) => {
            if (
              shouldSegmentBeIgnored(segment) ||
              (segment.origin.id !== stationId && segment.destination.id !== stationId)
            ) {
              return;
            }

            if (segment.origin.id === stationId || segment.destination.id === stationId) {
              acc.totalSegments = acc.totalSegments + 1;
            }

            if (segment.origin.id === stationId) {
              acc.totalDepartures = acc.totalDepartures + 1;
            }

            if (segment.destination.id === stationId) {
              acc.totalArrivals = acc.totalArrivals + 1;
            }

            if (!acc.firstSegmentDepartureDate || isBefore(segment.departureDate, acc.firstSegmentDepartureDate)) {
              acc.firstSegmentDepartureDate = segment.departureDate;
            }

            if (!acc.lastSegmentDepartureDate || isAfter(segment.departureDate, acc.lastSegmentDepartureDate)) {
              acc.lastSegmentDepartureDate = segment.departureDate;
            }
          });

          return acc;
        },
        {
          totalBookings: 0,
          totalSegments: 0,
          totalDepartures: 0,
          totalArrivals: 0,
          firstSegmentDepartureDate: null,
          lastSegmentDepartureDate: null,
        },
      );

      const defaultIATA = existingStation.id.length === 3 ? existingStation.id : undefined;
      await db.stations.update(stationId, {
        totalBookings: bookingStatus.totalBookings,
        totalSegments: bookingStatus.totalSegments,
        totalDepartures: bookingStatus.totalDepartures,
        totalArrivals: bookingStatus.totalArrivals,
        firstSegmentDepartureDate: bookingStatus.firstSegmentDepartureDate,
        lastSegmentDepartureDate: bookingStatus.lastSegmentDepartureDate,
        isAirport: existingStation?.isAirport || Boolean(existingStation?.icao),
        iata: existingStation?.iata || defaultIATA,
      });
    });
  }

  async recalculateCarrier(carrierId) {
    return db.transaction("rw", [db.bookings, db.carriers, db.segments], async () => {
      const bookings = await db.bookings.where({ "leadingCarrier.id": carrierId }).toArray();
      const segments = await db.segments.where({ "carrier.id": carrierId }).toArray();

      const segmentStats = segments.reduce(
        (acc, segment) => {
          if (shouldBookingBeIgnored(segment.booking)) {
            return acc;
          }

          if (shouldSegmentBeIgnored(segment)) {
            return acc;
          }

          if (segment.distanceInKm > 0) {
            acc.distanceInKm = acc.distanceInKm.plus(segment.distanceInKm);
            acc.totalSegmentsWithDistanceInKm = acc.totalSegmentsWithDistanceInKm + 1;
          }

          acc.totalSegments = acc.totalSegments + 1;

          if (!acc.firstSegmentDepartureDate || isBefore(segment.departureDate, acc.firstSegmentDepartureDate)) {
            acc.firstSegmentDepartureDate = segment.departureDate;
          }

          if (!acc.lastSegmentDepartureDate || isAfter(segment.departureDate, acc.lastSegmentDepartureDate)) {
            acc.lastSegmentDepartureDate = segment.departureDate;
          }

          return acc;
        },
        {
          distanceInKm: new Big(0.0),
          totalSegments: 0,
          totalSegmentsWithDistanceInKm: 0,
          firstSegmentDepartureDate: null,
          lastSegmentDepartureDate: null,
        },
      );

      const bookingStatus = bookings.reduce(
        (acc, booking) => {
          if (shouldBookingBeIgnored(booking)) {
            return acc;
          }

          if (booking.pricePaid > 0) {
            acc.lifetimeSpend = acc.lifetimeSpend.plus(booking.pricePaid);
          }

          acc.totalBookings = acc.totalBookings + 1;

          return acc;
        },
        { lifetimeSpend: new Big(0.0), totalBookings: 0 },
      );
      const lifetimeSpend = bookingStatus.lifetimeSpend.toNumber();
      const totalDistanceInKm = segmentStats.distanceInKm.toNumber();

      await db.carriers.update(carrierId, {
        lifetimeSpend,
        totalDistanceInKm,
        totalSegments: segmentStats.totalSegments,
        totalBookings: bookingStatus.totalBookings,
        totalSegmentsWithDistanceInKm: segmentStats.totalSegmentsWithDistanceInKm,
        firstSegmentDepartureDate: segmentStats.firstSegmentDepartureDate,
        lastSegmentDepartureDate: segmentStats.lastSegmentDepartureDate,
      });
      return lifetimeSpend;
    });
  }

  async recalculateAircraft(aircraftId) {
    if (!aircraftId) throw new Error(`aircraftId not supplied`);
    return db.transaction("rw", [db.aircraft, db.flights], async () => {
      const flights = await db.flights.where({ "aircraft.id": aircraftId }).toArray();
      const record = await db.aircraft.get(aircraftId);

      const flightStats = flights.reduce(
        (acc, segment) => {
          if (shouldBookingBeIgnored(segment.booking)) {
            return acc;
          }

          if (shouldSegmentBeIgnored(segment)) {
            return acc;
          }

          if (segment.distanceInKm > 0) {
            acc.distanceInKm = acc.distanceInKm.plus(segment.distanceInKm);
            acc.totalSegmentsWithDistanceInKm = acc.totalSegmentsWithDistanceInKm + 1;
          }

          acc.totalSegments = acc.totalSegments + 1;

          if (!acc.firstSegmentDepartureDate || isBefore(segment.departureDate, acc.firstSegmentDepartureDate)) {
            acc.firstSegmentDepartureDate = segment.departureDate;
          }

          if (!acc.lastSegmentDepartureDate || isAfter(segment.departureDate, acc.lastSegmentDepartureDate)) {
            acc.lastSegmentDepartureDate = segment.departureDate;
          }

          return acc;
        },
        {
          distanceInKm: new Big(0.0),
          totalSegments: 0,
          totalSegmentsWithDistanceInKm: 0,
          firstSegmentDepartureDate: null,
          lastSegmentDepartureDate: null,
        },
      );

      const totalDistanceInKm = flightStats.distanceInKm.toNumber();

      const aircraftTypeICAO = identifyAircraftModel(record.model);

      await db.aircraft.update(aircraftId, {
        totalDistanceInKm,
        totalSegments: flightStats.totalSegments,
        totalSegmentsWithDistanceInKm: flightStats.totalSegmentsWithDistanceInKm,
        firstSegmentDepartureDate: flightStats.firstSegmentDepartureDate,
        lastSegmentDepartureDate: flightStats.lastSegmentDepartureDate,
        icao: aircraftTypeICAO?.icao || record.icao,
        title: aircraftTypeICAO?.title || record.title,
        manufacturer: aircraftTypeICAO?.manufacturer || record.manufacturer,
      });
      return totalDistanceInKm;
    });
  }

  async recalculateStayBrand(stayBrandId) {
    return db.transaction("rw", [db.bookings, db.stayBrands], async () => {
      const bookings = await db.bookings.where({ "leadingStayBrand.id": stayBrandId }).toArray();
      const stats = bookings.reduce(
        (acc, booking) => {
          if (shouldBookingBeIgnored(booking)) {
            return acc;
          }

          if (booking.pricePaid > 0) {
            acc.lifetimeSpend = acc.lifetimeSpend.plus(booking.pricePaid);
          }

          if (booking._nights > 0) {
            acc.totalNights = acc.totalNights + booking._nights;
          }

          acc.totalBookings = acc.totalBookings + 1;

          return acc;
        },
        { lifetimeSpend: new Big(0.0), totalNights: 0, totalBookings: 0 },
      );

      const totalPrice = Array.from(
        bookings
          .reduce((acc, booking) => {
            if (shouldBookingBeIgnored(booking)) {
              return acc;
            }
            if (booking.price > 0 && booking.priceCurrency) {
              if (acc.has(booking.priceCurrency)) {
                acc.set(booking.priceCurrency, {
                  currency: booking.priceCurrency,
                  price: acc.get(booking.priceCurrency).price.plus(booking.price),
                });
              } else {
                acc.set(booking.priceCurrency, { currency: booking.priceCurrency, price: new Big(booking.price) });
              }
            }
            return acc;
          }, new Map())
          .values(),
      ).map((value) => {
        return {
          currency: value.currency,
          price: value.price.toNumber(),
        };
      });

      const lifetimeSpend = stats.lifetimeSpend.toNumber();
      await db.stayBrands.update(stayBrandId, {
        lifetimeSpend,
        totalPrice,
        totalNights: stats.totalNights,
        totalBookings: stats.totalBookings,
      });
      return lifetimeSpend;
    });
  }

  async recalculateStayBrandLocation(stayBrandLocationId) {
    return db.transaction("rw", [db.bookings, db.stayLocations], async () => {
      const bookings = await db.bookings.where({ "leadingStayLocation.id": stayBrandLocationId }).toArray();

      const totalNights = bookings.reduce((acc, booking) => {
        if (shouldBookingBeIgnored(booking)) {
          return acc;
        }
        if (booking._nights > 0) {
          return acc + booking._nights;
        }
        return acc;
      }, 0);

      await db.stayLocations.update(stayBrandLocationId, { totalNights });
    });
  }

  async recalculateAgent(agentId) {
    const bookings = await db.bookings.where({ "agent.id": agentId }).toArray();
    const stats = bookings.reduce(
      (acc, booking) => {
        if (shouldBookingBeIgnored(booking)) {
          return acc;
        }
        if (booking.pricePaid > 0) {
          acc.lifetimeSpend = acc.lifetimeSpend.plus(booking.pricePaid);
        }

        if (booking._nights > 0) {
          acc.totalNights = acc.totalNights + booking._nights;
        }

        acc.totalBookings = acc.totalBookings + 1;
        return acc;
      },
      { lifetimeSpend: new Big(0.0), totalBookings: 0, totalNights: 0 },
    );
    const lifetimeSpend = stats.lifetimeSpend.toNumber();
    await db.agents.update(agentId, {
      lifetimeSpend,
      totalBookings: stats.totalBookings,
      totalNights: stats.totalNights,
    });
    return lifetimeSpend;
  }

  async recalculateTrip(tripId) {
    return db.transaction("rw", [db.bookings, db.trips], async () => {
      const { trip, bookings } = await this.getTrip(tripId);
      trip.calculateTotals({ bookings });
      await db.trips.update(trip.id, trip);
      return trip;
    });
  }

  async updateLoyaltyProgram(loyaltyProgram, originalLoyaltyProgram) {
    const updatedLoyaltyProgram = {
      ...loyaltyProgram,
      updatedAt: new Date(),
    };

    const idToUpdate = originalLoyaltyProgram?.id || loyaltyProgram.id;

    await db.loyaltyPrograms.update(idToUpdate, updatedLoyaltyProgram);

    // TODO: Update bookings
    // const updatedObject = { id: agent.id, title: agent.title };
    // await db.bookings.where("agent.id").equals(idToUpdate).modify({ agent: updatedObject });

    return updatedLoyaltyProgram;
  }

  async updateAgent(agent, originalAgent) {
    const updatedAgent = {
      ...agent,
      updatedAt: new Date(),
    };

    const idToUpdate = originalAgent?.id || agent.id;

    await db.agents.update(idToUpdate, updatedAgent);

    const updatedObject = { id: agent.id, title: agent.title };

    await db.bookings.where("agent.id").equals(idToUpdate).modify({ agent: updatedObject });

    return updatedAgent;
  }

  async updateAircraft(aircraft, originalAircraft) {
    const updatedAircraft = {
      ...aircraft,
      updatedAt: new Date(),
    };

    const idToUpdate = originalAircraft?.id || aircraft.id;

    await db.aircraft.update(idToUpdate, updatedAircraft);

    const updatedObject = { id: aircraft.id, model: aircraft.model };

    // TODO: Update bookings
    await db.segments.where("aircraft.id").equals(idToUpdate).modify({ aircraft: updatedObject });
    await db.flights.where("aircraft.id").equals(idToUpdate).modify({ aircraft: updatedObject });

    return updatedAircraft;
  }

  async updateCarrier(carrier, originalCarrier) {
    const updatedCarrier = {
      ...carrier,
      updatedAt: new Date(),
    };

    const idToUpdate = originalCarrier?.id || carrier.id;

    await db.carriers.update(idToUpdate, updatedCarrier);

    const updatedObject = { id: carrier.id, title: carrier.title, type: carrier.type };

    await db.segments.where("carrier.id").equals(idToUpdate).modify({ carrier: updatedObject });

    // TODO: Delete and Restore flights according to carrier type.

    if (carrier.type === FLIGHT) {
      await db.flights.where("carrier.id").equals(idToUpdate).modify({ carrier: updatedObject });
    } else {
      await db.flights.where("carrier.id").equals(idToUpdate).delete();
    }

    await db.bookings.where("leadingCarrier.id").equals(idToUpdate).modify({ leadingCarrier: updatedObject });

    await db.bookings
      .where("segmentCarrierIds")
      .equals(idToUpdate)
      .modify((row) => {
        row.segments = row.segments.map((segment) => {
          if (segment.carrier.id !== idToUpdate) return segment;

          return { ...segment, carrier: updatedObject };
        });

        if (originalCarrier?.id !== carrier.id) {
          row.segmentCarrierIds = row.segmentCarrierIds.map((segmentCarrierId) => {
            if (segmentCarrierId === idToUpdate) return carrier.id;
            return segmentCarrierId;
          });
        }
      });

    return updatedCarrier;
  }

  async updateStayBrand(stayBrand, originalStayBrand) {
    const newStayBrand = new StayBrand(stayBrand);
    const updatedStayBrand = {
      ...stayBrand,
      updatedAt: new Date(),
      id: newStayBrand.id,
    };

    console.log(updatedStayBrand);

    const idToUpdate = originalStayBrand.id;

    await db.stayBrands.update(idToUpdate, updatedStayBrand);

    const updatedObject = { id: updatedStayBrand.id, title: updatedStayBrand.title };

    await db.stays.where("brand.id").equals(idToUpdate).modify({ brand: updatedObject });

    await db.stayLocations.where("brand.id").equals(idToUpdate).modify({ brand: updatedObject });

    await db.bookings.where("leadingStayBrand.id").equals(idToUpdate).modify({ leadingStayBrand: updatedObject });

    await db.bookings
      .where("leadingStayBrand.id")
      .equals(idToUpdate)
      .modify((row) => {
        row.stays = row.stays.map((segment) => {
          if (segment.brand.id !== idToUpdate) return segment;

          return { ...segment, brand: updatedObject };
        });
      });

    return updatedStayBrand;
  }

  async updateStayBrandLocation(stayBrandLocation, originalStayBrandLocation) {
    const updatedStayBrandLocation = {
      ...stayBrandLocation,
      updatedAt: new Date(),
      id: originalStayBrandLocation.id,
    };

    const idToUpdate = originalStayBrandLocation.id;

    await db.stayLocations.update(idToUpdate, updatedStayBrandLocation);

    const updatedObject = {
      id: updatedStayBrandLocation.id,
      title: updatedStayBrandLocation.title || originalStayBrandLocation.title,
      timeZone: updatedStayBrandLocation.timeZone,
    };

    await db.stays.where("location.id").equals(idToUpdate).modify({ location: updatedObject });

    await db.bookings.where("leadingStayLocation.id").equals(idToUpdate).modify({ leadingStayLocation: updatedObject });

    await db.bookings
      .where("leadingStayBrand.id")
      .equals(idToUpdate)
      .modify((row) => {
        row.stays = row.stays.map((stay) => {
          if (stay.location.id !== idToUpdate) return stay;

          return { ...stay, location: updatedObject };
        });
      });

    return updatedStayBrandLocation;
  }

  async updateStation(station, originalStation) {
    const updatedStation = {
      ...station,
      updatedAt: new Date(),
    };

    const idToUpdate = originalStation?.id || station.id;

    const existingStation = await db.stations.get(idToUpdate);

    if (!existingStation) {
      return null;
    }

    await db.stations.update(idToUpdate, updatedStation);

    if (
      originalStation &&
      station.title === originalStation.title &&
      station.id === originalStation.id &&
      station.timeZone === originalStation.timeZone &&
      station.lat === originalStation.lat &&
      station.lon === originalStation.lon &&
      station.municipalityName === originalStation.municipalityName &&
      station.fullName === originalStation.fullName &&
      station.country?.code === originalStation.country?.code
    ) {
      // Not necessary to update anything else as title/id are the same
      return updatedStation;
    }

    const updatedObject = {
      id: station.id,
      title: station.title,
      timeZone: station.timeZone,
      lat: station.lat ?? undefined,
      lon: station.lon ?? undefined,
    };

    if (station.country && station.country.code) {
      updatedObject.country = { code: station.country.code };
    }

    await db.segments.where("origin.id").equals(idToUpdate).modify({ origin: updatedObject });
    await db.segments.where("destination.id").equals(idToUpdate).modify({ destination: updatedObject });

    await db.flights.where("origin.id").equals(idToUpdate).modify({ origin: updatedObject });
    await db.flights.where("destination.id").equals(idToUpdate).modify({ destination: updatedObject });

    await db.bookings
      .where("segmentStationIds")
      .equals(idToUpdate)
      .modify((row) => {
        row.segments = row.segments.map((segment) => {
          if (segment.origin.id === idToUpdate) {
            segment.origin = updatedObject;
          }

          if (segment.destination.id === idToUpdate) {
            segment.destination = updatedObject;
          }

          return segment;
        });

        if (originalStation?.id !== station.id) {
          row.segmentStationIds = getSegmentStationIds(row.segments);
        }
      });

    return updatedStation;
  }

  async deleteStation() {
    // TODO: Add delete station function
    throw new Error("Not implemented.");
  }

  async updateTrip(trip, originalTrip) {
    const updatedTrip = {
      title: originalTrip.title,
      ...trip,
      updatedAt: new Date(),
    };

    const idToUpdate = originalTrip.id;

    await db.trips.update(idToUpdate, updatedTrip);

    const updatedObject = { id: originalTrip.id, title: updatedTrip.title };

    // TODO: Modify the segment items within the booking itself
    await db.bookings.where("trip.id").equals(idToUpdate).modify({ trip: updatedObject });
    await db.segments.where("trip.id").equals(idToUpdate).modify({ trip: updatedObject });
    await db.flights.where("trip.id").equals(idToUpdate).modify({ trip: updatedObject });

    return updatedTrip;
  }

  async upsertDiscoveredData(data) {
    const { carrier, origin, destination } = data;
    console.log(carrier, origin, destination);
  }
}

export const db = new TravelDB();

// db.on("ready", function () {
//   // Will trigger once and only once.
//   console.log("READY");
//
//   db.bookings.toCollection().modify((booking) => {
//     if (!Array.isArray(booking.type)) {
//       booking.type = [booking.type];
//     }
//   });
// });
