import Card from "@mui/material/Card";
import { useLiveQuery } from "dexie-react-hooks";
import { useState, useMemo } from "react";
import { db } from "../../models/db.js";
import CarriersTable from "./CarriersTable.js";

export default function CarriersList() {
  const [sortConfig, setSortConfig] = useState({ key: "title", direction: "asc" });

  const carriers = useLiveQuery(async () => {
    const allCarriers = await db.carriers.toCollection().sortBy("id");
    return allCarriers.reduce((acc, carrier) => {
      const { type } = carrier;
      if (!acc[type]) {
        acc[type] = [];
      }
      acc[type].push(carrier);
      return acc;
    }, {});
  });

  const sortedCarriers = useMemo(() => {
    if (!carriers) return null;

    const sorted = { ...carriers };
    Object.keys(sorted).forEach((type) => {
      sorted[type].sort((a, b) => {
        if (sortConfig.key === "title" || sortConfig.key === "iata" || sortConfig.key === "id") {
          const aValue = a[sortConfig.key] || "";
          const bValue = b[sortConfig.key] || "";

          return sortConfig.direction === "asc"
            ? aValue.localeCompare(bValue, undefined, { sensitivity: "base" })
            : bValue.localeCompare(aValue, undefined, { sensitivity: "base" });
        } else {
          if (a[sortConfig.key] < b[sortConfig.key]) {
            return sortConfig.direction === "asc" ? -1 : 1;
          }
          if (a[sortConfig.key] > b[sortConfig.key]) {
            return sortConfig.direction === "asc" ? 1 : -1;
          }
          return 0;
        }
      });
    });
    return sorted;
  }, [carriers, sortConfig]);

  if (!sortedCarriers) return null;

  const handleSort = (key) => {
    let direction = sortConfig.direction;
    if (sortConfig.key === key) {
      direction = direction === "asc" ? "desc" : "asc";
    }
    setSortConfig({ key, direction });
  };

  return (
    <Card square>
      {Object.keys(carriers).map((type) => (
        <CarriersTable key={type} carriers={carriers[type]} sortConfig={sortConfig} onSort={handleSort} />
      ))}
    </Card>
  );
}
