import Tooltip from "@mui/material/Tooltip";

import IconButton from "@mui/material/IconButton";
import { Link as ReactRouterLink, useLocation } from "react-router";
import BedroomParentIcon from "@mui/icons-material/BedroomParent";

const to = "/hotel-brands";
export default function HotelBrandsButton() {
  const { pathname } = useLocation();
  const isActive = pathname === to;
  return (
    <Tooltip title={"Hotel Brands"} placement="bottom-end">
      <IconButton component={ReactRouterLink} to={to} color={isActive ? "primary" : "inherit"} size="small">
        <BedroomParentIcon />
      </IconButton>
    </Tooltip>
  );
}
