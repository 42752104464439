import FixedFooterAppBar from "../FixedFooterAppBar.js";
import MainScrollLayout from "../MainScrollLayout.js";
import SubScrollLayout from "../SubScrollLayout.js";
import NewStationButton from "./NewStationButton.js";
import StationsList from "./StationsList.js";

export default function StationsListPage() {
  return (
    <>
      <MainScrollLayout footerBar fixed flexColumn>
        <SubScrollLayout>
          <StationsList />
        </SubScrollLayout>
        <FixedFooterAppBar>
          <NewStationButton />
        </FixedFooterAppBar>
      </MainScrollLayout>
    </>
  );
}
