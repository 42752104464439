export default function TripsListInitialState() {
  return {
    trips: [],
    sortedTrips: [],
    totalNights: null,
    totalPrice: null,
    totalPricePaid: null,
    totalPricePaidForServices: null,
    totalPricePaidForStays: null,
    totalPricePaidForTravel: null,
    sortConfig: { key: "startDate", direction: "desc" },
  };
}
