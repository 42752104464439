import { useLiveQuery } from "dexie-react-hooks";
import { db } from "../../models/db.js";

import LoyaltyProgramCard from "./LoyaltyProgramCard.js";

export default function LoyaltyProgramsList() {
  const loyaltyPrograms = useLiveQuery(() => db.loyaltyPrograms.toCollection().sortBy("id"));

  if (!loyaltyPrograms) return null;

  return loyaltyPrograms.map((loyaltyProgram) => (
    <LoyaltyProgramCard key={loyaltyProgram.id} loyaltyProgram={loyaltyProgram} />
  ));
}
