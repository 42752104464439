import LocalAirportIcon from "@mui/icons-material/LocalAirport";
import TrainIcon from "@mui/icons-material/Train";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import AirportShuttleIcon from "@mui/icons-material/AirportShuttle";
export default function TransportModeSelectorButton({ editMode, isAirport, isTrainStation, isBusStation, onChange }) {
  const emitChange = (delta) => {
    onChange({
      isTrainStation,
      isAirport,
      isBusStation,
      ...delta,
    });
  };

  const onIsAirportClick = () => {
    emitChange({ isAirport: !isAirport });
  };

  const onIsTrainStationClick = () => {
    emitChange({ isTrainStation: !isTrainStation });
  };

  const onIsBusStationClick = () => {
    emitChange({ isBusStation: !isBusStation });
  };

  return (
    <ToggleButtonGroup>
      {(editMode || isAirport) && (
        <ToggleButton
          value="isAirport"
          selected={isAirport}
          onChange={onIsAirportClick}
          disabled={!editMode}
          color={editMode ? "primary" : "standard"}
          size="small"
        >
          <LocalAirportIcon />
        </ToggleButton>
      )}
      {(editMode || isTrainStation) && (
        <ToggleButton
          value="isTrainStation"
          selected={isTrainStation}
          onChange={onIsTrainStationClick}
          disabled={!editMode}
          color={editMode ? "primary" : "standard"}
          size="small"
        >
          <TrainIcon />
        </ToggleButton>
      )}
      {(editMode || isBusStation) && (
        <ToggleButton
          value="isBusStation"
          selected={isBusStation}
          onChange={onIsBusStationClick}
          disabled={!editMode}
          color={editMode ? "primary" : "standard"}
          size="small"
        >
          <AirportShuttleIcon />
        </ToggleButton>
      )}
    </ToggleButtonGroup>
  );
}
