import Tooltip from "@mui/material/Tooltip";

import IconButton from "@mui/material/IconButton";
import { Link as ReactRouterLink, useLocation } from "react-router";
import SupportAgent from "@mui/icons-material/SupportAgent";

const to = "/agents";
export default function AgentsButton() {
  const { pathname } = useLocation();
  const isActive = pathname === to;
  return (
    <Tooltip title={"Agents"} placement="bottom-end" slotProps={{ timeout: 0 }}>
      <IconButton component={ReactRouterLink} to={to} color={isActive ? "primary" : "inherit"} size="small">
        <SupportAgent />
      </IconButton>
    </Tooltip>
  );
}
