import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import Skeleton from "@mui/material/Skeleton";

import usePrivacy from "../contexts/Privacy/usePrivacy.js";

export default function FeatureCard({ value, title, variant, enablePrivacy }) {
  const isOutline = variant === "outlined";
  const { privacyModeEnabled } = usePrivacy();

  const text = (
    <Typography component="div" variant="h5" fontWeight={600}>
      {value}
    </Typography>
  );

  const render = enablePrivacy !== false && privacyModeEnabled ? <Skeleton animation={false}>{text}</Skeleton> : text;
  return (
    <Card
      sx={
        isOutline
          ? {
              borderColor: "primary.main",
            }
          : {
              backgroundColor: "primary.main",
              color: "primary.contrastText",
            }
      }
      variant={variant}
    >
      <CardContent sx={{ "&:last-child": { paddingBottom: 2 } }}>
        {render}
        <Typography variant="subtitle1" component="div" fontWeight={600}>
          {title}
        </Typography>
      </CardContent>
    </Card>
  );
}
