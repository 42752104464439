import Box from "@mui/material/Box";
import Checkbox from "@mui/material/Checkbox";
import ListItemText from "@mui/material/ListItemText";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";

import MenuItem from "@mui/material/MenuItem";
import BookingPaymentConstants, {
  UPGRADE_CLASS,
  SEAT_SELECTION,
  PRIORITY_BOARDING,
  MEAL,
  BREAKFAST,
  CHECKED_BAGGAGE,
  LATE_CHECKOUT,
  EARLY_CHECKIN,
  AIRPORT_TRANSFER,
} from "../../models/BookingPaymentConstants.js";

function getBookingPaymentConstantsLabel(value) {
  switch (value) {
    case UPGRADE_CLASS:
      return "Upgrade Class";
    case SEAT_SELECTION:
      return "Seat Selection";
    case PRIORITY_BOARDING:
      return "Priority Boarding";
    case MEAL:
      return "Meal";
    case BREAKFAST:
      return "Breakfast";
    case CHECKED_BAGGAGE:
      return "Checked Baggage";
    case LATE_CHECKOUT:
      return "Late Check-out";
    case EARLY_CHECKIN:
      return "Early Check-in";
    case AIRPORT_TRANSFER:
      return "Airport Transfer";
    default:
      return value;
  }
}

export default function BookingPaymentConstantsSelectField({ value, onChange, disabled }) {
  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    onChange(typeof value === "string" ? value.split(",") : value);
  };

  return (
    <TextField
      select
      label="Features"
      variant="outlined"
      margin="none"
      size="small"
      autoComplete="off"
      value={value || ""}
      onChange={handleChange}
      disabled={disabled}
      sx={{ minWidth: 120, maxWidth: 120, width: 120 }}
      slotProps={{
        select: {
          multiple: true,
          displayEmpty: true,
          MenuProps: {
            MenuListProps: {
              dense: true,
            },
          },
          renderValue: (selected) => {
            let value;
            if (selected.length === 0) {
              value = (
                <Typography sx={{}} variant="body2" fontStyle="italic" color="textDisabled" component="span">
                  None
                </Typography>
              );
            } else {
              value = (
                <Typography sx={{}} variant="body2" component="span">
                  {selected.map(getBookingPaymentConstantsLabel).join(", ")}
                </Typography>
              );
            }
            return value;
          },
        },
      }}
    >
      {BookingPaymentConstants.map((BookingPaymentConstant) => {
        const isSelected = value.includes(BookingPaymentConstant);

        return (
          <MenuItem
            disableTouchRipple
            dense
            key={BookingPaymentConstant}
            value={BookingPaymentConstant}
            selected={isSelected}
          >
            <Checkbox
              size="small"
              checked={isSelected}
              disableRipple
              sx={{ paddingTop: 0, paddingBottom: 0, paddingLeft: 0 }}
            />
            <ListItemText>{getBookingPaymentConstantsLabel(BookingPaymentConstant)}</ListItemText>
            {/*<Typography variant="body2" sx={{ color: "text.secondary" }}>*/}
            {/*  {BookingPaymentConstant}*/}
            {/*</Typography>*/}
          </MenuItem>
        );
      })}
    </TextField>
  );
}
