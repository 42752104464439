export function getSegmentStationTitle({
  segment,
  leadingCarrier,
  showStationIds,
  hideStationIds,
  isReturn,
  hideOrigin,
}) {
  const showIds = hideStationIds !== true && (showStationIds || (leadingCarrier && leadingCarrier.type === "FLIGHT"));

  if (segment.origin && segment.destination) {
    let destinationTitle = segment.destination.title;

    if (showIds && segment.destination.id && segment.destination.id !== segment.destination.title) {
      destinationTitle = `${segment.destination.title} (${segment.destination.id})`;
    }

    if (hideOrigin) {
      return destinationTitle;
    }

    let originTitle = segment.origin.title;

    if (showIds && segment.origin.id && segment.origin.id !== segment.origin.title) {
      originTitle = `${segment.origin.title} (${segment.origin.id})`;
    }

    if (originTitle === destinationTitle) {
      return originTitle;
    }
    return `${originTitle} ${isReturn ? "‹" : ""}› ${destinationTitle}`;
  }
  return undefined;
}

/**
 * Generate a full title
 * @param {Segment} segment - The segment
 * @param {Object} [leadingCarrier] - The leading carrier, if any
 * @param {Boolean} [showStationIds] - If the airport station codes should be displayed
 * @param {Boolean} [hideStationIds]
 * @param {Boolean} [hideCarrier]
 * @param {Boolean} [hideOrigin]
 * @param {Boolean} [isReturn]
 * @param {Boolean} [showFlightNumber]
 * @return {String} - A full title
 */
export default function getSegmentFullTitle({
  segment,
  leadingCarrier,
  showStationIds,
  hideStationIds,
  hideCarrier,
  isReturn,
  hideOrigin,
  showFlightNumber,
}) {
  const carrierTitle = segment.carrier?.title || "";

  const travelTitle = getSegmentStationTitle({
    segment,
    leadingCarrier,
    showStationIds,
    hideStationIds,
    isReturn,
    hideOrigin,
  });

  if (travelTitle) {
    if (hideCarrier !== true && carrierTitle && (!leadingCarrier || segment.carrier.id !== leadingCarrier.id))
      return `${carrierTitle}:${showFlightNumber && segment.flightNumber ? ` ${segment.flightNumber} - ` : ""} ${travelTitle}`;
    return travelTitle;
  }

  return carrierTitle;
}
