import GenericLoyaltyProgramActivityColumn from "./GenericLoyaltyProgramActivityColumn.js";

export default function GenericLoyaltyProgramActivityBookingColumn({ booking }) {
  let loyaltyProgramActivity = null;
  let stay;
  let segment;

  if (booking && booking?.stays?.length === 1) {
    stay = booking.stays[0];
    loyaltyProgramActivity = stay.loyaltyProgramActivities?.[0];
  } else if (booking && booking?.segments?.length === 1) {
    segment = booking.segments[0];
    loyaltyProgramActivity = segment.loyaltyProgramActivities?.[0];
  } else if (booking && booking?.segments?.length) {
    segment = booking.segments.find((item) => item.loyaltyProgramActivities?.[0]);
    loyaltyProgramActivity = segment?.loyaltyProgramActivities?.[0];
  }

  return (
    <GenericLoyaltyProgramActivityColumn
      loyaltyProgramActivity={loyaltyProgramActivity}
      segment={segment}
      stay={stay}
    />
  );
}
