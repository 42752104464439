import MainScrollLayout from "../MainScrollLayout.js";
import AgentsList from "./AgentsList.js";

export default function AgentsListPage() {
  return (
    <MainScrollLayout>
      <AgentsList />
    </MainScrollLayout>
  );
}
