import { Big } from "big.js";
import { isAfter, isBefore } from "date-fns";
import { determineBestEndDate, determineBestStartDate } from "../../lib/determineBestDates.js";
import isConnectedTravelSequence from "../../lib/isConnectedTravelSequence.js";
import isReturnBooking from "../../lib/isReturnBooking.js";
import isSkippedBooking from "../../lib/isSkippedBooking.js";
import now from "../../lib/now.js";
import Agent from "../../models/Agent.js";
import Aircraft from "../../models/Aircraft.js";
import Booking from "../../models/Booking.js";
import { SERVICE, STAY, TRAVEL } from "../../models/BookingConstants.js";
import BookingPayment from "../../models/BookingPayment.js";
import Carrier from "../../models/Carrier.js";
import { FLIGHT } from "../../models/CarrierConstants.js";
import LoyaltyProgramActivity from "../../models/LoyaltyProgramActivity.js";
import PaymentMethod from "../../models/PaymentMethod.js";
import Segment from "../../models/Segment.js";
import Station from "../../models/Station.js";
import Stay from "../../models/Stay.js";
import StayBrand from "../../models/StayBrand.js";
import StayLocation from "../../models/StayLocation.js";

export function isCollectionInValid(collection) {
  if (!collection?.length) return false;
  return Boolean(collection.find((segment) => segment.isValid === false));
}

export default function generateData(state, { carriers: existingSystemCarriers, aircraft: existingSystemAircraft }) {
  const createdAt = now();

  const { bookingDate, pricePaid, price, priceCurrency, cancelBeforeDate } = state;

  const result = {};

  if (state.agent && !state.agent.id) {
    // we have a new agent to save
    result.agent = new Agent({
      createdAt,
      updatedAt: createdAt,
      title: state.agent.title,
    });
  }

  const _agent = result.agent || state.agent;
  const booking = new Booking();
  booking.bookingDate = bookingDate;

  if (cancelBeforeDate) {
    booking.cancelBeforeDate = cancelBeforeDate;
  }
  booking.canBeCancelled = Boolean(cancelBeforeDate);
  booking.agent = _agent ? { id: _agent.id, title: _agent.title } : undefined;

  booking.createdAt = state.createdAt;
  booking.updatedAt = createdAt;
  booking.bookingReference = state.bookingReference || undefined;

  booking.type = [];
  booking.status = state.status;
  if (state.trip) booking.trip = { id: state.trip.id, title: state.trip.title };
  booking.id = state.id;
  booking.note = state.note || undefined;

  const stations = [];
  const carriers = [];
  const aircraft = [];
  const updatedCarriers = [];
  const stayBrands = [];
  const stayLocations = [];

  result.segments = state.segments.map((segment) => {
    let newOriginStation;
    let newDestinationStation;
    if (segment.origin && (!segment.origin.id || segment.sourcedFromAPI)) {
      const existingNewStation = stations.find((station) => station.title === segment.origin.title);
      if (existingNewStation) {
        // We're already creating the station
        newOriginStation = existingNewStation;
      } else {
        // A new origin station
        newOriginStation = new Station({
          id: segment.origin.id || segment.origin.title,
          title: segment.originTitle || segment.origin.title,
          iata: segment.origin?.iata,
          icao: segment.origin?.icao,
          fullName: segment.origin?.fullName,
          shortName: segment.origin?.shortName,
          municipalityName: segment.origin?.municipalityName,
          lat: segment.origin?.lat,
          lon: segment.origin?.lon,
          timeZone: segment.origin?.timeZone,
          country: segment.origin?.country,
          createdAt,
          updatedAt: createdAt,
          hasData: segment.sourcedFromAPI,
        });
        stations.push(newOriginStation);
      }
    }

    if (segment.destination && (!segment.destination.id || segment.sourcedFromAPI)) {
      const existingNewStation = stations.find((station) => station.title === segment.destination.title);
      if (existingNewStation) {
        // We're already creating the station
        newDestinationStation = existingNewStation;
      } else {
        // A new destination station
        newDestinationStation = new Station({
          id: segment.destination.id || segment.destination.title,
          title: segment.destinationTitle || segment.destination.title,
          iata: segment.destination?.iata,
          icao: segment.destination?.icao,
          fullName: segment.destination?.fullName,
          shortName: segment.destination?.shortName,
          municipalityName: segment.destination?.municipalityName,
          lat: segment.destination?.lat,
          lon: segment.destination?.lon,
          timeZone: segment.destination?.timeZone,
          country: segment.destination?.country,
          createdAt,
          updatedAt: createdAt,
          hasData: segment.sourcedFromAPI,
        });
        stations.push(newDestinationStation);
      }
    }

    const getAsSegmentStation = (station) => {
      return {
        id: station.id,
        title: station.title,
        timeZone: station.timeZone,
        lat: station.lat,
        lon: station.lon,
        country: station.country ? { code: station.country?.code } : undefined,
      };
    };

    const newSegment = new Segment({
      ...segment,
      booking,
      trip: booking.trip,
      origin: getAsSegmentStation(newOriginStation || segment.origin),
      destination: getAsSegmentStation(newDestinationStation || segment.destination),
      loyaltyProgramActivities: segment.loyaltyProgramActivities.map((loyaltyProgramActivity) =>
        new LoyaltyProgramActivity(loyaltyProgramActivity).toJSON(),
      ),
    });

    // New typed items: { title: "Example" }
    // Existing Selected Items: { ... the full carrier ... }
    // API Created items: { id: "ABC", icao: "ICAO", iata: "ABC", title: "Example" }

    if (segment.carrier && (!segment.carrier.id || segment.sourcedFromAPI)) {
      if (!segment.carrier.id && !segment.sourcedFromAPI) {
        // A new manually entered carrier
        // { title: "Example" }
        const newCarrier =
          carriers.find((carrier) => carrier.title === segment.carrier.title) ||
          new Carrier({
            // TODO: Use IATA for ID, but map to existing title
            // id: segment.carrier.id,
            icao: segment.carrier.icao,
            iata: segment.carrier.iata,
            title: segment.carrier.title,
            type: segment.carrier.type,
            createdAt,
            updatedAt: createdAt,
          });
        newSegment.carrier = { id: newCarrier.id, title: newCarrier.title, type: newCarrier.type };
        carriers.push(newCarrier);
      } else if (segment.sourcedFromAPI) {
        // The API has returned a partial Carrier
        // { id: "ABC", icao: "ICAO", iata: "ABC", title: "Example" }
        let matchedCarrier = existingSystemCarriers.find((carrier) => carrier.iata === segment.carrier.iata);
        if (!matchedCarrier) {
          matchedCarrier = existingSystemCarriers.find((carrier) => carrier.id === segment.carrier.title);
        }
        if (!matchedCarrier) {
          matchedCarrier = existingSystemCarriers.find((carrier) => carrier.title === segment.carrier.title);
        }
        if (matchedCarrier) {
          // Use existing carrier, and update any metadata
          newSegment.carrier = { id: matchedCarrier.id, title: matchedCarrier.title, type: matchedCarrier.type };
          updatedCarriers.push({
            id: matchedCarrier.id,
            data: {
              title: matchedCarrier.title,
              iata: segment.carrier.iata || matchedCarrier.iata,
              icao: segment.carrier.iata || matchedCarrier.icao,
              updatedAt: createdAt,
            },
          });
        } else {
          // This is a new API provided carrier, insert totally
          const newCarrier = new Carrier({
            id: segment.carrier.id,
            icao: segment.carrier.icao,
            iata: segment.carrier.iata,
            title: segment.carrier.title,
            type: segment.carrier.type,
            createdAt,
            updatedAt: createdAt,
          });
          newSegment.carrier = { id: newCarrier.id, title: newCarrier.title, type: newCarrier.type };
          carriers.push(newCarrier);
        }
      } else {
        // The existing carrier, leave as is, but condenses the object
        newSegment.carrier = { id: segment.carrier.id, title: segment.carrier.title, type: segment.carrier.type };
      }
    }

    if (segment.isLeadingCarrier && newSegment.carrier) {
      booking.leadingCarrier = {
        id: newSegment.carrier.id,
        title: newSegment.carrier.title,
        type: newSegment.carrier.type,
      };
    }

    if (segment.aircraft) {
      if (segment.aircraft.id) {
        // item has an ID
        const existingRecord =
          existingSystemAircraft.find((aircraft) => aircraft.id === segment.aircraft.id) ||
          aircraft.find((aircraft) => aircraft.id === segment.aircraft.id);
        if (!existingRecord) {
          // insert the new record
          aircraft.push(new Aircraft(segment.aircraft));
        }
      } else {
        // the item needs to be created
        const existingRecord =
          existingSystemAircraft.find((aircraft) => aircraft.model === segment.aircraft.model) ||
          aircraft.find((aircraft) => aircraft.model === segment.aircraft.model);
        if (existingRecord) {
          // assign the existing aircraft ID
          newSegment.aircraft = { id: existingRecord.id, model: existingRecord.model };
        } else {
          // this is a new aircraft model
          const interim = new Aircraft(segment.aircraft);
          newSegment.aircraft = { id: interim.id, model: interim.model };

          aircraft.push(interim);
        }
      }
    }

    return newSegment;
  });

  booking.segments = result.segments;
  if (booking.segments.length) {
    booking.type.push(TRAVEL);
    booking.startDate = determineBestStartDate(result.segments);
    booking.endDate = determineBestEndDate(result.segments);
  }

  result.stays = state.stays.map((stay) => {
    const newStay = new Stay({
      ...stay,
      booking,
      trip: booking.trip,
      loyaltyProgramActivities: stay.loyaltyProgramActivities.map((loyaltyProgramActivity) =>
        new LoyaltyProgramActivity(loyaltyProgramActivity).toJSON(),
      ),
    });

    if (stay.brand && !stay.brand.id) {
      // A new Stay Brand
      const newStayBrand =
        stayBrands.find((stayBrand) => stayBrand.title === stay.brand.title.trim()) ||
        new StayBrand({
          title: stay.brand.title.trim(),
          createdAt,
          updatedAt: createdAt,
        });
      newStay.brand = { id: newStayBrand.id, title: newStayBrand.title };
      stayBrands.push(newStayBrand);
    } else if (stay.brand && stay.brand.id) {
      newStay.brand = { id: stay.brand.id, title: stay.brand.title };
    }

    if (stay.location && !stay.location.id) {
      // A new Stay Location
      const newStayLocation =
        stayLocations.find((stayLocation) => stayLocation.title === stay.location.title.trim()) ||
        new StayLocation({
          title: stay.location.title.trim(),
          createdAt,
          updatedAt: createdAt,
          id: self.crypto.randomUUID(),
          brand: {
            id: newStay.brand.id,
            title: newStay.brand.title,
          },
        });
      newStay.location = { id: newStayLocation.id, title: newStayLocation.title, timeZone: newStayLocation.timeZone };
      stayLocations.push(newStayLocation);
    } else if (stay.location && stay.location.id) {
      newStay.location = { id: stay.location.id, title: stay.location.title, timeZone: stay.location.timeZone };
    }

    booking._nights = (booking._nights || 0) + (newStay.nights || 0);
    return newStay;
  });

  booking.stays = result.stays;

  result.paymentMethods = [];

  result.payments = state.payments.map((payment) => {
    let paymentMethod;
    if (payment.paymentMethod && !payment.paymentMethod.id) {
      // new payment method
      // Payment method already created?

      let newPaymentMethod = result.paymentMethods.find((item) => item.id === payment.paymentMethod.title);

      if (!newPaymentMethod) {
        // Payment method is new, so set it up for saving
        newPaymentMethod = new PaymentMethod({ id: payment.paymentMethod.title });
        result.paymentMethods.push(newPaymentMethod);
      }
      paymentMethod = { id: newPaymentMethod.id };
    } else if (payment.paymentMethod && payment.paymentMethod.id) {
      paymentMethod = { id: payment.paymentMethod.id };
    }

    return new BookingPayment({
      ...payment,
      paymentMethod,
    });
  });

  booking.payments = result.payments;

  booking.price = price;
  booking.priceCurrency = priceCurrency || undefined;
  booking.isPaid = state.isPaid;
  booking.isPriceEstimated = booking.priceCurrency === "GBP" ? false : state.isPriceEstimated;
  booking.pricePaid = pricePaid;

  booking.pointsPaid = undefined;
  booking.paymentMethod = undefined;

  if (booking.stays.length) {
    // TODO: Handle scenario when a booking has stays at multiple brands/locations in a single booking.
    // See also: *segmentCarrierIds
    booking.leadingStayBrand = booking.stays[0].brand;
    booking.leadingStayLocation = booking.stays[0].location;
    booking.type.push(STAY);

    booking.startDate = result.stays.reduce((acc, stay) => {
      if (!stay.checkInDate || stay.isSkipped) return acc;
      if (!acc || isBefore(stay.checkInDate, acc)) return stay.checkInDate;
      return acc;
    }, undefined);
    booking.endDate = result.stays.reduce((acc, stay) => {
      if (!stay.checkOutDate || stay.isSkipped) return acc;
      if (!acc || isAfter(stay.checkOutDate, acc)) return stay.checkOutDate;
      return acc;
    }, undefined);
  }

  if (booking._nights && booking.pricePaid) {
    booking.pricePaidPerNight = new Big(booking.pricePaid).div(booking._nights).round(2).toNumber();
  }

  if (booking._nights && booking.price) {
    booking.pricePerNight = new Big(booking.price).div(booking._nights).round(2).toNumber();
  }

  if (stations.length) {
    result.stations = stations;
  }

  if (carriers.length) {
    result.carriers = carriers;
  }

  if (updatedCarriers.length) {
    result.updatedCarriers = updatedCarriers;
  }

  if (stayBrands.length) {
    result.stayBrands = stayBrands;
  }

  if (stayLocations.length) {
    result.stayLocations = stayLocations;
  }

  if (state.segmentsToDelete?.length) {
    result.segmentsToDelete = state.segmentsToDelete;
  }

  if (state.staysToDelete?.length) {
    result.staysToDelete = state.staysToDelete;
  }

  if (!booking.type.length) {
    booking.type.push(SERVICE);
  }

  booking.setTitle(result);

  booking.isReturn = isReturnBooking(booking);
  booking.isConnectedTravelSequence = isConnectedTravelSequence(booking);
  booking.isSkipped = isSkippedBooking(booking);
  result.booking = booking;

  result.aircraft = aircraft;

  if (!result.booking.isValid) {
    console.log("NOT VALID - BOOKING");
    return null;
  }

  if (result.agent && !result.agent.isValid) {
    console.log("NOT VALID - AGENT");
    return null;
  }

  if (isCollectionInValid(result.segments)) {
    console.log("NOT VALID - SEGMENT");
    return null;
  }

  if (isCollectionInValid(result.stays)) {
    console.log("NOT VALID - STAY");
    return null;
  }

  if (isCollectionInValid(result.stations)) {
    console.log("NOT VALID - STATION");
    return null;
  }

  if (isCollectionInValid(result.carriers)) {
    console.log("NOT VALID - CARRIER");
    return null;
  }

  if (isCollectionInValid(result.stayBrands)) {
    console.log("NOT VALID - STAY BRAND");
    return null;
  }

  if (isCollectionInValid(result.stayLocations)) {
    console.log("NOT VALID - STAY LOCATION");
    return null;
  }

  if (isCollectionInValid(result.aircraft)) {
    console.log("NOT VALID - AIRCRAFT");
    return null;
  }

  result.flights = result.segments.filter((segment) => segment.carrier.type === FLIGHT);

  return result;
}
