import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableSortLabel from "@mui/material/TableSortLabel";

import StationsTableRow from "./StationsTableRow.js";

export default function StationsTable({ stations, sortConfig, onSort }) {
  const createSortHandler = (key) => () => {
    onSort(key);
  };

  return (
    <TableContainer>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell sx={{ width: "20%" }}>
              <TableSortLabel
                active={sortConfig.key === "title"}
                direction={sortConfig.direction}
                onClick={createSortHandler("title")}
              >
                Name
              </TableSortLabel>
            </TableCell>
            <TableCell sx={{ width: "15%" }}>
              <TableSortLabel
                active={sortConfig.key === "id"}
                direction={sortConfig.direction}
                onClick={createSortHandler("id")}
              >
                IATA
              </TableSortLabel>
            </TableCell>
            <TableCell sx={{ width: "15%" }}>
              <TableSortLabel
                active={sortConfig.key === "icao"}
                direction={sortConfig.direction}
                onClick={createSortHandler("icao")}
              >
                ICAO
              </TableSortLabel>
            </TableCell>
            <TableCell sx={{ width: "15%" }}>Latitude / Longitude</TableCell>
            <TableCell sx={{ width: "10%" }}>
              <TableSortLabel
                active={sortConfig.key === "timeZone"}
                direction={sortConfig.direction}
                onClick={createSortHandler("timeZone")}
              >
                Timezone
              </TableSortLabel>
            </TableCell>
            <TableCell sx={{ width: "10%" }}>
              <TableSortLabel
                active={sortConfig.key === "countryName"}
                direction={sortConfig.direction}
                onClick={createSortHandler("countryName")}
              >
                Country
              </TableSortLabel>
            </TableCell>
            <TableCell sx={{ width: "25%" }}></TableCell>
            <TableCell sx={{ width: "10%" }}>
              <TableSortLabel
                active={sortConfig.key === "lastSegmentDepartureDate"}
                direction={sortConfig.direction}
                onClick={createSortHandler("lastSegmentDepartureDate")}
              >
                Last Visit
              </TableSortLabel>
            </TableCell>
            <TableCell sx={{ width: "10%" }}>
              <TableSortLabel
                active={sortConfig.key === "totalSegments"}
                direction={sortConfig.direction}
                onClick={createSortHandler("totalSegments")}
              >
                Segments
              </TableSortLabel>
            </TableCell>

            <TableCell sx={{ width: "25%" }}></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {stations.map((station) => (
            <StationsTableRow key={station.id} station={station} />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
