import Breadcrumbs from "@mui/material/Breadcrumbs";
import Link from "@mui/material/Link";
import { useIntl } from "react-intl";

import { Link as ReactRouterLink } from "react-router";
import useCarrier from "../../contexts/Carrier/useCarrier.js";
import FeatureCard from "../FeatureCard.js";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import BookingRangeText from "../StationBookingsList/BookingRangeText.js";

export default function CarrierDetailsCard({}) {
  const { formatNumber } = useIntl();
  const { carrier } = useCarrier();
  return (
    <CardContent>
      <Grid container columnSpacing={1} rowSpacing={{ xs: 1, sm: 0 }}>
        <Grid size={{ xs: 12, md: 4 }}>
          <Card elevation={0}>
            <CardContent sx={{ paddingLeft: 0, paddingRight: 0 }}>
              <Breadcrumbs variant="h5" gutterBottom>
                <Link to={`/carriers`} component={ReactRouterLink} underline="hover" color="inherit" variant="h5">
                  Carriers
                </Link>
                <Typography component="div" variant="h5" color="text.primary">
                  {carrier?.title}
                </Typography>
              </Breadcrumbs>
              <BookingRangeText carrier={carrier} />
            </CardContent>
          </Card>
        </Grid>

        <Grid size={{ xs: 12, md: 2 }}>
          <FeatureCard title="Bookings" variant="outlined" value={formatNumber(carrier?.totalBookings || 0, {})} />
        </Grid>

        <Grid size={{ xs: 12, md: 2 }}>
          <FeatureCard title="Segments" variant="outlined" value={formatNumber(carrier?.totalSegments || 0, {})} />
        </Grid>

        <Grid size={{ xs: 12, md: 2 }}>
          <FeatureCard
            title="Total Distance"
            variant="outlined"
            value={formatNumber(carrier?.totalDistanceInKm || 0, {
              style: "unit",
              unit: "kilometer",
              maximumFractionDigits: 0,
            })}
          />
        </Grid>

        <Grid size={{ xs: 12, md: 2 }}>
          <FeatureCard
            title="Total Paid"
            value={formatNumber(carrier?.lifetimeSpend || 0, { style: "currency", currency: "GBP" })}
          />
        </Grid>
      </Grid>
    </CardContent>
  );
}
