import Joi from "joi";

export const AircraftValidator = Joi.object({
  id: Joi.string().required(),
  title: Joi.string().optional().example("787-9 Dreamliner"), // ICAO title
  model: Joi.string().required().example("Boeing 787-9"),
  modeS: Joi.string().optional().example("E80459"),
  reg: Joi.string().optional().example("CC-BGM"),
  icao: Joi.string().optional().example("B789"), // ICAO ID
  totalDistanceInKm: Joi.number().min(0).required().precision(2),
  totalSegments: Joi.number().min(0).required(),
  totalSegmentsWithDistanceInKm: Joi.number().min(0).required(),
  firstSegmentDepartureDate: Joi.date().optional(),
  lastSegmentDepartureDate: Joi.date().optional(),
}).label("Aircraft");

export default AircraftValidator;
