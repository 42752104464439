import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableSortLabel from "@mui/material/TableSortLabel";

import AircraftTableRow from "./AircraftTableRow.js";

export default function AircraftTable({ aircraft, sortConfig, onSort }) {
  const createSortHandler = (key) => () => {
    onSort(key);
  };

  return (
    <TableContainer>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell sx={{ width: "40%" }}>
              <TableSortLabel
                active={sortConfig.key === "model"}
                direction={sortConfig.direction}
                onClick={createSortHandler("model")}
              >
                Model
              </TableSortLabel>
            </TableCell>
            <TableCell sx={{ width: "40%" }}>
              <TableSortLabel
                active={sortConfig.key === "icao"}
                direction={sortConfig.direction}
                onClick={createSortHandler("icao")}
              >
                ICAO
              </TableSortLabel>
            </TableCell>
            <TableCell sx={{ width: "20%" }} align="right">
              <TableSortLabel
                active={sortConfig.key === "totalSegments"}
                direction={sortConfig.direction}
                onClick={createSortHandler("totalSegments")}
              >
                Flights
              </TableSortLabel>
            </TableCell>
            <TableCell sx={{ width: "20%" }} align="right">
              <TableSortLabel
                active={sortConfig.key === "totalDistanceInKm"}
                direction={sortConfig.direction}
                onClick={createSortHandler("totalDistanceInKm")}
              >
                Total Distance
              </TableSortLabel>
            </TableCell>

            <TableCell sx={{ width: "40%" }}></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {aircraft.map((aircraftItem) => (
            <AircraftTableRow key={aircraftItem.id} aircraft={aircraftItem} />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
