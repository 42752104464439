import { useMemo } from "react";
import useCurrency from "../../contexts/Currency/useCurrency.js";
import FixedFooterAppBar from "../FixedFooterAppBar.js";
import MainScrollLayout from "../MainScrollLayout.js";
import SubScrollLayout from "../SubScrollLayout.js";
import CurrencyList from "./CurrencyList.js";
import CurrencyListSummaryHeader from "./CurrencyListSummaryHeader.js";
import RefreshCurrencyButton from "./RefreshCurrencyButton.js";

const homeCurrency = "GBP";

export default function CurrencyListPage() {
  const currencyState = useCurrency();
  const { currency, rates, convertCurrency } = currencyState;

  const ratesToDisplay = useMemo(() => {
    if (!currency) return currency;
    return currency.map((currencyItem) => {
      return {
        ...currencyItem,
        rateToHomeCurrency: convertCurrency(1, currencyItem.id, homeCurrency),
        rateFromHomeCurrency: convertCurrency(1, homeCurrency, currencyItem.id),
      };
    });
  }, [convertCurrency, rates, currency]);

  return (
    <>
      <MainScrollLayout footerBar fixed flexColumn>
        <CurrencyListSummaryHeader />
        <SubScrollLayout>
          <CurrencyList currency={ratesToDisplay} homeCurrency={homeCurrency} />
        </SubScrollLayout>
      </MainScrollLayout>
      <FixedFooterAppBar>
        <RefreshCurrencyButton />
      </FixedFooterAppBar>
    </>
  );
}
