import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";

import GenericBookingsRow from "./GenericBookingsRow.js";

export default function GenericBookingsTable({
  bookings,
  showTrip,
  showPerNight,
  showRoomNumber,
  hideAgentLink,
  isTravel,
  isStay,
  linkState,
  isLoading,
}) {
  return (
    <TableContainer>
      <Table size="small" sx={{ tableLayout: "fixed" }}>
        <TableHead>
          <TableRow>
            <TableCell sx={{ width: 150 }}>
              <TableSortLabel active direction="desc" disabled>
                Booking Date
              </TableSortLabel>
            </TableCell>
            <TableCell sx={{ minWidth: 200, width: 450 }}>Description</TableCell>
            <TableCell sx={{ minWidth: 150, width: "auto" }}>
              {"Supplier"}
              {hideAgentLink !== true && " / Vendor"}
            </TableCell>
            <TableCell sx={{ width: 100 }}>Reference</TableCell>
            <TableCell align="right" sx={{ width: 100 }}>
              Cost
            </TableCell>
            <TableCell align="right" sx={{ width: 100 }}>
              Price Paid
            </TableCell>
            {showTrip && <TableCell sx={{ width: 150 }}>Trip</TableCell>}
            {showTrip && (
              <TableCell colSpan={3} sx={{ width: 200 }}>
                Loyalty
              </TableCell>
            )}
          </TableRow>
        </TableHead>
        <TableBody>
          {isLoading && (
            <GenericBookingsRow
              booking={undefined}
              linkState={linkState}
              hideAgentLink={hideAgentLink}
              isTravel={isTravel}
              isStay={isStay}
              showTrip={showTrip}
              showPerNight={showPerNight}
              showRoomNumber={showRoomNumber}
            />
          )}

          {bookings?.map((booking) => (
            <GenericBookingsRow
              key={booking.id}
              booking={booking}
              linkState={linkState}
              hideAgentLink={hideAgentLink}
              isTravel={isTravel}
              isStay={isStay}
              showTrip={showTrip}
              showPerNight={showPerNight}
              showRoomNumber={showRoomNumber}
            />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
