import FlightIcon from "@mui/icons-material/Flight";
import TourIcon from "@mui/icons-material/Tour";
import Tooltip from "@mui/material/Tooltip";
import { useState } from "react";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Divider from "@mui/material/Divider";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import SettingsIcon from "@mui/icons-material/Settings";
import { Link as ReactRouterLink, useLocation } from "react-router";
import AirlinesIcon from "@mui/icons-material/Airlines";
import CheckForUpdatesMenuItem from "./CheckForUpdatesMenuItem.js";
import ExportDataMenuItem from "./ExportDataMenuItem.js";
import ImportDataMenuItem from "./ImportDataMenuItem.js";
import BedroomParentIcon from "@mui/icons-material/BedroomParent";
import SupportAgent from "@mui/icons-material/SupportAgent";
import PerformServiceMenuItem from "./PerformServiceMenuItem.js";
import PersistDataMenuItem from "./PersistDataMenuItem.js";
import Loyalty from "@mui/icons-material/Loyalty";
import CurrencyExchange from "@mui/icons-material/CurrencyExchange";

export default function AuxiliaryMenuButton() {
  const [anchorEl, setAnchorEl] = useState(null);
  const accountMenuOpen = Boolean(anchorEl);

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const { pathname } = useLocation();

  return (
    <>
      <Tooltip title={"Options"} placement="bottom-end">
        <IconButton
          aria-label="account of current user"
          aria-controls="menu-appbar"
          aria-haspopup="true"
          onClick={handleMenu}
          color="inherit"
          size="small"
        >
          <SettingsIcon fontSize="small" />
        </IconButton>
      </Tooltip>
      <Menu
        id="menu-appbar"
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        keepMounted
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={accountMenuOpen}
        onClose={handleClose}
        slotProps={{
          list: {
            dense: true,
          },
          paper: {
            sx: {
              appRegion: "no-drag",
              WebkitAppRegion: "no-drag",
            },
          },
          root: {
            slotProps: {
              backdrop: {
                sx: {
                  appRegion: "no-drag",
                  WebkitAppRegion: "no-drag",
                },
                invisible: true,
              },
            },
          },
        }}
      >
        {/*<MenuItem onClick={handleClose} component={Link} to="/account">*/}
        {/*  <ListItemIcon>*/}
        {/*    <AccountCircle fontSize="small" />*/}
        {/*  </ListItemIcon>*/}
        {/*  {!account && <ListItemText>Account Options</ListItemText>}*/}
        {/*  {account && account.name && <ListItemText primary={account.name} secondary={account.email} />}*/}
        {/*  {account && !account.name && <ListItemText primary={account.email} />}*/}
        {/*</MenuItem>*/}
        {/*<Divider />*/}

        <MenuItem
          onClick={handleClose}
          component={ReactRouterLink}
          to="/agents"
          selected={pathname === "/agents"}
          disableRipple
          disableTouchRipple
        >
          <ListItemIcon>
            <SupportAgent fontSize="small" />
          </ListItemIcon>
          <ListItemText>Agents</ListItemText>
        </MenuItem>

        <MenuItem
          onClick={handleClose}
          component={ReactRouterLink}
          to="/carriers"
          selected={pathname === "/carriers"}
          disableRipple
          disableTouchRipple
        >
          <ListItemIcon>
            <AirlinesIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>Carriers</ListItemText>
        </MenuItem>

        <MenuItem
          onClick={handleClose}
          component={ReactRouterLink}
          to="/hotel-brands"
          selected={pathname === "/hotel-brands"}
          disableRipple
          disableTouchRipple
        >
          <ListItemIcon>
            <BedroomParentIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>Hotel Brands</ListItemText>
        </MenuItem>

        <MenuItem
          onClick={handleClose}
          component={ReactRouterLink}
          to="/stations"
          selected={pathname === "/stations"}
          disableRipple
          disableTouchRipple
        >
          <ListItemIcon>
            <TourIcon />
          </ListItemIcon>
          <ListItemText>Stations</ListItemText>
        </MenuItem>

        <MenuItem
          onClick={handleClose}
          component={ReactRouterLink}
          to="/aircraft"
          selected={pathname === "/aircraft"}
          disableRipple
          disableTouchRipple
        >
          <ListItemIcon>
            <FlightIcon />
          </ListItemIcon>
          <ListItemText>Aircraft</ListItemText>
        </MenuItem>

        <MenuItem
          onClick={handleClose}
          component={ReactRouterLink}
          to="/loyalty"
          selected={pathname === "/loyalty"}
          disableRipple
          disableTouchRipple
        >
          <ListItemIcon>
            <Loyalty />
          </ListItemIcon>
          <ListItemText>Loyalty</ListItemText>
        </MenuItem>
        <Divider />

        <MenuItem
          onClick={handleClose}
          component={ReactRouterLink}
          to="/currency"
          selected={pathname === "/currency"}
          disableRipple
          disableTouchRipple
        >
          <ListItemIcon>
            <CurrencyExchange />
          </ListItemIcon>
          <ListItemText>Currency</ListItemText>
        </MenuItem>

        <Divider />

        <ExportDataMenuItem onClick={handleClose} />
        <ExportDataMenuItem anonymous onClick={handleClose} />
        <ImportDataMenuItem onClick={handleClose} />
        <PersistDataMenuItem onClick={handleClose} />
        <PerformServiceMenuItem onClick={handleClose} />
        <CheckForUpdatesMenuItem onClick={handleClose} />
        {/*<MenuItem onClick={onLogoutClick}>*/}
        {/*  <ListItemIcon>*/}
        {/*    <Logout fontSize="small" />*/}
        {/*  </ListItemIcon>*/}
        {/*  <ListItemText>Logout</ListItemText>*/}
        {/*</MenuItem>*/}
      </Menu>
    </>
  );
}
