import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import InputAdornment from "@mui/material/InputAdornment";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { styled } from "@mui/material/styles";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";

import MenuItem from "@mui/material/MenuItem";
import UndoIcon from "@mui/icons-material/Undo";
const MenuItemWithIcon = styled(MenuItem)(({ theme }) => ({
  "& .MuiSvgIcon-root": {
    marginRight: theme.spacing(1),
  },
}));

const STATES = {
  UNKNOWN: { text: "Unknown", icon: CheckCircleIcon },
  BOOKED: { text: "Booked", icon: CheckCircleIcon, color: "success" },
  REFUNDED: { text: "Refunded", icon: UndoIcon },
  CANCELLED: { text: "Cancelled", icon: HighlightOffIcon, color: "error" },
};

export default function BookingStatusField({ value, onChange, disabled }) {
  const renderValue = (value) => {
    return (
      <Typography component="span" variant="body2" color={STATES[value].color}>
        {STATES[value].text}
      </Typography>
    );
  };

  const { icon: StateIcon, color } = STATES[value] || STATES.UNKNOWN;

  return (
    <TextField
      select
      label="Status"
      variant="outlined"
      margin="none"
      size="small"
      autoComplete="off"
      fullWidth
      value={value || "UNKNOWN"}
      onChange={onChange}
      disabled={disabled}
      slotProps={{
        select: {
          renderValue,
          MenuProps: {
            MenuListProps: {
              dense: true,
            },
          },
        },
        input: {
          startAdornment: (
            <InputAdornment position="start">
              <StateIcon color={color} />
            </InputAdornment>
          ),
        },
      }}
    >
      {!value && <MenuItem value="UNKNOWN">Unknown</MenuItem>}
      <MenuItemWithIcon value="BOOKED" disableRipple>
        <ListItemIcon>
          <CheckCircleIcon color="success" />
        </ListItemIcon>
        <ListItemText primaryTypographyProps={{ color: "success" }}>Booked</ListItemText>
      </MenuItemWithIcon>
      <MenuItemWithIcon value="REFUNDED" disableRipple>
        <ListItemIcon>
          <UndoIcon />
        </ListItemIcon>
        <ListItemText>Refunded</ListItemText>
      </MenuItemWithIcon>
      <MenuItemWithIcon value="CANCELLED" disableRipple>
        <ListItemIcon>
          <HighlightOffIcon color="error" />
        </ListItemIcon>
        <ListItemText primaryTypographyProps={{ color: "error" }}>Cancelled</ListItemText>
      </MenuItemWithIcon>
    </TextField>
  );
}
