import MainScrollLayout from "../MainScrollLayout.js";
import FlightsList from "./FlightsList.js";

const linkState = {
  source: "flights",
};

export default function FlightsListPage() {
  return (
    <MainScrollLayout>
      <FlightsList linkState={linkState} />
    </MainScrollLayout>
  );
}
