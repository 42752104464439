import icao_aircraft_types from "../icao_aircraft_types.js";
export function toTitleCase(value) {
  if (!value) return value;
  return value
    .split(/\s+/)
    .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
    .join(" ");
}

export default function identifyAircraftModel(model) {
  if (!model) return null;
  const parts = model.split(" ");

  const partsLength = parts.length;

  if (partsLength === 1) {
    // match direct on ID, if possible
    let possibleMatches = [];
    for (const [key, value] of Object.entries(icao_aircraft_types)) {
      possibleMatches.push(
        value
          .filter((item) => item[0] === parts[0])
          .map((value) => ({ manufacturer: key, icao: value[0], title: value[1] })),
      );
    }
    possibleMatches = possibleMatches.flat();
    const possibleMatchesCount = possibleMatches.length;
    if (possibleMatchesCount === 1) {
      // pick the default winner
      return {
        icao: possibleMatches[0].icao,
        title: possibleMatches[0].title,
        manufacturer: toTitleCase(possibleMatches[0].manufacturer),
      };
    } else if (possibleMatchesCount) {
      // filter the best match
      let bestMatch = possibleMatches.find((item) => item.title.startsWith(parts[0]));
      if (bestMatch) {
        return {
          icao: bestMatch.icao,
          title: bestMatch.title,
          manufacturer: toTitleCase(bestMatch.manufacturer),
        };
      }
      // default to the first winner anyway, better than nothing
      return {
        icao: possibleMatches[0].icao,
        title: possibleMatches[0].title,
        manufacturer: toTitleCase(possibleMatches[0].manufacturer),
      };
    }
  }

  if (partsLength !== 2 && partsLength !== 3) return null;

  const manufacturerFromString = parts[0].toLowerCase();
  let modelFromString = parts[1].toUpperCase();
  const engineTypeString = parts[2]?.toUpperCase();

  if (engineTypeString === "NEO") {
    modelFromString = `${modelFromString}neo`;
  }

  const manufacturerTypes = icao_aircraft_types[manufacturerFromString];

  if (!manufacturerTypes) return null;

  let firstMatch = manufacturerTypes.find((item) => item[1] === modelFromString);

  if (!firstMatch) {
    firstMatch = manufacturerTypes.find((item) => item[1].startsWith(modelFromString));
  }

  if (!firstMatch) return null;

  return {
    icao: firstMatch[0],
    title: firstMatch[1],
    manufacturer: toTitleCase(manufacturerFromString),
  };
}
