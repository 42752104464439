import Typography from "@mui/material/Typography";

export default function SegmentStatusLabel({ segment, inline }) {
  const sx = inline ? { paddingLeft: 1 } : { paddingRight: 1 };

  if (segment.isSkipped) {
    return (
      <Typography component="span" variant="body2" color="info.main" sx={sx}>
        Skipped
      </Typography>
    );
  }
  if (segment.isSecondaryPassenger) {
    return (
      <Typography component="span" variant="body2" color="info.main" sx={sx}>
        Secondary Passenger
      </Typography>
    );
  }
  return null;
}
