import Typography from "@mui/material/Typography";

export default function TripNoteText({ note, component }) {
  if (!note) return null;

  return (
    <Typography
      component={component || "div"}
      sx={component === "span" ? { paddingLeft: 1 } : undefined}
      variant="body2"
      color="textSecondary"
    >
      {note}
    </Typography>
  );
}
