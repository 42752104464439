import { intervalToDuration, formatDuration } from "date-fns";
import { useMemo } from "react";

/**
 * Generate a title to indicate the approximate age of a cleared award.
 * The idea is that for points that expire, it's easy to see how old an award is.
 */
export default function ClearedAgeDateLabel({ referenceDate, prefix }) {
  const now = useMemo(() => new Date(), []);

  const interval = useMemo(() => {
    if (!referenceDate) return null;
    return intervalToDuration({
      start: referenceDate,
      end: now,
    });
  }, [referenceDate, now]);

  if (!referenceDate) return prefix;
  const value = formatDuration(interval, { format: ["years", "months"] });

  return prefix ? `${prefix}: ${value}` : value;
}
