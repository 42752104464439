import { useNavigate } from "react-router";
import useSnackBar from "../../contexts/SnackBar/useSnackBar.js";
import { db } from "../../models/db.js";
import TripDetailsCard from "./TripDetailsCard.js";
import TripDetailsFooter from "./TripDetailsFooter.js";
import TripDetailsMain from "./TripDetailsMain.js";
import MainScrollLayout from "../MainScrollLayout.js";
import EditTripModal from "./EditTripModal.js";

export default function TripDetailsLayout({ state, tab, setState, dispatch }) {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackBar();

  const { trip } = state;

  const onDeleteClick = async () => {
    await db.deleteTrip(trip.id);
    navigate("/trips");
    enqueueSnackbar({ message: `${trip.title || "Trip"} Deleted`, variant: "success" });
  };

  const onEditClick = () => {
    setState({ editModalIsOpen: true });
  };

  const onEditTripModalClose = () => {
    setState({ editModalIsOpen: false });
  };

  return (
    <>
      <MainScrollLayout footerBar fixed flexColumn>
        <TripDetailsCard state={state} />
        <TripDetailsMain state={state} tab={tab} dispatch={dispatch} />
      </MainScrollLayout>
      <TripDetailsFooter id={trip.id} onDeleteClick={onDeleteClick} onEditClick={onEditClick} tab={tab} />
      <EditTripModal isOpen={state.editModalIsOpen} onClose={onEditTripModalClose} trip={trip} />
    </>
  );
}
