import Tooltip from "@mui/material/Tooltip";

import IconButton from "@mui/material/IconButton";
import { Link as ReactRouterLink, useLocation } from "react-router";
import AirlinesIcon from "@mui/icons-material/Airlines";

const to = "/carriers";
export default function CarriersButton() {
  const { pathname } = useLocation();
  const isActive = pathname === to;
  return (
    <Tooltip title={"Carriers"} placement="bottom-end">
      <IconButton component={ReactRouterLink} to={to} color={isActive ? "primary" : "inherit"} size="small">
        <AirlinesIcon />
      </IconButton>
    </Tooltip>
  );
}
