import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Checkbox from "@mui/material/Checkbox";
import Divider from "@mui/material/Divider";
import FormControlLabel from "@mui/material/FormControlLabel";
import InputAdornment from "@mui/material/InputAdornment";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Tooltip from "@mui/material/Tooltip";
import Radio from "@mui/material/Radio";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";

import { useIntl } from "react-intl";
import useCurrency from "../../contexts/Currency/useCurrency.js";
import BookingPaymentConstantsSelectField from "./BookingPaymentConstantsSelectField.js";
import BookingPaymentEditorRowActionsButton from "./BookingPaymentEditorRowActionsButton.js";
import CurrencySelectField from "./CurrencySelectField.js";
import EditorRowActionsButton from "./EditorRowActionsButton.js";
import LoyaltyProgramActivityButton from "./LoyaltyProgramActivity/LoyaltyProgramActivityButton.js";
import PaymentMethodsAutocomplete from "./PaymentMethodsAutocomplete.js";
import cleanDateValue from "./cleanDateValue.js";
import numberValidator, { integerValidator } from "./validators.js";

export default function BookingPaymentEditorRow({
  payment,
  setBookingPayment,
  referenceDate,
  endDateMaxValue,
  bookingDateMinValue,
}) {
  const { convertCurrency } = useCurrency();
  const { formatNumber } = useIntl();

  const {
    id,
    createdAt,
    updatedAt,
    paymentDate,
    paymentMethod,
    pointsPaid,
    pointsPaidAsString,
    pointsPaidIsValid,
    pricePaid,
    pricePaidAsString,
    pricePaidIsValid,
    price,
    isPaid,
    priceAsString,
    priceIsValid,
    priceCurrency,
    title,
    description,
    loyaltyProgram,
    isPriceEstimated,
    features,
  } = payment;

  const performConversionIfNeeded = (update, priceCurrency) => {
    const compareData = update || {
      priceAsString: priceAsString,
      price: price,
      priceIsValid: priceIsValid,
    };

    if (priceCurrency === "GBP") {
      // no currency to change
      return {
        pricePaidAsString: compareData.priceAsString,
        pricePaid: compareData.price,
        pricePaidIsValid: compareData.priceIsValid,
        availableConversion: null,
      };
    }

    if (isPriceEstimated && compareData.isEmpty) {
      // no value, to reset estimated field back to empty.
      return {
        pricePaidAsString: "",
        pricePaid: undefined,
        pricePaidIsValid: true,
        availableConversion: null,
      };
    }

    if (!compareData.priceIsValid || !compareData.priceAsString.length) {
      // no value to convert
      return {
        availableConversion: null,
      };
    }

    const result = convertCurrency(compareData.priceAsString, priceCurrency, "GBP");
    if (result === undefined) {
      // failed to make a conversion
      return {
        availableConversion: null,
      };
    }

    const pricePaidAsString = formatNumber(result, {
      useGrouping: false,
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });

    if (isPriceEstimated) {
      return {
        pricePaidAsString: pricePaidAsString,
        pricePaid: result,
        pricePaidIsValid: true,
        availableConversion: null,
      };
    } else {
      // We are not applying the conversion, but making it available for selection
      return {
        availableConversion: {
          pricePaidAsString: pricePaidAsString,
          pricePaid: result,
          pricePaidIsValid: true,
        },
      };
    }
  };

  const onPaymentMethodChange = (newPaymentMethod) => setBookingPayment(id, { paymentMethod: newPaymentMethod });

  const onPaidEstimatedChange = (event) => {
    setBookingPayment(id, { isPriceEstimated: event.target.checked });
  };

  const onFeaturesChange = (newValue) => {
    setBookingPayment(id, { features: newValue });
  };

  const onDeleteClick = () => {
    setBookingPayment(id, undefined, "DELETE_PAYMENT");
  };

  const onLoyaltyProgramActivityChange = (value, event) => {
    setBookingPayment(id, value, event);
  };

  const onCurrencyChange = (event) => {
    const nextUpdate = {
      priceCurrency: event.target.value,
      ...performConversionIfNeeded(undefined, event.target.value),
    };

    setBookingPayment(id, nextUpdate);
  };

  const onIsPaidChange = (event) => {
    setBookingPayment(id, { isPaid: event.target.checked });
  };

  const onPriceChange = (event) => {
    const result = numberValidator(event.target.value);

    let update = {
      priceAsString: result.valueAsString,
      price: result.value,
      priceIsValid: result.error === undefined,
    };

    setBookingPayment(id, {
      ...update,
      ...performConversionIfNeeded(
        {
          ...update,
          isEmpty: result.isEmpty,
        },
        priceCurrency,
      ),
    });
  };

  const onPricePaidChange = (event) => {
    const result = numberValidator(event.target.value);

    setBookingPayment(id, {
      pricePaidAsString: result.valueAsString,
      pricePaid: result.value,
      pricePaidIsValid: result.error === undefined,
    });
  };

  return (
    <Card key={id} elevation={1}>
      <CardContent>
        <Stack direction="row" spacing={1}>
          <DatePicker
            disableToolbar
            variant="inline"
            inputVariant="outlined"
            autoOk
            label="Payment Date"
            value={paymentDate ? dayjs.utc(paymentDate) : null}
            onChange={(newValue) => {
              setBookingPayment(id, { paymentDate: newValue?.startOf("day")?.toDate() });
            }}
            sx={{ maxWidth: 150, width: 150 }}
            minDate={dayjs.utc(bookingDateMinValue)}
            maxDate={dayjs.utc(endDateMaxValue)}
            referenceDate={dayjs.utc(referenceDate || createdAt)}
            timezone="UTC"
            showDaysOutsideCurrentMonth
            format="DD/MM/YY"
            slotProps={{
              openPickerButton: { size: "small" },
              openPickerIcon: { fontSize: "small" },
              textField: {
                variant: "outlined",
                label: "Payment Date",
                margin: "none",
              },
            }}
          />
          <TextField
            label="Title"
            value={title}
            onChange={(event) => {
              setBookingPayment(id, { title: event.target.value });
            }}
            margin="none"
            sx={{ maxWidth: 70 }}
          />

          <TextField
            label="Price"
            value={priceAsString}
            margin="none"
            error={!priceIsValid}
            onChange={onPriceChange}
            inputProps={{
              inputMode: "numeric",
            }}
            sx={{ maxWidth: 150, width: 150 }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <FormControlLabel
                    sx={{ marginRight: 0 }}
                    onChange={onIsPaidChange}
                    control={<Checkbox sx={{ padding: 1 }} color="success" checked={isPaid} size="small" />}
                    label="Paid"
                    slotProps={{
                      typography: {
                        variant: "body2",
                      },
                    }}
                  />
                </InputAdornment>
              ),
            }}
          />

          <CurrencySelectField value={priceCurrency} onChange={onCurrencyChange} />
          <PaymentMethodsAutocomplete onChange={onPaymentMethodChange} value={paymentMethod} sx={{ width: 200 }} />
          <TextField
            sx={{ width: 200 }}
            label="Price Paid (GBP)"
            value={pricePaidAsString}
            margin="none"
            disabled={priceCurrency === "GBP"}
            error={!pricePaidIsValid}
            onChange={onPricePaidChange}
            inputProps={{
              inputMode: "numeric",
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <FormControlLabel
                    sx={{ marginRight: 0 }}
                    disabled={priceCurrency === "GBP"}
                    onChange={onPaidEstimatedChange}
                    control={
                      <Checkbox
                        sx={{ padding: 1 }}
                        checked={isPriceEstimated && priceCurrency !== "GBP"}
                        size="small"
                      />
                    }
                    label="Estimate"
                    slotProps={{
                      typography: {
                        variant: "body2",
                      },
                    }}
                  />
                </InputAdornment>
              ),
            }}
          />
          <Divider orientation="vertical" variant="middle" flexItem />
          <TextField
            label="Points Paid"
            value={pointsPaidAsString}
            error={!pointsPaidIsValid}
            margin="none"
            onChange={(event) => {
              const result = integerValidator(event.target.value);

              setBookingPayment(id, {
                pointsPaidAsString: result.valueAsString,
                pointsPaid: result.value,
                pointsPaidIsValid: result.error === undefined,
              });
            }}
            inputProps={{
              inputMode: "numeric",
            }}
            sx={{ width: 100 }}
          />
          <BookingPaymentConstantsSelectField value={payment.features} onChange={onFeaturesChange} />
          <Box sx={{ alignSelf: "center" }}>
            <BookingPaymentEditorRowActionsButton onDelete={onDeleteClick} payment={payment} />
          </Box>
        </Stack>
      </CardContent>
      <Divider />
    </Card>
  );
}
