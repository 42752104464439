import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import { formatDuration, intervalToDuration, isFuture } from "date-fns";
import { useMemo } from "react";
import { useIntl } from "react-intl";

const longDateOptions = {
  year: "numeric",
  month: "long",
  day: "numeric",
};

function ClearedAgeDateLabel({ referenceDate, isFuture }) {
  const now = useMemo(() => new Date(), []);

  const interval = useMemo(() => {
    if (!referenceDate) return null;
    return intervalToDuration(
      isFuture
        ? {
            start: now,
            end: referenceDate,
          }
        : {
            start: referenceDate,
            end: now,
          },
    );
  }, [referenceDate, now]);

  if (!referenceDate) return null;

  const { years, months, weeks, days, hours, minutes } = interval;

  const options = {};

  if (years || months) {
    options.format = ["years", "months"];
  } else if (weeks || days) {
    options.format = ["weeks", "days"];
  } else if (hours || minutes) {
    options.format = ["hours", "minutes"];
  } else {
    options.format = ["days"];
  }

  return formatDuration(interval, options);
}

export function LastSegmentDepartureDateRangeText({ referenceDate }) {
  const { formatDate } = useIntl();

  if (!referenceDate) return null;

  if (isFuture(referenceDate)) {
    return (
      <Tooltip title={formatDate(referenceDate, longDateOptions)} placement="right">
        <Typography component="span" variant="body2" sx={{ userSelect: "none" }}>
          {`Next segment: `}
          <ClearedAgeDateLabel referenceDate={referenceDate} isFuture />
        </Typography>
      </Tooltip>
    );
  }

  return (
    <Tooltip title={formatDate(referenceDate, longDateOptions)} placement="right">
      <Typography component="span" variant="body2" sx={{ userSelect: "none" }}>
        {`Last segment: `}
        <ClearedAgeDateLabel referenceDate={referenceDate} />
        {" ago"}
      </Typography>
    </Tooltip>
  );
}

export function FirstSegmentDepartureDateRangeText({ referenceDate }) {
  const { formatDate } = useIntl();

  if (!referenceDate) return null;

  return (
    <Tooltip title={formatDate(referenceDate, longDateOptions)} placement="right">
      <Typography component="span" variant="body2" sx={{ userSelect: "none" }}>
        {`First segment: `}
        <ClearedAgeDateLabel referenceDate={referenceDate} />
        {" ago"}
      </Typography>
    </Tooltip>
  );
}

export default function BookingRangeText({ station, carrier, aircraft }) {
  if (!station && !carrier && !aircraft) return null;

  const { lastSegmentDepartureDate, firstSegmentDepartureDate } = [station, carrier, aircraft].find(Boolean);

  return (
    <>
      <LastSegmentDepartureDateRangeText referenceDate={lastSegmentDepartureDate} />
      <br />
      <FirstSegmentDepartureDateRangeText referenceDate={firstSegmentDepartureDate} />
    </>
  );
}
