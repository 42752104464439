import { useLiveQuery } from "dexie-react-hooks";
import { db } from "../../models/db.js";
import GenericBookingsTable from "../GenericBookingsTable/GenericBookingsTable.js";

export default function CarriersBookingsList({ carrierId, linkState }) {
  const bookings = useLiveQuery(() =>
    db.bookings.where("leadingCarrier.id").equals(carrierId).reverse().sortBy("bookingDate"),
  );

  return <GenericBookingsTable bookings={bookings} showTrip linkState={linkState} />;
}
