import { useMemo, useState } from "react";
import { useLiveQuery } from "dexie-react-hooks";
import { db } from "../../models/db.js";
import formatRegionName from "../../lib/formatRegionName.js";
import Card from "@mui/material/Card";
import StationsTable from "./StationsTable.js";

export default function StationsList() {
  const [sortConfig, setSortConfig] = useState({ key: "title", direction: "asc" });

  const stations = useLiveQuery(() => db.stations.orderBy("title").toArray());

  const sortedStations = useMemo(() => {
    if (!stations) return null;

    return stations
      .map((station) => {
        if (!station.countryName && station.country?.code) {
          station.countryName = formatRegionName(station.country?.code);
        }

        return station;
      })
      .sort((a, b) => {
        if (sortConfig.key === "title") {
          return sortConfig.direction === "asc"
            ? a.title.localeCompare(b.title, undefined, { sensitivity: "base" })
            : b.title.localeCompare(a.title, undefined, { sensitivity: "base" });
        } else if (sortConfig.key === "timeZone") {
          return sortConfig.direction === "asc"
            ? (a.timeZone || "").localeCompare(b.timeZone || "", undefined, { sensitivity: "base" })
            : (b.timeZone || "").localeCompare(a.timeZone || "", undefined, { sensitivity: "base" });
        } else if (sortConfig.key === "countryName") {
          return sortConfig.direction === "asc"
            ? (a.countryName || "").localeCompare(b.countryName || "", undefined, { sensitivity: "base" })
            : (b.countryName || "").localeCompare(a.countryName || "", undefined, { sensitivity: "base" });
        } else {
          if (a[sortConfig.key] < b[sortConfig.key]) {
            return sortConfig.direction === "asc" ? -1 : 1;
          }
          if (a[sortConfig.key] > b[sortConfig.key]) {
            return sortConfig.direction === "asc" ? 1 : -1;
          }
          return 0;
        }
      });
  }, [stations, sortConfig]);

  if (!stations) return null;

  const handleSort = (key) => {
    let direction = sortConfig.direction;
    if (sortConfig.key === key) {
      direction = direction === "asc" ? "desc" : "asc";
    }
    setSortConfig({ key, direction });
  };

  return (
    <Card square>
      <StationsTable stations={sortedStations} sortConfig={sortConfig} onSort={handleSort} />
    </Card>
  );
}
