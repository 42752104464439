import Joi from "joi";

const numbersOnlyRegex = /([^0-9.-])+/gi;
const optionalNumber = Joi.number().min(0).allow("").empty("");
const integerValidatorSchema = optionalNumber.precision(0);

const numberValidatorSchema = optionalNumber.precision(2);

const latitudeValidatorSchema = Joi.number().precision(8).min(-90).max(90);
const longitudeValidatorSchema = Joi.number().precision(8).min(-180).max(180);

export function latitudeValidator(value) {
  const valueAsString = value.replace(numbersOnlyRegex, "");
  return {
    ...latitudeValidatorSchema.validate(valueAsString),
    valueAsString,
  };
}

export function longitudeValidator(value) {
  const valueAsString = value.replace(numbersOnlyRegex, "");
  return {
    ...longitudeValidatorSchema.validate(valueAsString),
    valueAsString,
  };
}

export default function numberValidator(value) {
  const valueAsString = value.replace(numbersOnlyRegex, "");
  return {
    ...numberValidatorSchema.validate(valueAsString),
    valueAsString,
    isEmpty: valueAsString.length === 0,
  };
}

export function integerValidator(value) {
  const valueAsString = value.replace(numbersOnlyRegex, "");

  return {
    ...integerValidatorSchema.validate(valueAsString),
    valueAsString,
    isEmpty: valueAsString.length === 0,
  };
}

const latitudeLongitudeCombinedStringValidatorRegex = /^(-?\d{1,3}\.\d{1,9})[\s,]*(-?\d{1,3}\.\d{1,9})$/gi;

export function latitudeLongitudeCombinedStringValidator(value) {
  if (!value?.length) {
    return { success: false };
  }

  const result = [...value.matchAll(latitudeLongitudeCombinedStringValidatorRegex)][0];

  const firstPart = result?.[1];
  const secondPart = result?.[2];

  if (firstPart?.length && secondPart?.length) {
    return { success: true, latitude: firstPart, longitude: secondPart };
  }

  return { success: false };
}
