import { Big } from "big.js";
import haversine from "haversine-distance";

/**
 * Determine the distance in kilometers between two stations
 * @param origin
 * @param destination
 * @return {undefined|Number}
 */
export default function calculateDistance(origin, destination) {
  if (
    origin &&
    destination &&
    origin.lat !== undefined &&
    origin.lon !== undefined &&
    destination.lat !== undefined &&
    destination.lon !== undefined
  ) {
    const originLocation = { lat: origin.lat, lon: origin.lon };
    const destinationLocation = { lat: destination.lat, lon: destination.lon };

    const result = new Big(haversine(originLocation, destinationLocation)).div(1000).round(2).toNumber();
    if (result >= 0) {
      return result;
    }
  }

  return undefined;
}
