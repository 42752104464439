import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";

import StayBrandsLocationsTableRow from "./StayBrandsLocationsTableRow.js";

export default function StayBrandsLocationsTable({ stayBrandsLocations }) {
  return (
    <TableContainer>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell sx={{ width: "20%" }}>Name</TableCell>
            <TableCell sx={{ width: "20%" }}>Time Zone</TableCell>
            <TableCell sx={{ width: "40%" }} align="right">
              Total Nights
            </TableCell>
            <TableCell sx={{ width: "20%" }}></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {stayBrandsLocations.map((stayBrandsLocation) => (
            <StayBrandsLocationsTableRow key={stayBrandsLocation.id} stayBrandsLocation={stayBrandsLocation} />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
