export default async function basic({ departure, flightNumber }) {
  try {
    const response = await fetch("/api/flights/basic", {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "POST",
      body: JSON.stringify({ departure, flightNumber }, null, 0),
    });

    const contentType = response.headers.get("content-type");
    if (contentType && contentType.includes("application/json")) {
      return await response.json();
    }
  } catch (ex) {
    console.log(ex);
  }
}
