import { useLiveQuery } from "dexie-react-hooks";
import { db } from "../../models/db.js";
import GenericBookingsTable from "../GenericBookingsTable/GenericBookingsTable.js";

export default function StationBookingsList({ stationId }) {
  const bookings = useLiveQuery(() =>
    db.bookings.where("segmentStationIds").equals(stationId).reverse().sortBy("bookingDate"),
  );

  return <GenericBookingsTable isLoading={bookings === undefined} bookings={bookings} showTrip hideAgentLink />;
}
