import { differenceInCalendarDays, differenceInMinutes, intervalToDuration } from "date-fns";
import generateIdFromTitle from "../../lib/generateIdFromTitle.js";
import stripWhiteSpace from "../../lib/stripWhiteSpace.js";
import { CARRIER_TYPES } from "../../models/CarrierConstants.js";

function mapAirportToStation(airport) {
  return {
    id: airport.iata,
    iata: airport.iata,
    icao: airport.icao,
    title: airport.name,
    fullName: airport.name,
    shortName: airport.shortName,
    municipalityName: airport.municipalityName,
    lat: airport.location?.lat,
    lon: airport.location?.lon,
    country: { code: airport.countryCode },
    timeZone: airport.timeZone,
    hasData: true,
  };
}

/**
 * Take the record returned from the API, and process and align its data to the local modal.
 * @param {Object} flight - The record for a single flight returned from the API
 * @return {{}}
 */
export default function processFlightRecord({ flight }) {
  const dataToSet = {};

  const { aircraft, departure, arrival, airline, number } = flight;
  if (aircraft?.model) {
    dataToSet.aircraft = {
      // TODO: Should the ID be left blank and added on generateData
      // id: generateIdFromTitle(aircraft.model),
      model: aircraft.model,
    };
  }

  if (number) {
    dataToSet.flightNumber = stripWhiteSpace(number.toUpperCase());
  }

  if (airline) {
    dataToSet.carrier = {
      id: airline.iata,
      icao: airline.icao,
      iata: airline.iata,
      title: airline.name,
      type: CARRIER_TYPES.FLIGHT,
    };
  }

  // Require IATA on airport, as some low quality data have no airport info
  if (departure?.airport?.iata) {
    dataToSet.origin = mapAirportToStation(departure.airport);
  }

  if (departure?.terminal) {
    dataToSet.originTerminal = departure.terminal;
  }

  if (arrival?.airport?.iata) {
    dataToSet.destination = mapAirportToStation(arrival.airport);
  }

  if (arrival?.terminal) {
    dataToSet.destinationTerminal = arrival.terminal;
  }

  if (departure?.scheduledTime?.utc) {
    // TODO: Consider joining these fields
    dataToSet.departureTimeUtc = new Date(departure.scheduledTime.utc);
    dataToSet.departureDate = dataToSet.departureTimeUtc;
  }

  if (arrival?.scheduledTime?.utc) {
    dataToSet.arrivalTimeUtc = new Date(arrival.scheduledTime.utc);
  }

  if (dataToSet.arrivalTimeUtc && dataToSet.departureTimeUtc) {
    // TODO: Check this works across timezone, a 12 hour overnight flight can still happen on the same UTC day
    dataToSet.isOvernight = differenceInCalendarDays(dataToSet.arrivalTimeUtc, dataToSet.departureTimeUtc) > 0;
    dataToSet.durationInMinutes = differenceInMinutes(dataToSet.arrivalTimeUtc, dataToSet.departureTimeUtc);
    dataToSet.duration = intervalToDuration({ start: dataToSet.departureTimeUtc, end: dataToSet.arrivalTimeUtc });
  }

  return dataToSet;
}
