import { useParams, useSearchParams, useLocation } from "react-router";
import { TRAVEL, STAY, SERVICE } from "../../models/BookingConstants.js";
import BookingEditor from "./BookingEditor.js";
import { useEffect, useState } from "react";
import { db } from "../../models/db.js";

export default function BookingCreatePage() {
  const { type, tripId } = useParams();
  const [response, setResponse] = useState(null);
  const typeUpper = type?.toUpperCase();
  let [searchParams] = useSearchParams();
  const [agent, setAgent] = useState(null);
  const [carrier, setCarrier] = useState(null);

  let location = useLocation();
  const source = location.state?.source;
  const agentId = searchParams.get("agentId");
  const carrierId = searchParams.get("carrierId");

  useEffect(() => {
    async function fetchData() {
      if (!tripId) return;
      try {
        const response = await db.getTrip(tripId);
        setResponse(response);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }

    fetchData();
  }, [tripId]);

  useEffect(() => {
    async function fetchData() {
      if (!agentId) return;
      try {
        const loadedAgent = await db.agents.get(agentId);
        setAgent(loadedAgent ? { id: loadedAgent.id, title: loadedAgent.title } : null);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }

    fetchData();
  }, [agentId]);

  useEffect(() => {
    async function fetchData() {
      if (!carrierId) return;
      try {
        const loadedCarrier = await db.carriers.get(carrierId);
        setCarrier(
          loadedCarrier ? { id: loadedCarrier.id, title: loadedCarrier.title, type: loadedCarrier.type } : null,
        );
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }

    fetchData();
  }, [carrierId]);

  if (tripId && !response?.trip) return null;
  if (agentId && !agent) return null;
  if (carrierId && !carrier) return null;

  if (typeUpper === TRAVEL)
    return (
      <BookingEditor
        key={TRAVEL}
        type={TRAVEL}
        trip={response?.trip}
        bookings={response?.bookings}
        agent={agent}
        carrier={carrier}
        source={source}
      />
    );
  if (typeUpper === "HOTEL")
    return (
      <BookingEditor
        key={STAY}
        type={STAY}
        trip={response?.trip}
        bookings={response?.bookings}
        agent={agent}
        carrier={carrier}
        source={source}
      />
    );
  if (typeUpper === "SERVICE")
    return (
      <BookingEditor
        key={SERVICE}
        type={SERVICE}
        trip={response?.trip}
        bookings={response?.bookings}
        agent={agent}
        carrier={carrier}
        source={source}
      />
    );
  return null;
}
