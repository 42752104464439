import LoyaltyOutlined from "@mui/icons-material/LoyaltyOutlined";
import Loyalty from "@mui/icons-material/Loyalty";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import { useState } from "react";
import LoyaltyProgramActivityModal from "./LoyaltyProgramActivityModal.js";

export default function LoyaltyProgramActivityButton({
  onChange,
  loyaltyProgramActivities,
  title,
  referenceDate,
  minDate,
}) {
  const [isOpen, setIsOpen] = useState(false);

  const hasActivities = loyaltyProgramActivities?.length > 0;
  const onClick = () => {
    setIsOpen(!isOpen);
  };

  const onClose = () => {
    setIsOpen(false);
  };

  const set = (value, type) => {
    onChange(value, type);
  };

  return (
    <>
      <Tooltip
        title={hasActivities ? "Loyalty Program Activity" : "Add Loyalty Program Activity"}
        arrow
        placement="bottom-start"
      >
        <IconButton size="small" color={hasActivities ? "primary" : "inherit"} onClick={onClick}>
          {hasActivities ? <Loyalty fontSize="small" /> : <LoyaltyOutlined fontSize="small" />}
        </IconButton>
      </Tooltip>
      <LoyaltyProgramActivityModal
        set={set}
        isOpen={isOpen}
        onClose={onClose}
        title={title}
        loyaltyProgramActivities={loyaltyProgramActivities}
        referenceDate={referenceDate}
        minDate={minDate}
      />
    </>
  );
}
