import AddIcon from "@mui/icons-material/Add";
import Button from "@mui/material/Button";
import { useState } from "react";
import NewLoyaltyProgramModal from "./NewLoyaltyProgramModal.js";

export default function NewLoyaltyProgramButton() {
  const [isOpen, setIsOpen] = useState(false);

  const onClick = () => {
    setIsOpen(!isOpen);
  };

  const onClose = () => {
    setIsOpen(false);
  };

  return (
    <>
      <Button startIcon={<AddIcon />} color="secondary" variant="outlined" onClick={onClick}>
        New Program
      </Button>
      <NewLoyaltyProgramModal isOpen={isOpen} onClose={onClose} />
    </>
  );
}
