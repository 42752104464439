import { useLiveQuery } from "dexie-react-hooks";
import { db } from "../../models/db.js";
import StayBrandsLocationsTable from "./StayBrandsLocationsTable.js";

export default function StayBrandsLocationList({ stayBrandId }) {
  const stayBrandsLocations = useLiveQuery(() =>
    db.stayLocations.where("brand.id").equals(stayBrandId).sortBy("title"),
  );

  if (!stayBrandsLocations) return null;

  return <StayBrandsLocationsTable stayBrandsLocations={stayBrandsLocations} />;
}
