import Divider from "@mui/material/Divider";

import AircraftProvider from "../../contexts/Aircraft/AircraftProvider.js";
import FlightsList from "../FlightsList/FlightsList.js";
import MainScrollLayout from "../MainScrollLayout.js";
import SubScrollLayout from "../SubScrollLayout.js";
import AircraftDetailsCard from "./AircraftDetailsCard.js";
import { useParams } from "react-router";

export default function AircraftSegmentsListPage() {
  const { aircraftId } = useParams();
  const linkState = { source: "aircraft", sourceId: aircraftId };

  return (
    <>
      <AircraftProvider id={aircraftId}>
        <MainScrollLayout fixed flexColumn>
          <AircraftDetailsCard />
          <Divider />
          <SubScrollLayout>
            <FlightsList aircraftId={aircraftId} linkState={linkState} />
          </SubScrollLayout>
        </MainScrollLayout>
      </AircraftProvider>
    </>
  );
}
