import Link from "@mui/material/Link";
import Typography from "@mui/material/Typography";

import { Link as ReactRouterLink } from "react-router";

export default function SegmentAircraftLabel({ segment, inline }) {
  const sx = inline ? { paddingLeft: 1, whiteSpace: "nowrap" } : { paddingRight: 1, whiteSpace: "nowrap" };

  if (segment.aircraft?.id) {
    return (
      <Link
        to={`/aircraft/${segment.aircraft.id}`}
        component={ReactRouterLink}
        variant="body2"
        color="secondary.main"
        sx={sx}
        underline="hover"
      >
        {segment.aircraft.model}
      </Link>
    );
  }

  if (segment.aircraft?.model) {
    return (
      <Typography component="span" variant="body2" color="secondary.main" sx={sx}>
        {segment.aircraft?.model}
      </Typography>
    );
  }

  return null;
}
