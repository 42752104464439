import { useLiveQuery } from "dexie-react-hooks";
import { db } from "../../models/db.js";
import { shouldSegmentBeIncluded } from "../../utils/shouldSegmentBeIgnored.js";
import GenericSegmentsTable from "./GenericSegmentsTable.js";

export default function FlightsList({
  carrierId,
  linkState,
  loyaltyProgramId,
  showOnlySource,
  aircraftId,
  showOnlyTierPoints,
}) {
  const segments = useLiveQuery(async () => {
    if (loyaltyProgramId) {
      return (
        await db.segments.where("loyaltyProgramIds").equals(loyaltyProgramId).reverse().sortBy("departureDate")
      ).filter((segment) => {
        if (showOnlyTierPoints) {
          if (
            segment.loyaltyProgramActivities.find(
              (loyaltyProgramActivity) =>
                loyaltyProgramActivity.loyaltyProgram.id === loyaltyProgramId && loyaltyProgramActivity.tierPoints > 0,
            )
          ) {
            return true;
          }
          return false;
        } else {
          return true;
        }
      });
    } else if (carrierId) {
      return db.flights.where("carrier.id").equals(carrierId).reverse().sortBy("departureDate");
    } else if (aircraftId) {
      return (await db.flights.where("aircraft.id").equals(aircraftId).reverse().sortBy("departureDate")).filter(
        shouldSegmentBeIncluded,
      );
    } else {
      return db.flights.reverse().sortBy("departureDate");
    }
  }, [carrierId, loyaltyProgramId, showOnlyTierPoints]);

  return (
    <GenericSegmentsTable
      segments={segments}
      isLoading={segments === undefined}
      linkState={linkState}
      showOnlySource={showOnlySource}
    />
  );
}
