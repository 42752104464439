import { useMemo } from "react";
import { styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import Divider from "@mui/material/Divider";

import DialogActions from "@mui/material/DialogActions";
import DialogContentText from "@mui/material/DialogContentText";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";

const BorderedDialogPaper = styled(Paper)(({ theme }) => ({
  borderSize: 1,
  borderStyle: "solid",
  borderColor: theme.palette.error.dark,
}));

export default function ConfirmationModal({
  isOpen,
  title,
  text,
  cancelButtonLabel = "Cancel",
  submitButtonLabel = "Continue",
  onSubmit,
  onClose,
  color,
}) {
  return useMemo(
    () => (
      <Dialog open={isOpen} onClose={onClose} PaperComponent={color === "error" ? BorderedDialogPaper : undefined}>
        {title && <DialogTitle>{title}</DialogTitle>}
        <Divider color={color} />
        <DialogContent>
          <DialogContentText>{text}</DialogContentText>
        </DialogContent>
        <Divider color={color} />
        <DialogActions>
          <Button onClick={onClose} variant="outlined" color="secondary">
            {cancelButtonLabel}
          </Button>
          <Button onClick={onSubmit} color={color || "primary"} variant="contained">
            {submitButtonLabel || "Continue"}
          </Button>
        </DialogActions>
      </Dialog>
    ),
    [isOpen, title, text, cancelButtonLabel, submitButtonLabel, onSubmit, onClose, color],
  );
}
