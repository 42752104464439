/**
 * Determine if all the elements with a booking have been skipped, thus, the entire booking is marked as skipped
 * @param {Object} booking
 * @return {boolean} - If ALL the elements within this booking have been skipped.
 */
export default function isSkippedBooking(booking) {
  if (!booking.segments?.length && !booking.stays?.length) return false;

  const hasUnSkippedSegments = Boolean(booking.segments?.find((segment) => segment.isSkipped !== true) || false);
  const hasUnSkippedStays = Boolean(booking.stays?.find((segment) => segment.isSkipped !== true) || false);
  if (hasUnSkippedSegments || hasUnSkippedStays) {
    return false;
  }

  return true;
}
