import Link from "@mui/material/Link";
import { useLiveQuery } from "dexie-react-hooks";
import { useIntl } from "react-intl";

import { Link as ReactRouterLink } from "react-router";
import { db } from "../../models/db.js";
import CopyToClipboardText from "../CopyToClipboardText.js";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import VirginRedSpecificInfo from "./VirginRedSpecificInfo.js";

export function LoyaltyProgramDetailsCardQuery({ loyaltyProgramId }) {
  const loyaltyProgram = useLiveQuery(() => db.loyaltyPrograms.get(loyaltyProgramId));

  return <LoyaltyProgramDetailsCard loyaltyProgram={loyaltyProgram} />;
}

export default function LoyaltyProgramDetailsCard({ loyaltyProgram }) {
  const { formatNumber } = useIntl();

  return (
    <CardContent>
      <Grid container spacing={1}>
        <Grid size={{ xs: 6 }}>
          <Card elevation={0}>
            <CardContent sx={{ paddingLeft: 0, paddingRight: 0 }}>
              <Breadcrumbs variant="h5" gutterBottom>
                <Link to={`/loyalty`} component={ReactRouterLink} underline="hover" color="inherit" variant="h5">
                  Loyalty
                </Link>
                <Typography component="div" variant="h5" color="text.primary">
                  {loyaltyProgram?.title}
                </Typography>
              </Breadcrumbs>
              <Typography component="div" variant="body2" color="textSecondary">
                {loyaltyProgram?.programId?.length ? (
                  <CopyToClipboardText sx={{ color: "inherit" }} value={loyaltyProgram?.programId} />
                ) : (
                  ""
                )}
              </Typography>
              {loyaltyProgram?.note && (
                <Typography component="div" variant="body2" color="textSecondary">
                  {loyaltyProgram?.note}
                </Typography>
              )}
              <VirginRedSpecificInfo loyaltyProgram={loyaltyProgram} />
            </CardContent>
          </Card>
        </Grid>
        <Grid size={{ xs: 2 }}>
          {/*<FeatureCard title="Total Nights" value={formatNumber(trip.totalNights || 0)} variant="outlined" />*/}
        </Grid>

        <Grid size={{ xs: 2 }}>
          {/*<FeatureCard*/}
          {/*  title="Payments Due"*/}
          {/*  value={formatNumber(trip.totalPricePaidUnpaid, { style: "currency", currency: "GBP" })}*/}
          {/*  variant="outlined"*/}
          {/*/>*/}
        </Grid>

        <Grid size={{ xs: 2 }}>
          {/*<FeatureCard*/}
          {/*  title="Total Paid"*/}
          {/*  value={formatNumber(carrier?.lifetimeSpend || 0, { style: "currency", currency: "GBP" })}*/}
          {/*/>*/}
        </Grid>
      </Grid>
    </CardContent>
  );
}
