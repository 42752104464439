import Breadcrumbs from "@mui/material/Breadcrumbs";
import Link from "@mui/material/Link";
import { useIntl } from "react-intl";

import { Link as ReactRouterLink } from "react-router";
import useAircraft from "../../contexts/Aircraft/useAircraft.js";
import FeatureCard from "../FeatureCard.js";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import BookingRangeText from "../StationBookingsList/BookingRangeText.js";

export default function AircraftDetailsCard({}) {
  const { formatNumber } = useIntl();
  const { aircraft } = useAircraft();
  return (
    <CardContent>
      <Grid container columnSpacing={1} rowSpacing={{ xs: 1, sm: 0 }}>
        <Grid size={{ xs: 12, md: 8 }}>
          <Card elevation={0}>
            <CardContent sx={{ paddingLeft: 0, paddingRight: 0 }}>
              <Breadcrumbs variant="h5" gutterBottom>
                <Link to={`/aircraft`} component={ReactRouterLink} underline="hover" color="inherit" variant="h5">
                  Aircraft
                </Link>
                <Typography component="div" variant="h5" color="text.primary">
                  {[
                    aircraft?.manufacturer,
                    aircraft?.title || aircraft?.model,
                    aircraft?.icao ? `(${aircraft.icao})` : null,
                  ]
                    .filter(Boolean)
                    .join(" ")}
                </Typography>
              </Breadcrumbs>
              <BookingRangeText aircraft={aircraft} />
            </CardContent>
          </Card>
        </Grid>

        <Grid size={{ xs: 12, md: 2 }}>
          <FeatureCard title="Flights" variant="outlined" value={formatNumber(aircraft?.totalSegments || 0, {})} />
        </Grid>

        <Grid size={{ xs: 12, md: 2 }}>
          <FeatureCard
            title="Total Distance"
            variant="outlined"
            value={formatNumber(aircraft?.totalDistanceInKm || 0, {
              style: "unit",
              unit: "kilometer",
              maximumFractionDigits: 0,
            })}
          />
        </Grid>
      </Grid>
    </CardContent>
  );
}
