import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import Button from "@mui/material/Button";

import useDialog from "../../contexts/Dialog/useDialog.js";

export default function DeleteBookingButton({ onClick, disabled }) {
  const confirmAction = useDialog();

  const onDeleteClick = async () => {
    const proceed = await confirmAction({
      title: `Delete Booking`,
      text: "Are you sure you want to continue? ",
      submitButtonLabel: "Delete Booking",
      color: "error",
    });

    if (!proceed) return;

    onClick();
  };

  return (
    <Button
      startIcon={<DeleteForeverIcon fontSize="small" />}
      color="error"
      variant="outlined"
      onClick={onDeleteClick}
      disabled={disabled}
    >
      Delete
    </Button>
  );
}
