import { useLiveQuery } from "dexie-react-hooks";
import { db } from "../../models/db.js";
import GenericSegmentsTable from "./GenericSegmentsTable.js";

export default function SegmentsList({ carrierId, linkState, loyaltyProgramId, showOnlySource, stationId }) {
  const segments = useLiveQuery(() => {
    if (loyaltyProgramId) {
      return db.segments.where("loyaltyProgramIds").equals(loyaltyProgramId).reverse().sortBy("departureDate");
    } else if (carrierId) {
      return db.segments.where("carrier.id").equals(carrierId).reverse().sortBy("departureDate");
    } else if (stationId) {
      return db.segments
        .where("origin.id")
        .equals(stationId)
        .or("destination.id")
        .equals(stationId)
        .reverse()
        .sortBy("departureDate");
    } else {
      return db.segments.reverse().sortBy("departureDate");
    }
  }, [carrierId, loyaltyProgramId]);

  return (
    <GenericSegmentsTable
      segments={segments}
      isLoading={segments === undefined}
      linkState={linkState}
      showOnlySource={showOnlySource}
    />
  );
}
