import MainScrollLayout from "../MainScrollLayout.js";
import AircraftList from "./AircraftList.js";

export default function AircraftListPage() {
  return (
    <MainScrollLayout>
      <AircraftList />
    </MainScrollLayout>
  );
}
