import TableCell from "@mui/material/TableCell";

import { useIntl } from "react-intl";
import LoyaltyProgramActivityNameLink from "./LoyaltyProgramActivityNameLink.js";
import LoyaltyProgramActivityStatusIcon from "./LoyaltyProgramActivityStatusIcon.js";

function PointsDisplayText({ points, tierPoints }) {
  const { formatNumber } = useIntl();

  const pointsText = points !== undefined ? formatNumber(points) : "-";

  if (tierPoints === undefined) {
    return pointsText;
  }

  const tierPointsText = formatNumber(tierPoints);

  if (tierPointsText === pointsText) {
    return pointsText;
  }
  return `${pointsText} / ${tierPointsText}`;
}

export default function GenericLoyaltyProgramActivityColumn({
  loyaltyProgramActivity,
  showSource,
  showOnlySource,
  segment,
  stay,
}) {
  if (!loyaltyProgramActivity) {
    return <TableCell colSpan={3} />;
  }

  return (
    <>
      <TableCell
        sx={{
          userSelect: "none",
          overflow: "hidden",
          textOverflow: "ellipsis",
          whiteSpace: "nowrap",
        }}
      >
        <LoyaltyProgramActivityNameLink
          loyaltyProgramActivity={loyaltyProgramActivity}
          showSource={showSource}
          showOnlySource={showOnlySource}
        />
      </TableCell>

      <TableCell align="right" padding="none" sx={{ whiteSpace: "nowrap", padding: 0, userSelect: "none" }}>
        <PointsDisplayText points={loyaltyProgramActivity.points} tierPoints={loyaltyProgramActivity.tierPoints} />
      </TableCell>

      <TableCell align="right" padding="checkbox" sx={{ width: 20 }}>
        <LoyaltyProgramActivityStatusIcon
          loyaltyProgramActivity={loyaltyProgramActivity}
          segment={segment}
          stay={stay}
        />
      </TableCell>
    </>
  );
}
