import { useState } from "react";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import MoreVertIcon from "@mui/icons-material/MoreVert";

export default function BookingPaymentEditorRowActionsButton({ onDelete, payment }) {
  const [anchorEl, setAnchorEl] = useState(null);
  const accountMenuOpen = Boolean(anchorEl);

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const onDeleteClick = () => {
    if (payment.isPrinciple) return;
    handleClose();
    onDelete();
  };

  return (
    <>
      <IconButton onClick={handleMenu} color="inherit" size="small">
        <MoreVertIcon fontSize="small" />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        keepMounted
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={accountMenuOpen}
        onClose={handleClose}
        MenuListProps={{
          dense: true,
        }}
      >
        <MenuItem disableTouchRipple onClick={onDeleteClick} disabled={payment.isPrinciple}>
          <ListItemIcon>
            <DeleteForeverIcon fontSize="small" color="error" />
          </ListItemIcon>
          <ListItemText primaryTypographyProps={{ color: "error" }} primary="Delete" />
        </MenuItem>
      </Menu>
    </>
  );
}
