import ConfirmationNumberIcon from "@mui/icons-material/ConfirmationNumber";
import ConnectingAirportsIcon from "@mui/icons-material/ConnectingAirports";
import FlightIcon from "@mui/icons-material/Flight";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import Skeleton from "@mui/material/Skeleton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";

import { Link as ReactRouterLink } from "react-router";
import { FLIGHT } from "../../models/CarrierConstants.js";
import { ToggleButtonCompact, ToggleButtonIcon } from "../controls/ToggleButton.js";
import FixedFooterAppBar from "../FixedFooterAppBar.js";

export default function StationFixedFooterAppBar({ linkState, stationId, tabSafe }) {
  return (
    <FixedFooterAppBar>
      <Divider orientation="vertical" variant="middle" flexItem />
      <ToggleButtonGroup exclusive value={tabSafe}>
        <ToggleButtonCompact
          color="primary"
          size="small"
          value="segments"
          component={ReactRouterLink}
          to={`/stations/${stationId}`}
        >
          <ToggleButtonIcon>
            <ConnectingAirportsIcon />
          </ToggleButtonIcon>
          Segments
        </ToggleButtonCompact>

        <ToggleButtonCompact
          color="primary"
          size="small"
          value="bookings"
          component={ReactRouterLink}
          to={`/stations/${stationId}/bookings`}
        >
          <ToggleButtonIcon>
            <ConfirmationNumberIcon />
          </ToggleButtonIcon>
          Bookings
        </ToggleButtonCompact>
      </ToggleButtonGroup>
    </FixedFooterAppBar>
  );
}
