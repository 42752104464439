import FixedFooterAppBar from "../FixedFooterAppBar.js";
import MainScrollLayout from "../MainScrollLayout.js";
import SubScrollLayout from "../SubScrollLayout.js";
import LoyaltyProgramsList from "./LoyaltyProgramsList.js";
import NewLoyaltyProgramButton from "./NewLoyaltyProgramButton.js";

export default function LoyaltyProgramsListPage() {
  return (
    <>
      <MainScrollLayout footerBar fixed flexColumn>
        <SubScrollLayout>
          <LoyaltyProgramsList />
        </SubScrollLayout>
      </MainScrollLayout>
      <FixedFooterAppBar>
        <NewLoyaltyProgramButton />
      </FixedFooterAppBar>
    </>
  );
}
