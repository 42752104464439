import useCarriers from "../../contexts/Carriers/useCarriers.js";
import CreatableAutocomplete from "./CreatableAutocomplete.js";

export default function CarriersAutocomplete({ value, onChange, label, disabled }) {
  const { carriers, isLoaded } = useCarriers();

  return (
    <CreatableAutocomplete
      value={value}
      onChange={onChange}
      options={carriers}
      label={label || "Carrier"}
      clearOnBlur
      sx={{ width: 300 }}
      required
      loading={!isLoaded}
      disabled={disabled}
    />
  );
}
