import BookingEditorReducerValidator from "./BookingEditorReducerValidator.js";

export default function BookingEditorReducerGeneral(state) {
  const { isValid, isValidErrorMessages } = BookingEditorReducerValidator(state);
  state.isValid = isValid;
  state.isValidErrorMessages = isValidErrorMessages;
  state.canAddSegments = true; // state.type === TRAVEL;
  state.canAddStays = true; // state.type === STAY;
  return state;
}
