import { useMemo, useState } from "react";
import { useLiveQuery } from "dexie-react-hooks";
import { db } from "../../models/db.js";
import Card from "@mui/material/Card";
import StayBrandsTable from "./StayBrandsTable.js";

export default function StayBrandsList() {
  const [sortConfig, setSortConfig] = useState({ key: "title", direction: "asc" });

  const stayBrands = useLiveQuery(() => db.stayBrands.toCollection().sortBy("id"));

  const sortedStayBrands = useMemo(() => {
    if (!stayBrands) return null;

    return stayBrands.sort((a, b) => {
      if (sortConfig.key === "title") {
        return sortConfig.direction === "asc"
          ? a.title.localeCompare(b.title, undefined, { sensitivity: "base" })
          : b.title.localeCompare(a.title, undefined, { sensitivity: "base" });
      } else {
        if (a[sortConfig.key] < b[sortConfig.key]) {
          return sortConfig.direction === "asc" ? -1 : 1;
        }
        if (a[sortConfig.key] > b[sortConfig.key]) {
          return sortConfig.direction === "asc" ? 1 : -1;
        }
        return 0;
      }
    });
  }, [stayBrands, sortConfig]);

  if (!sortedStayBrands) return null;

  const handleSort = (key) => {
    let direction = sortConfig.direction;
    if (sortConfig.key === key) {
      direction = direction === "asc" ? "desc" : "asc";
    }
    setSortConfig({ key, direction });
  };

  return (
    <Card square>
      <StayBrandsTable stayBrands={sortedStayBrands} sortConfig={sortConfig} onSort={handleSort} />
    </Card>
  );
}
