import Typography from "@mui/material/Typography";
import { useMemo } from "react";
import { subMonths } from "date-fns/subMonths";
import { addMonths } from "date-fns/addMonths";
import { subMilliseconds } from "date-fns/subMilliseconds";

import { startOfMonth } from "date-fns/startOfMonth";
import { useIntl } from "react-intl";
import isVirginRed from "./isVirginRed.js";

const longDateOptions = {
  year: "numeric",
  month: "long",
  day: "numeric",
  timeZone: "Europe/London",
};

export default function VirginRedSpecificInfo({ loyaltyProgram }) {
  const now = useMemo(() => new Date(), []);
  const { formatDate } = useIntl();

  if (!isVirginRed(loyaltyProgram)) return null;

  const redStartDate = startOfMonth(subMonths(now, 12));

  const nextMonthStartDate = startOfMonth(addMonths(now, 1));
  const nextMonthBackDate = startOfMonth(subMonths(nextMonthStartDate, 12));
  const nextMonthExpireAtDate = subMilliseconds(nextMonthStartDate, 1);

  const secondNextMonthStartDate = startOfMonth(addMonths(now, 2));
  const secondNextMonthBackDate = startOfMonth(subMonths(secondNextMonthStartDate, 12));
  const secondNextMonthExpireAtDate = subMilliseconds(secondNextMonthStartDate, 1);

  return (
    <Typography component="div" variant="body2" color="textSecondary">
      {`Red Member Upgrade Cycle Starts: ${formatDate(redStartDate, longDateOptions)}`}
      <br />
      {`Red Member Next Points Expire Due: ${formatDate(nextMonthExpireAtDate, longDateOptions)} (tier points awarded before ${formatDate(nextMonthBackDate, longDateOptions)} will expire)`}
      <br />
      {`Red Member Second Next Points Expire Due: ${formatDate(secondNextMonthExpireAtDate, longDateOptions)} (tier points awarded before ${formatDate(secondNextMonthBackDate, longDateOptions)} will expire)`}
      <br />
    </Typography>
  );
}
