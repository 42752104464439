import FlightIcon from "@mui/icons-material/Flight";
import HotelIcon from "@mui/icons-material/Hotel";
import Button from "@mui/material/Button";

import FixedFooterAppBar from "../FixedFooterAppBar.js";
import MainScrollLayout from "../MainScrollLayout.js";
import SubScrollLayout from "../SubScrollLayout.js";
import AgentBookingsList from "./AgentBookingsList.js";
import { Link as ReactRouterLink, useParams } from "react-router";
import { AgentDetailsCardQuery } from "./AgentDetailsCard.js";

export default function AgentBookingsListPage() {
  const { agentId } = useParams();
  const linkState = { source: "agent" };
  return (
    <>
      <MainScrollLayout footerBar fixed flexColumn>
        <AgentDetailsCardQuery agentId={agentId} />
        <SubScrollLayout>
          <AgentBookingsList agentId={agentId} linkState={linkState} />
        </SubScrollLayout>
      </MainScrollLayout>
      <FixedFooterAppBar>
        <Button
          size="small"
          startIcon={<FlightIcon fontSize="small" />}
          color="secondary"
          variant="outlined"
          component={ReactRouterLink}
          state={linkState}
          to={`/bookings/new/travel?agentId=${agentId}`}
        >
          Add Travel
        </Button>
        <Button
          size="small"
          startIcon={<HotelIcon fontSize="small" />}
          color="secondary"
          variant="outlined"
          component={ReactRouterLink}
          state={linkState}
          to={`/bookings/new/hotel?agentId=${agentId}`}
        >
          Add Hotel
        </Button>
      </FixedFooterAppBar>
    </>
  );
}
