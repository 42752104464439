import { createContext } from "react";

const CurrencyContext = createContext({
  currency: [],
  loadCurrencyRateData: async () => {},
  convertCurrency: () => {},
  dataLoaded: null,
});

export default CurrencyContext;
