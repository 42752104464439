import { TableFooter } from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableSortLabel from "@mui/material/TableSortLabel";
import { Big } from "big.js";

import { useIntl } from "react-intl";
import CarrierRow from "./CarrierRow.js";

export default function CarriersTable({ carriers, sortConfig, onSort }) {
  const { formatNumber } = useIntl();

  const createSortHandler = (key) => () => {
    onSort(key);
  };

  const stats = {
    totalSegments: 0,
    totalDistanceInKm: new Big(0.0),
    lifetimeSpend: new Big(0.0),
  };

  return (
    <TableContainer>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell sx={{ width: "20%" }}>
              <TableSortLabel
                active={sortConfig.key === "title"}
                direction={sortConfig.direction}
                onClick={createSortHandler("title")}
              >
                Name
              </TableSortLabel>
            </TableCell>
            <TableCell sx={{ width: "20%" }}>
              <TableSortLabel
                active={sortConfig.key === "id"}
                direction={sortConfig.direction}
                onClick={createSortHandler("id")}
              >
                ID
              </TableSortLabel>
            </TableCell>
            <TableCell sx={{ width: "20%" }}>
              <TableSortLabel
                active={sortConfig.key === "iata"}
                direction={sortConfig.direction}
                onClick={createSortHandler("iata")}
              >
                IATA
              </TableSortLabel>
            </TableCell>
            <TableCell sx={{ width: "20%" }}>Type</TableCell>
            <TableCell sx={{ width: "20%" }}>
              <TableSortLabel
                active={sortConfig.key === "lastSegmentDepartureDate"}
                direction={sortConfig.direction}
                onClick={createSortHandler("lastSegmentDepartureDate")}
              >
                Last Segment
              </TableSortLabel>
            </TableCell>
            <TableCell sx={{ width: "20%" }} align="right">
              <TableSortLabel
                active={sortConfig.key === "totalSegments"}
                direction={sortConfig.direction}
                onClick={createSortHandler("totalSegments")}
              >
                Segments
              </TableSortLabel>
            </TableCell>
            <TableCell sx={{ width: "20%" }} align="right">
              <TableSortLabel
                active={sortConfig.key === "totalDistanceInKm"}
                direction={sortConfig.direction}
                onClick={createSortHandler("totalDistanceInKm")}
              >
                Total Distance
              </TableSortLabel>
            </TableCell>
            <TableCell sx={{ width: "20%" }} align="right">
              <TableSortLabel
                active={sortConfig.key === "lifetimeSpend"}
                direction={sortConfig.direction}
                onClick={createSortHandler("lifetimeSpend")}
              >
                Lifetime Spend
              </TableSortLabel>
            </TableCell>
            <TableCell sx={{ width: "20%" }}></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {carriers.map((carrier) => {
            stats.totalSegments += carrier.totalSegments || 0;
            stats.totalDistanceInKm = stats.totalDistanceInKm.plus(carrier.totalDistanceInKm || 0.0);
            stats.lifetimeSpend = stats.lifetimeSpend.plus(carrier.lifetimeSpend || 0.0);

            return <CarrierRow key={carrier.id} carrier={carrier} />;
          })}
        </TableBody>
        <TableFooter>
          <TableRow>
            <TableCell sx={{ width: "20%" }}></TableCell>
            <TableCell sx={{ width: "20%" }}></TableCell>
            <TableCell sx={{ width: "20%" }}></TableCell>
            <TableCell sx={{ width: "20%" }}></TableCell>
            <TableCell sx={{ width: "20%" }} align="right">
              {formatNumber(stats.totalSegments, {})}
            </TableCell>
            <TableCell sx={{ width: "20%" }} align="right">
              {formatNumber(stats.totalDistanceInKm.toNumber(), {
                style: "unit",
                unit: "kilometer",
                maximumFractionDigits: 0,
              })}
            </TableCell>
            <TableCell sx={{ width: "20%" }} align="right">
              {formatNumber(stats.lifetimeSpend.toNumber(), { style: "currency", currency: "GBP" })}
            </TableCell>
            <TableCell sx={{ width: "20%" }}></TableCell>
          </TableRow>
        </TableFooter>
      </Table>
    </TableContainer>
  );
}
