/**
 * Indicates if a Segment should be ignored from various statistics or list views.
 * @param {Object} segment - The segment to examine.
 * @return {boolean} - If the segment should be ignored.
 */
export default function shouldSegmentBeIgnored(segment) {
  if (segment.isSkipped || segment.isSecondaryPassenger) {
    // Only include consumed by primary passenger
    return true;
  }

  return false;
}

export function shouldSegmentBeIncluded(segment) {
  return !shouldSegmentBeIgnored(segment);
}
