import Skeleton from "@mui/material/Skeleton";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";

import { useIntl } from "react-intl";
import CopyToClipboardText from "../CopyToClipboardText.js";
import GenericLoyaltyProgramActivityBookingColumn from "../LoyaltyProgramActivityList/GenericLoyaltyProgramActivityBookingColumn.js";
import BookingDescriptionText from "./BookingDescriptionText.js";
import BookingTitleLink from "./BookingTitleLink.js";
import CostText from "./CostText.js";
import PricePaid from "./PricePaid.js";
import SupplierTitleLink from "./SupplierTitleLink.js";
import TextWrapper from "./TextWrapper.js";
import TripLink from "./TripLink.js";
import { styled } from "@mui/material/styles";

function EmptyGenericBookingsRow({ showTrip }) {
  return (
    <TableRow hover>
      <TableCell
        sx={{
          whiteSpace: "nowrap",
        }}
      >
        <Skeleton />
      </TableCell>
      <TableCell>
        <Skeleton />
      </TableCell>
      <TableCell>
        <Skeleton />
      </TableCell>
      <TableCell>
        <Skeleton />
      </TableCell>
      <TableCell align="right">
        <Skeleton />
      </TableCell>

      <TableCell align="right">
        <Skeleton />
      </TableCell>
      {showTrip && (
        <TableCell>
          <Skeleton />
        </TableCell>
      )}
      <TableCell colSpan={3}>
        <Skeleton />
      </TableCell>
    </TableRow>
  );
}

// Styled components for fixed-width cells
const FixedCell = styled(TableCell)({
  width: (props) => props.width,
  minWidth: (props) => props.width,
  maxWidth: (props) => props.width,
  whiteSpace: "nowrap",
  overflow: "hidden",
  textOverflow: "ellipsis",
});

// Styled component for fluid-width cells
const FluidCell = styled(TableCell)({
  width: "auto",
  whiteSpace: "nowrap",
  overflow: "hidden",
  textOverflow: "ellipsis",
});

export default function GenericBookingsRow({
  booking,
  showTrip,
  linkState,
  hideAgentLink,
  isTravel,
  isStay,
  showPerNight,
  showRoomNumber,
}) {
  const { formatDate } = useIntl();

  if (!booking) {
    return (
      <>
        <EmptyGenericBookingsRow showTrip={showTrip} />
        <EmptyGenericBookingsRow showTrip={showTrip} />
        <EmptyGenericBookingsRow showTrip={showTrip} />
      </>
    );
  }
  return (
    <TableRow hover>
      <TableCell
        sx={{
          whiteSpace: "nowrap",
        }}
      >
        {formatDate(booking.bookingDate, {
          year: "numeric",
          month: "short",
          day: "numeric",
          weekday: "short",
        })}
      </TableCell>
      <TableCell>
        <BookingTitleLink
          hideStationIds
          hideCarrier
          booking={booking}
          isTravel={isTravel}
          isStay={isStay}
          state={linkState}
        />

        <BookingDescriptionText booking={booking} showPerNight={showPerNight} showRoomNumber={showRoomNumber} />
      </TableCell>
      <TableCell>
        <SupplierTitleLink booking={booking} hideAgentLink={hideAgentLink} isTravel={isTravel} isStay={isStay} />
      </TableCell>
      <TableCell>
        <TextWrapper width="100px">
          <CopyToClipboardText
            value={booking.bookingReference}
            sx={{
              maxWidth: "inherit",
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
              display: "inline-block",
            }}
          />
        </TextWrapper>
      </TableCell>
      <TableCell align="right">
        <CostText booking={booking} />
      </TableCell>

      <TableCell align="right">
        <PricePaid booking={booking} showUnpaidLabel />
      </TableCell>
      {showTrip && (
        <FluidCell>
          <TripLink trip={booking.trip} />
        </FluidCell>
      )}
      <GenericLoyaltyProgramActivityBookingColumn booking={booking} />
    </TableRow>
  );
}
