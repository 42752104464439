import FlightIcon from "@mui/icons-material/Flight";
import Tooltip from "@mui/material/Tooltip";

import IconButton from "@mui/material/IconButton";
import { Link as ReactRouterLink, useLocation } from "react-router";

const to = "/aircraft";
export default function AircraftButton() {
  const { pathname } = useLocation();
  const isActive = pathname === to;
  return (
    <Tooltip title={"Aircraft"} placement="bottom-end">
      <IconButton component={ReactRouterLink} to={to} color={isActive ? "primary" : "inherit"} size="small">
        <FlightIcon />
      </IconButton>
    </Tooltip>
  );
}
