import Link from "@mui/material/Link";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";

import { useIntl } from "react-intl";
import { Link as ReactRouterLink } from "react-router";
import CopyToClipboardText from "../CopyToClipboardText.js";
import { BookingLink } from "../GenericBookingsTable/BookingTitleLink.js";
import TextWrapper from "../GenericBookingsTable/TextWrapper.js";
import TripLink from "../GenericBookingsTable/TripLink.js";
import GenericLoyaltyProgramActivityColumn from "../LoyaltyProgramActivityList/GenericLoyaltyProgramActivityColumn.js";

export default function GenericStaysRow({ stay, linkState, showOnlySource, showBookingDate, loyaltyProgramId }) {
  const { formatDate } = useIntl();

  const primaryLoyaltyProgram = loyaltyProgramId
    ? stay.loyaltyProgramActivities.find((item) => item.loyaltyProgram.id === loyaltyProgramId)
    : stay.loyaltyProgramActivities?.[0];

  const roomName = [stay.roomName, stay.roomNumber].filter((item) => item !== undefined).join(" / ");
  return (
    <TableRow hover>
      {showBookingDate && (
        <TableCell
          sx={{
            whiteSpace: "nowrap",
          }}
        >
          {stay.booking?.bookingDate
            ? formatDate(stay.booking.bookingDate, {
                year: "numeric",
                month: "short",
                day: "numeric",
                weekday: "short",
              })
            : ""}
        </TableCell>
      )}
      <TableCell
        sx={{
          whiteSpace: "nowrap",
        }}
      >
        {formatDate(stay.checkInDate, {
          year: "numeric",
          month: "short",
          day: "numeric",
          weekday: "short",
        })}
      </TableCell>
      <TableCell
        sx={{
          whiteSpace: "nowrap",
        }}
      >
        {formatDate(stay.checkOutDate, {
          year: "numeric",
          month: "short",
          day: "numeric",
          weekday: "short",
        })}
      </TableCell>
      <TableCell align="right">{stay.nights || ""}</TableCell>

      <TableCell>
        <Link
          to={`/hotels/${stay.brand.id}`}
          component={ReactRouterLink}
          state={linkState}
          color="inherit"
          underline="hover"
        >
          {stay.brand.title}
        </Link>
      </TableCell>

      <TableCell>
        <BookingLink id={stay.booking.id} title={stay.location.title} state={linkState} />

        {/*<BookingDescriptionText booking={booking} />*/}
      </TableCell>

      <TableCell>
        <TextWrapper width="100px">
          <CopyToClipboardText
            value={stay.carrierReference || stay.booking?.bookingReference}
            sx={{
              maxWidth: "inherit",
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
              display: "inline-block",
            }}
          />
        </TextWrapper>
      </TableCell>

      <TableCell>{roomName}</TableCell>

      {/*<TableCell>*/}
      {/*  <TextWrapper width="100px">{stay.roomNumber || ""}</TextWrapper>*/}
      {/*</TableCell>*/}

      <TableCell>
        <TextWrapper>
          <TripLink trip={stay.trip} id={stay.trip.id} />
        </TextWrapper>
      </TableCell>
      <GenericLoyaltyProgramActivityColumn
        loyaltyProgramActivity={primaryLoyaltyProgram}
        showOnlySource={showOnlySource}
        stay={stay}
      />
    </TableRow>
  );
}
