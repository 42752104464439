import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableSortLabel from "@mui/material/TableSortLabel";

import AgentsTableRow from "./AgentsTableRow.js";

export default function AgentsTable({ agents, sortConfig, onSort }) {
  const createSortHandler = (key) => () => {
    onSort(key);
  };

  return (
    <TableContainer>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell sx={{ width: "40%" }}>
              <TableSortLabel
                active={sortConfig.key === "title"}
                direction={sortConfig.direction}
                onClick={createSortHandler("title")}
              >
                Name
              </TableSortLabel>
            </TableCell>
            <TableCell sx={{ width: "20%" }} align="right">
              <TableSortLabel
                active={sortConfig.key === "totalNights"}
                direction={sortConfig.key === "totalNights" ? sortConfig.direction : "asc"}
                onClick={createSortHandler("totalNights")}
              >
                Nights
              </TableSortLabel>
            </TableCell>
            <TableCell sx={{ width: "20%" }} align="right">
              <TableSortLabel
                active={sortConfig.key === "totalBookings"}
                direction={sortConfig.direction}
                onClick={createSortHandler("totalBookings")}
              >
                Bookings
              </TableSortLabel>
            </TableCell>
            <TableCell sx={{ width: "20%" }} align="right">
              <TableSortLabel
                active={sortConfig.key === "lifetimeSpend"}
                direction={sortConfig.direction}
                onClick={createSortHandler("lifetimeSpend")}
              >
                Lifetime Spend
              </TableSortLabel>
            </TableCell>
            <TableCell sx={{ width: "40%" }}></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {agents.map((agent) => (
            <AgentsTableRow key={agent.id} agent={agent} />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
